import React from "react";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { useState } from "react";
import { IoMdCreate } from "react-icons/io";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import axiosInstance from "../../utils/axiosInstance";
import handle401Exception from "../../utils/handle401Exception";
import { queryClient } from "../..";
import { useGlobalContext } from "../../utils/GlobalContext";

const InhouseForm = () => {
  const { setIsAppLoading } = useGlobalContext()
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    contact: 0,
  });

  // on input field change
  const handleOnChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // query function to add question set
  const queryFunction = async (user_data) => {
    setIsAppLoading(true)
    return await axiosInstance
      .post("/api/registerinhousestudent", user_data)
      .then((response) => {
        if (response.status === 201) {
          performSideEffects(response);
        }
      });
  };

  const performSideEffects = () => {
    queryClient.invalidateQueries({
      queryKey: ["get-inhouse-students"],
    });
    setFormData({
      name: "",
      email: "",
      address: "",
      contact: 0,
    });
    setIsAppLoading(false)
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: queryFunction,
    onSuccess: (response) => {},
    onError: (error) => {
      if (error?.response?.status === 401) {
        handle401Exception(() => queryFunction(formData), performSideEffects);
      }else {
        setIsAppLoading(false)
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault()
    if (formData.name === "") {
      toast.error("Please enter name of user.");
      return;
    }
    if (formData.email === "") {
      toast.error("Please enter email of user.");
      return;
    }
    if (formData.address === "") {
      toast.error("Please enter address of user.");
      return;
    }
    if (formData.contact === null || !formData.contact) {
      toast.error("Please enter contact of user.");
      return;
    }
    if(formData.contact.split('').length < 10){
      toast.error("Contact number should be at least 10 numbers long.");
      return;
    }

    mutate(formData);
  };

  return (
    <>
      {/* {isLoading && <LoadingSpinner />} */}
      <form
        className="my-5"
        style={{
          width: "45%",
        }}
        onSubmit={handleSubmit}
      >
        <h1
          style={{
            fontSize: "2rem",
          }}
        >
          Register Inhouse Student
        </h1>
        <div className="mb-3">
          <label className="form-label">
            Name{" "}
            <span
              style={{
                color: "red",
                paddingLeft: ".25rem",
                fontWeight: "bold",
              }}
            >
              *
            </span>{" "}
          </label>
          <input
            value={formData.name}
            onChange={handleOnChange}
            name="name"
            className="form-control shadow-sm"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            Email{" "}
            <span
              style={{
                color: "red",
                paddingLeft: ".25rem",
                fontWeight: "bold",
              }}
            >
              *
            </span>{" "}
          </label>
          <input
          type="email"
            value={formData.email}
            onChange={handleOnChange}
            name="email"
            className="form-control shadow-sm"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            Address{" "}
            <span
              style={{
                color: "red",
                paddingLeft: ".25rem",
                fontWeight: "bold",
              }}
            >
              *
            </span>{" "}
          </label>
          <input
            value={formData.address}
            onChange={handleOnChange}
            name="address"
            className="form-control shadow-sm"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            Contact{" "}
            <span
              style={{
                color: "red",
                paddingLeft: ".25rem",
                fontWeight: "bold",
              }}
            >
              *
            </span>{" "}
          </label>
          <input
            type="number"
            value={formData.contact}
            onChange={handleOnChange}
            name="contact"
            className="form-control shadow-sm"
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          style={{
            display: "flex",
            gap: ".5rem",
            alignItems: "center",
          }}
        >
          <IoMdCreate />
          <span>Register user</span>
        </button>
      </form>
    </>
  );
};

export default InhouseForm;
