import React, { useRef, useState, useEffect } from 'react'
import { Button, Form, Figure } from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import handle401Exception from '../../utils/handle401Exception'
import axiosInstance from '../../utils/axiosInstance'
import { useGlobalContext } from '../../utils/GlobalContext'
import { queryClient } from '../../index'

const UploadAudioBooks = () => {

  const [values, setValues] = useState({
    book_name: '',
    audioBook: null,
  })

  const { setIsAppLoading } = useGlobalContext()


  const API = '/api/uploadaudiobook'


  const queryFunction = async (formData) => {

    return await axiosInstance.post(API, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  const performSideEffects = (response) => {
    setIsAppLoading(false)
    setValues({ book_name: '', audioBook: null })
    const fileInput = document.querySelector('input[name="audioBook"]')
    if (fileInput) {
      fileInput.value = ''
    }
    queryClient.invalidateQueries({ queryKey: ['get-audio-book-list'] })
  }

  const handleSubmit = () => {
    if (!values.book_name) {
      toast.error('Please enter the name of the audio book.')
      return
    }
    if (!values.audioBook) {
      toast.error('Please upload an audio file.')
      return
    }
    setIsAppLoading(true)
    const formData = new FormData()
    formData.append('book_name', values.book_name)
    formData.append('audioBook', values.audioBook)
    queryFunction(formData)
      .then((response) => {
        performSideEffects(response)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          handle401Exception(() => queryFunction(formData), performSideEffects)
        } else {
          setIsAppLoading(false)
        }
      })
  }

  const handleAudioNameChange = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }))
  }

  const handleAudioFileChange = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      audioBook: e.target.files[0],
    }))
  }

  const handleRemove = (e) => {
    setValues({
      book_name: '',
      audioBook: null,
    })
    const fileInput = document.querySelector('input[name="audioBook"]')
    if (fileInput) {
      fileInput.value = ''
    }
  }

  const uploadAudioChangeRef = useRef();
  useEffect(() => {
    if (uploadAudioChangeRef.current) {
      uploadAudioChangeRef.current?.load();
    }
  }, [values.audioBook]);

  return (
    <div
      style={{
        width: '50%',
        marginTop: '1rem',
      }}
    >
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <label className="form-label">Book name</label>
          <input
            onChange={handleAudioNameChange}
            type="text"
            name="book_name"
            className="form-control"
            value={values.book_name}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <label className="form-label">Upload Audio</label>
          <input
            onChange={handleAudioFileChange}
            type="file"
            name="audioBook"
            className="form-control"
            accept="audio/*"

          />
          {values.audioBook !== null && (
            <Figure className="my-4">
              <audio ref={uploadAudioChangeRef} controls>
                <source src={URL.createObjectURL(values.audioBook)} type="audio/mpeg" />
              </audio>
            </Figure>
          )}
        </Form.Group>
        <div
          className="d-flex"
          style={{
            gap: '1rem',
          }}
        >
          <Button type="button" onClick={handleSubmit} className="my-3 px-3">
            Upload
          </Button>
          <Button
            type="button"
            className="my-3 px-3"
            style={{
              background: 'tomato',
              border: 'none',
            }}
            onClick={(e) => handleRemove(e)}
          >
            Remove
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default UploadAudioBooks
