import React from "react";
import InhouseForm from "../../components/Inhouse/InhouseForm";
import InhouseStudents from "../../components/Inhouse/InhouseStudents";
import { Search } from "../QuestionSet/QuestionSet";

const RegisterInhouse = () => {
  return (
    <>
      <InhouseForm />
      <InhouseStudents />
    </>
  );
};

export default RegisterInhouse;
