export function convertToMinutes(timeString) {
  // Split the string into hours and minutes parts
  const [hoursString, minutesString] = timeString.split(/[hm]/);

  // Convert the hours and minutes parts to numbers
  const hours = parseInt(hoursString, 10) || 0; // If hoursString is not a valid number, default to 0
  const minutes = parseInt(minutesString, 10) || 0; // If minutesString is not a valid number, default to 0

  // Convert the hours and minutes to minutes and add them up
  const totalMinutes = hours * 60 + minutes;

  return totalMinutes;
}
// Example usage:
// console.log(convertToMinutes("1h20m")); // Output: 80
// console.log(convertToMinutes("9h12m")); // Output: 552
// console.log(convertToMinutes("0h0m")); // Output: 0
// console.log(convertToMinutes("2h")); // Output: 120 (treated as 2 hours and 0 minutes)
// console.log(convertToMinutes("30m")); // Output: 30 (treated as 0 hours and 30 minutes)

/*
----------------------------------------------------------------------------------------------
*/

export function convertToTime(minutes) {
  const hours = Math.floor(minutes / 60); // Get the number of whole hours in the input
  const remainingMinutes = minutes % 60; // Get the number of remaining minutes

  const hoursString = hours === 0 ? "0h" : `${hours}h`; // Use '0h' if the hours part is zero, otherwise use the actual value with the 'h' suffix
  const minutesString = remainingMinutes === 0 ? "0m" : `${remainingMinutes}m`; // Use '0m' if the minutes part is zero, otherwise use the actual value with the 'm' suffix

  return hoursString + minutesString; // Concatenate the two parts and return the result
}
// Example usage:
// console.log(convertToTime(80)); // Output: "1h20m"
// console.log(convertToTime(552)); // Output: "9h12m"
// console.log(convertToTime(0)); // Output: "0h0m"
// console.log(convertToTime(120)); // Output: "2h0m"
// console.log(convertToTime(30)); // Output: "0h30m"

/*
----------------------------------------------------------------------------------------------
*/
export function formatTime(minutes) {
  const hours = Math.floor(minutes / 60); // Get the number of whole hours in the input
  const remainingMinutes = minutes % 60; // Get the number of remaining minutes

  const hoursString = hours === 1 ? "1 hour" : `${hours} hours`; // Use 'hour' or 'hours' depending on the value of hours
  const minutesString =
    remainingMinutes === 1 ? "1 minute" : `${remainingMinutes} minutes`; // Use 'minute' or 'minutes' depending on the value of remainingMinutes

  if (hours === 0) {
    return `${minutesString}`; // If there are no hours, only return the minutes part
  } else if (remainingMinutes === 0) {
    return `${hoursString}`; // If there are no remaining minutes, only return the hours part
  } else {
    return `${hoursString} ${minutesString}`; // Otherwise, concatenate the two parts and return the result
  }
}

// Example usage:
// console.log(formatTime(80)); // Output: "1 hour 20 minutes"
// console.log(formatTime(552)); // Output: "9 hours 12 minutes"
// console.log(formatTime(0)); // Output: "0 minutes"
// console.log(formatTime(120)); // Output: "2 hours"
// console.log(formatTime(30)); // Output: "30 minutes"

/*
----------------------------------------------------------------------------------------------
*/
