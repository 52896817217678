import React, { useEffect, useRef, useState } from "react"
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"
import handle401Exception from "../../utils/handle401Exception"
import axiosInstance from "../../utils/axiosInstance"
import { FaInfoCircle } from 'react-icons/fa'
import { toast } from "react-hot-toast"
import { Button, Form, Modal } from "react-bootstrap"

const AccessControlExamSet = () => {

    const [examData, setExamData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [examSet, setExamSet] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [price, setPrice] = useState(0)
    const [examSetName, setExamSetName] = useState("")
    const selectRef = useRef(null)

    let getExamList = async () => {
        setIsLoading(true)
        await axiosInstance.get('/api/getexamlistinhousestudent')
            .then((response) => {
                setIsLoading(false)
                setExamData(response.data.data)
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    handle401Exception(getExamList, () => { })
                }
                setIsLoading(false)
            })
    }

    let giveAccess = async () => {
        if (examSet == null || examSet == "") {
            toast.error('Please select a exam set.')
        }
        setShowModal(false)
        setIsLoading(true)
        let obj = {
            exam_set_id: examSet,
            price
        }
        await axiosInstance.post('/api/giveaccesstoappuser', obj)
            .then((response) => {
                setIsLoading(false)
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    handle401Exception(giveAccess, () => { })
                }
                setIsLoading(false)
            })
    }

    let handleChange = (e) => {
        const selectedName = selectRef.current.options[selectRef.current.selectedIndex].text;
        setExamSetName(selectedName)
        setExamSet(e.target.value)
    }

    let handlePriceChange = (e) => {
        let examSetPrice = e.target.value
        if (examSetPrice < 0) {
            examSetPrice = parseInt(examSetPrice) * -1
        }
        setPrice(examSetPrice)
    }

    let handleClose = () => {
        setShowModal(false)
    }

    useEffect(() => {
        getExamList()
    }, [])


    return (
        <div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Access Control [{examSetName}]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form style={{ width: "50%" }}>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <label className="form-label">Set price for this exam set </label>
                            <div className="d-flex">
                                <input
                                    min="0"
                                    onChange={handlePriceChange}
                                    name="price"
                                    type="number"
                                    className="form-control"
                                    value={price}
                                />
                                <FaInfoCircle style={{ marginLeft: "5px", width: "20px", height: "20px" }} color="#17a2b8" title="For free access set the price to zero" />
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={giveAccess}
                    >
                        {isLoading ? "Loading..." : "Give Access"}
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <h1>Access Control [Exam Set]</h1>
            {isLoading ? <LoadingSpinner /> : ""}
            <div style={{ marginTop: "50px" }}>
                <div>
                    <label className="form-label">
                        Select the exam set
                        <span
                            style={{
                                color: "red",
                                paddingLeft: ".25rem",
                                fontWeight: "bold",
                            }}
                        >
                            *
                        </span>{" "}
                    </label>
                    <select
                        name="examSet"
                        className="form-select"
                        onChange={handleChange}
                        style={{ width: "16.5rem" }}
                        ref={selectRef}
                    >
                        <option value="" hidden>

                        </option>
                        {examData.map((exam, index) => (
                            <option
                                value={exam.set_id}
                                key={index}
                            >
                                {exam.set_title}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mt-3">
                    <button
                        onClick={() => {
                            if (examSet == null || examSet == "") {
                                toast.error("Please select a exam set")
                                return
                            }
                            setShowModal(true)
                        }}
                        className="btn btn-primary">
                        Give Access To Mobile App User
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AccessControlExamSet