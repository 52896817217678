import { Modal, Form, Button } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { queryClient } from "../../../index";
import { useContext } from "react";
import { GlobalContext } from "../../../utils/GlobalContext";
import axiosInstance from "../../../utils/axiosInstance";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import handle401Exception from "../../../utils/handle401Exception";
import { IoMdCreate } from "react-icons/io";
import { AiOutlineClear, AiOutlineCloseCircle } from "react-icons/ai";
import {
  convertToTime,
  formatTime,
  convertToMinutes,
} from "../../../utils/time";
import useEditQuestionSet from "../../../customHooks/useEditQuestionSet";
const EditQuestionSet = ({
  name,
  expiry_duration,
  price,
  question_set_id,
  status,
  editModal,
  setEditModal,
}) => {
  const [formData, setFormData] = useState({
    name: name,
    price: price,
    // status: status,
  });

  // on input field change
  const handleOnChange = (e) => {
    if (e.target.value <= -1) {
      toast.error("Price cannot be in negative");
      return;
    }
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // related to expiry time
  const timeRef = useRef();
  const [totalTime, setTotalTime] = useState("");

  useEffect(() => {
    const minutes = convertToMinutes(expiry_duration);

    // set value of input[time wala]
    if (timeRef) {
      timeRef.current.value = Number(minutes);
    }

    // set total time string
    setTotalTime(() => formatTime(minutes));
  }, []);

  // exam duration field change => total time
  // handle form field change
  const handleExpiryFieldChange = (e) => {
    if (e.target.value <= -1) {
      toast.error("Exam duration cannot be negative");
      timeRef.current.value = 1;
      setTotalTime(() => formatTime(timeRef.current.value));
      return;
    }
    setTotalTime(() => formatTime(timeRef.current.value));
  };

  const { handleSubmit, handleClose } = useEditQuestionSet(
    formData,
    totalTime,
    setEditModal,
    question_set_id,
    timeRef.current?.value
  );

  return (
    <>
      <Modal
        show={
          editModal.question_set_id !== "" && editModal.question_set_id !== null
        }
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Question Set</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label className="form-label">Set Name</label>
              <input
                onChange={handleOnChange}
                name="name"
                className="form-control"
                value={formData.name}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <label className="form-label">Price</label>
              <input
                min="0"
                onChange={handleOnChange}
                name="price"
                type="number"
                className="form-control"
                value={formData.price}
              />
            </Form.Group>
            <Form.Group>
              <label className="form-label">Expiration</label>
              <div className="d-flex align-items-center gap-5">
                <div className="d-flex  gap-2 align-items-center">
                  <input
                    min="1"
                    ref={timeRef}
                    name="m"
                    type="number"
                    className="form-control w-50"
                    onChange={handleExpiryFieldChange}
                  />
                  <p className="form-label d-flex gap-4 " htmlFor="">
                    Min
                    <span className=" ">
                      {totalTime ? totalTime : "0 minute"}
                    </span>
                  </p>
                </div>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{
              display: "flex",
              gap: ".5rem",
              alignItems: "center",
              background: "#EE4B2B",
              border: "none",
            }}
          >
            <AiOutlineCloseCircle />

            <span className="ml-3">Close</span>
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            <IoMdCreate />
            <span> Save Changes</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditQuestionSet;
