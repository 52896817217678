import jwtDecode from "jwt-decode";

// this fn fetches token from ls and decodes it and provides token decoded or not
export const getDecodedToken = () => {
  let refresh_token, access_token;
  if (
    !!localStorage.getItem("refresh_token") &&
    !!localStorage.getItem("access_token")
  ) {
    refresh_token = JSON.parse(localStorage.getItem("refresh_token")) || null;
    access_token = JSON.parse(localStorage.getItem("access_token")) || null;
  }

  if (!refresh_token || !access_token) {
    return {
      access_token: null,
      refresh_token: null,
      decoded_access_token: null,
      decoded_refresh_token: null,
    };
  }
  const decoded_access_token = jwtDecode(access_token);
  const decoded_refresh_token = jwtDecode(refresh_token);

  return {
    access_token,
    refresh_token,
    decoded_access_token,
    decoded_refresh_token,
  };
};
