import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { getDecodedToken } from "../../utils/getDecodedToken";

const ProtectedRoutes = ({ children }) => {
  const [navigate, setNavigate] = useState(false);

  useEffect(() => {
    const { refresh_token, access_token, decoded_access_token } =
      getDecodedToken();
    if (!refresh_token || !access_token || !decoded_access_token) {
      setNavigate(true);
      return;
    }
  }, []);

  return (
    <div>
      {navigate && <Navigate to={"/login"} replace={true} />}
      {children}
    </div>
  );
};

export default ProtectedRoutes;
