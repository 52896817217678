import React, { useState, useRef, FC, SetStateAction, Dispatch } from "react";

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

import "react-image-crop/dist/ReactCrop.css";
import { Button } from "react-bootstrap";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ImageCropper = ({
  circular,
  setCroppedImg,
  setOpenCropImageModal,
  openCropImageModalData,
  handleRemoveImageCache
}) => {
  const [imgSrc, setImgSrc] = useState(openCropImageModalData);
  const [imgName, setImgName] = useState(`${Date.now()}--image`);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "px", // Can be 'px' or '%'
    x: 50,
    y: 50,
    width: 100,
    height: 100,
  });
  const [completedCrop, setCompletedCrop] = useState({
    unit: "px", // Can be 'px' or '%'
    x: 50,
    y: 50,
    width: 100,
    height: 100,
  });

  const handleClose = () => {
    canvasPreview(
      imgRef.current,
      previewCanvasRef.current,
      completedCrop,
      imgName,
      setCroppedImg
    );
    setOpenCropImageModal("");
    // handleRemoveImageCache?.()
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          imgName,
          setCroppedImg
        );
      }
    },
    100,
    [completedCrop]
  );

  return (
    <>
      <div>
        <div>
          <div
            style={{
              maxWidth: "500px",
              maxHeight: "500px",
              overflow: "auto",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            {!!imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={(crop, percentCrop) => setCrop(crop)}
                onComplete={(c) => setCompletedCrop(c)}
              >
                <img
                  style={{
                    // maxWidth: "80%",
                    maxHeight: 450,
                  }}
                  ref={imgRef}
                  alt="Crop me"
                  src={URL.createObjectURL(imgSrc)}
                />
              </ReactCrop>
            )}
          </div>
          <div>
            {!!completedCrop && (
              <canvas
                ref={previewCanvasRef}
                style={{
                  display: "none",
                  objectFit: "contain",
                  borderRadius: `${circular} ? "50%": ""`,
                  maxHeight: "50rem",
                  maxWidth: "50rem",
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          variant="primary"
          className="my-3 py-2 mx-3"
          onClick={handleClose}
          style={{
            textAlign: "center",
            width: "70%",
          }}
        >
          Save Image
        </Button>
      </div>
    </>
  );
};

export default ImageCropper;

// {imgSrc === "" && (
//   <div className="my-6 mb-12 lg:w-[100%]">
//     <label className="text-2xl min-h-[200px] cursor-pointer text-[#404858] h-[100%] flex items-center justify-between w-[100%]  px-6 py-6 mt-4 mb-2 bg-white border-[2px] rounded-[5px] ">
//       <span className="text-center px-6 py-6 w-[100%]">
//         {t("Select image")} (max: 200Kb)
//       </span>{" "}
//       <input
//         className={`hidden`}
//         name="image"
//         type={"file"}
//         placeholder="office"
//         accept=".png,.jpg,.jpeg"
//         onChange={(e) => {
//           if (e.target.files.length !== 0) {
//             if (e.target.files[0].size >= 200 * 1024) {
//               cogoToast.info("File size must be less than 200kb");
//             } else {
//               onSelectFile(e);
//             }
//           }
//         }}
//       />
//     </label>
//   </div>
// )}
