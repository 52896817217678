import React from "react";
import UploadBooks from "../../components/Books/UploadBooks";
import BookList from "../../components/Books/BookList";

const Books = () => {
  return (
    <>
      {" "}
      <h1
        style={{
          fontSize: "1.8rem",
          marginTop: "1rem",
        }}
      >
        Books
      </h1>
      <UploadBooks />
      <BookList />
    </>
  );
};

export default Books;
