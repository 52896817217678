import React, { useEffect, useState, useRef } from "react";
import { useMutation } from "react-query";
import axiosInstance from "../../utils/axiosInstance";
import { IoExpandOutline } from "react-icons/io5";
import { toast } from "react-hot-toast";
import handle401Exception from "../../utils/handle401Exception";
import { queryClient } from "../..";
// import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { IoMdCreate } from "react-icons/io";

const CreateInhouseExamSet = () => {
  const [totalTime, setTotalTime] = useState("");

  const timeRef = useRef();
  const [formData, setFormData] = useState({
    set_title: "",
    set_duration: {
      h: 0,
      m: 0,
    },
  });
  // query function to add question set
  const queryFunction = async (examset) => {
    await axiosInstance
      .post("/api/examsetinhousestudent", examset)
      .then((response) => {
        if (response.status === 201) {
          performSideEffects(response);
        }
      });
  };

  const performSideEffects = () => {
    queryClient.invalidateQueries({
      queryKey: ["get-inhouse-exam-sets"],
    });
    setFormData((prev) => ({
      ...prev,
      set_title: "",
      set_duration: {
        h: 0,
        m: 0,
      },
    }));
    setTotalTime("");
    timeRef.current.value = 0;
  };
  //
  const { mutate, isLoading } = useMutation({
    mutationFn: queryFunction,
    onSuccess: (response) => {},
    onError: (error) => {
      if (error?.response?.status === 401) {
        // (3) ['', '40', 'minutes']
        // (4) ['2', 'hours', '3', 'minutes']0: "2"1: "hours"2: "3"3: "minutes"length: 4[[Prototype]]: Array(0)
        let set_duration;
        const timeArray = totalTime.split(" ");
        if (timeArray.length === 3) {
          set_duration = `0h${timeArray[1]}m`;
        }
        if (timeArray.length === 4) {
          set_duration = `${timeArray[0]}h${timeArray[2]}m`;
        }

        const data = {
          set_title: formData.set_title,
          set_duration: set_duration,
        };
        handle401Exception(() => queryFunction(data), performSideEffects);
      }
    },
  });

  const handleSubmit = () => {
    if (formData.set_title === "") {
      toast.error("Please enter the name of exam set.");
      return;
    }

    if (totalTime === "" || !totalTime || totalTime === null) {
      toast.error("Exam Duration is required.");
      return;
    }

    // (3) ['', '40', 'minutes']
    // (4) ['2', 'hours', '3', 'minutes']0: "2"1: "hours"2: "3"3: "minutes"length: 4[[Prototype]]: Array(0)
    let set_duration;
    const timeArray = totalTime.split(" ");
    if (timeArray.length === 3) {
      set_duration = `0h${timeArray[1]}m`;
    }
    if (timeArray.length === 4) {
      set_duration = `${timeArray[0]}h${timeArray[2]}m`;
    }

    if (set_duration == "0h0m") {
      toast.error("Exam duration cannot be Zero");
      return;
    }

    const data = {
      set_title: formData.set_title,
      set_duration: set_duration,
    };
    mutate({ ...data });
  };

  // on input field change
  const handleOnChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // handle form field change
  const handleExpiryFieldChange = (e) => {
    formatTime(e.target.value);
  };

  function formatTime(minutes) {
    if (!minutes) {
      setTotalTime("0 minute");
      return;
    }
    if (minutes <= -1) {
      setTotalTime("0 minute");
      timeRef.current.value = 1;
      toast.error("Exam duration cannot be negaive");
      return;
    }
    if (minutes <= 60) {
      setTotalTime(` ${minutes} minutes`);
      return;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    setTotalTime(`${hours} hours ${remainingMinutes} minutes`);
    return;
  }
  return (
    <form
      className="my-5"
      style={{
        width: "35%",
      }}
    >
      <h1
        style={{
          fontSize: "2rem",
        }}
      >
        Create Exam Set
      </h1>
      <div className="mb-3">
        <label className="form-label">
          Set Title{" "}
          <span
            style={{
              color: "red",
              paddingLeft: ".25rem",
              fontWeight: "bold",
            }}
          >
            *
          </span>{" "}
        </label>
        <input
          value={formData.set_title}
          onChange={handleOnChange}
          name="set_title"
          className="form-control shadow-sm"
        />
      </div>
      <div className="mb-3">
        <label className="form-label">
          Exam duration
          <span
            style={{
              color: "red",
              paddingLeft: ".25rem",
              fontWeight: "bold",
            }}
          >
            *
          </span>{" "}
        </label>
        <div className="d-flex align-items-center gap-5">
          <div className="d-flex  gap-2 align-items-center">
            <input
              ref={timeRef}
              min="1"
              onChange={handleExpiryFieldChange}
              name="m"
              type="number"
              className="form-control w-50  shadow-sm"
            />
            <p className="form-label d-flex gap-4 " htmlFor="">
              Min
              <span className=" ">{totalTime}</span>
            </p>
          </div>
        </div>
      </div>

      <button
        type="button"
        onClick={handleSubmit}
        className="btn btn-primary"
        style={{
          display: "flex",
          gap: ".5rem",
          alignItems: "center",
        }}
      >
        <IoMdCreate />
        <span> Create exam set</span>
      </button>
    </form>
  );
};

export default CreateInhouseExamSet;
