import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axiosInstance from "../../../utils/axiosInstance";
import { queryClient } from "../../..";
import handle401Exception from "../../../utils/handle401Exception";
import { useMutation } from "react-query";
import { GlobalContext } from "../../../utils/GlobalContext";
import { toast } from "react-hot-toast";

function DeleteModal({
  name,
  question_set_id,
  price,
  status,
  deleteModal,
  setDeleteModal,
}) {
  const { setIsAppLoading } = useContext(GlobalContext);

  const handleClose = () => {
    setDeleteModal((prev) => ({
      name: "",
      price: null,
      question_set_id: null,
      status: null,
      expiry_duration: "",
    }));
  };
  const performSideEffects = () => {
    toast.success(`${name} deleted successfully.`);
    queryClient.invalidateQueries({ queryKey: ["get-question-sets"] });
    queryClient.invalidateQueries({
      queryKey: ["get-all-question-sets-without-pagination"],
    });
    setIsAppLoading(false);
    handleClose();
  };

  const queryFunction = async () => {
    return await axiosInstance
      .delete(`/api/questionset/${question_set_id}`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          performSideEffects();
        }
      });
  };
  const { mutate } = useMutation(queryFunction, {
    onError: (error) => {
      setIsAppLoading(false);
      if (error?.response.status === 401) {
        handle401Exception(queryFunction, performSideEffects);
      }
      handleClose();
    },
  });
  const handleDelete = () => {
    setIsAppLoading(true);
    mutate();
  };
  return (
    <>
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete this question Set</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to delete {name}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{ background: "#EE4B2B", border: "none" }}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteModal;
