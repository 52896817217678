import React, { useState } from "react";
import { Stack, Button, Card, Breadcrumb } from "react-bootstrap";
import QuestionOnlyCard from "../../components/QuestionOnly/QuestionOnlyCard/QuestionOnlyCard";
import QuestionOnlyForm from "../../components/QuestionOnly/QuestionOnlyForm/QuestionOnlyForm";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import handle401Exception from "../../utils/handle401Exception";
import { useGlobalContext } from "../../utils/GlobalContext";
import { useFetchQuestions } from "../../customHooks/useFetchQuestions";
import RenderQuestions from "../../components/common/RenderQuestions";

const InhouseQuestion = () => {
  const { id, set_title } = useParams();
  const { questionArray, isLoading, isFetching, length } = useFetchQuestions(
    id,
    `/api/getquestioninhousestudent/${id}`,
    ["get-inhouse-exam-questions", id]
  );

  // readingQuestions listeningQuestions
  const [selectedSection, setSelectedSection] = useState("readingQuestions");
  return (
    <Stack
      style={{
        marginBottom: "5rem",
      }}
    >
      <div className="py-4 ">
        <QuestionOnlyForm
          queryKey={["get-inhouse-exam-questions", id]}
          API_URL={"/api/addquestioninhousestudent"}
          questionLength={length}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "80%",
          alignItems: "center",
        }}
      >
        <h1 className="pt-3 pb-2">All available Questions</h1>
        {(!isLoading || !isFetching) && (
          <span
            style={{
              fontWeight: "700",
            }}
          >
            Total Questions: {length}{" "}
          </span>
        )}
      </div>
      <Breadcrumb>
        <Link to={`/inhouse-examset`}>{set_title}</Link>
      </Breadcrumb>
      <Stack gap={4} style={{ marginTop: "1rem" }}>
        {isLoading || isFetching ? (
          <p>Loading...</p>
        ) : questionArray !== null && length !== 0 ? (
          <>
            <ul className="nav nav-pills">
              <li
                className="nav-item"
                onClick={() => setSelectedSection("readingQuestions")}
              >
                <p
                  className={`nav-link ${
                    selectedSection === "readingQuestions" && "active"
                  }`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Reading Questions
                </p>
              </li>
              <li
                className="nav-item"
                onClick={() => setSelectedSection("listeningQuestions")}
              >
                <p
                  className={`nav-link ${
                    selectedSection === "listeningQuestions" && "active"
                  }`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Listening Questions
                </p>
              </li>
            </ul>
            {questionArray && questionArray.length !== 0 && (
              <RenderQuestions
                pageUsed="InhouseQuestion"
                question_set_name={set_title}
                questionArray={
                  selectedSection === "readingQuestions"
                    ? questionArray[0]?.readingQuestions
                    : questionArray[0]?.listeningQuestions
                }
              />
            )}
          </>
        ) : (
          <p>Sorry, no questions available</p>
        )}
      </Stack>
    </Stack>
  );
};

export default InhouseQuestion;

// correct_answer: "option1";
// option1: "Hll";
// option2: "klfa";
// option3: "klfaw";
// option4: "kjlfaw";
// option_id: 27;
// option_type: "text";
// question_audio: "";
// question_id: 29;
// question_image: "";
// question_set_id: 10;
// question_title: "What is NEpal?";
// {
//   option_type,
//     option1,
//     option2,
//     option3,
//     option4,
//     question_audio,
//     question_image,
//     question_image,
//     correct_answer,
// }

// {
//   "status": true,
//   "message": "Data found successfully!!!",
//   "data": [
//       {
//           "readingQuestions": [
//               {
//                   "question_id": 44,
//                   "question_title": "What is your name?",
//                   "question_image": "",
//                   "question_audio": "",
//                   "question_set_id": 8,
//                   "option_id": 42,
//                   "option_type": "text",
//                   "option1": "Hello",
//                   "option2": "Suzan",
//                   "option3": "Rana",
//                   "option4": "World"
//               },
//               {
//                   "question_id": 45,
//                   "question_title": "Hi",
//                   "question_image": "",
//                   "question_audio": "",
//                   "question_set_id": 8,
//                   "option_id": 43,
//                   "option_type": "text",
//                   "option1": "fal",
//                   "option2": "kfa",
//                   "option3": "fawl",
//                   "option4": "faw"
//               },
//               {
//                   "question_id": 47,
//                   "question_title": "Question 1?",
//                   "question_image": "",
//                   "question_audio": "",
//                   "question_set_id": 8,
//                   "option_id": 45,
//                   "option_type": "text",
//                   "option1": "option 1",
//                   "option2": "option 2",
//                   "option3": "option 3",
//                   "option4": "option 4"
//               }
//           ],
//           "listeningQuestions": [
//               {
//                   "question_id": 43,
//                   "question_title": "What is question 2?",
//                   "question_image": "1682583386959--suzan-Page-1.drawio.png",
//                   "question_audio": "1682583386963--stomping-drums-rhythm-and-percussion-141367.mp3",
//                   "question_set_id": 8,
//                   "option_id": 41,
//                   "option_type": "image",
//                   "option1": "1682583199685--suzan-Page-1.drawio.png",
//                   "option2": "1682582221278--new-.png",
//                   "option3": "1682583316197--er (1).png",
//                   "option4": "1682582221286--use.png"
//               },
//               {
//                   "question_id": 46,
//                   "question_title": "한국어는 무엇입니까?",
//                   "question_image": "1682585706266--new-.png",
//                   "question_audio": "1682585706267--stomping-drums-rhythm-and-percussion-141367.mp3",
//                   "question_set_id": 8,
//                   "option_id": 44,
//                   "option_type": "text",
//                   "option1": "어는어는어는어는어는어는어는",
//                   "option2": "어는",
//                   "option3": "어는어는",
//                   "option4": "어는어는어는"
//               }
//           ]
//       }
//   ]
// }
