import React, { useState } from "react";
import InhouseLiveExamForm, {
  useFetchAvailableExamSets,
  useFetchLiveExam,
} from "../../components/Inhouse/CreateLiveExamForm";
import InhouseLiveExamTable from "../../components/Inhouse/InhouseLiveExam";

const InhouseLiveExam = () => {
  const [isRequestEnabled, setIsRequestEnabled] = useState(false);
  const { availableExamSets } = useFetchAvailableExamSets(setIsRequestEnabled);
  const { liveExamData, isFetching, isLoading } =
    useFetchLiveExam(isRequestEnabled);
  return (
    <>
      <InhouseLiveExamForm availableExamSets={availableExamSets} />
      <div className="my-4 ">
        <div>
          <h1
            style={{
              fontSize: "1.5rem",
              marginTop: "1rem",
              marginBottom: '1rem'
            }}
          >
            All available Live Exam
          </h1>
        </div>
        {isLoading || isFetching ? (
          <p>Loading...</p>
        ) : (
          <>
            {liveExamData !== undefined && liveExamData.length !== 0 ? (
              <InhouseLiveExamTable liveExamData={liveExamData} />
            ) : (
              <p>No live exam found</p>
            )}
          </>
        )}
      </div>  
    </>
  );
};

export default InhouseLiveExam;
