import { useState } from "react";
import { GiPapers } from "react-icons/gi";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { AiFillQuestionCircle } from "react-icons/ai";
import { IoBookSharp } from "react-icons/io5";
import { FiPackage } from "react-icons/fi";
import { BsFillPeopleFill } from 'react-icons/bs'
import { RiFilePaper2Line } from 'react-icons/ri'
import { ImTablet } from 'react-icons/im'
import { VscPreview } from 'react-icons/vsc'
import { ImMobile } from 'react-icons/im'
import { BsPeopleFill } from 'react-icons/bs'
import { TbLockAccess } from 'react-icons/tb'
import { FaFileAudio } from 'react-icons/fa'
/*
@args: title: string, title you want to show of the dropdown in sidebar
@args: optionsArray: [ { name: 'Option Name', link: '/inhouse-external-exam }]
*/
export const CustomSidebarExamElement = ({ options1Array, title1, title2, options2Array, options3Array, title3 }) => {
  const [showDropdown, setShowDropdown] = useState({
    d1: false,
    d2: false,
    d3: false
  });

  return (
    <>
      <section
        style={{
          position: "relative",
        }}
      >
        <div
          className="px-3"
          style={{
            textDecoration: "none",
            color: "white",
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            marginTop: "-0.75rem",
          }}
        >
          <p>
            <ImMobile
              style={{
                marginTop: "1rem",
                width: "20px",
                height: "20px",
              }}
            />
          </p>
          <p
            onClick={() =>
              setShowDropdown((prev) => ({ d2: false, d3: false, d1: !prev["d1"] }))
            }
            style={{ marginTop: "1rem", marginLeft: "1rem", display: "flex" }}
          >
            {title1}
            <IoIosArrowDown
              style={{
                marginLeft: "1rem",
                width: "20px",
                height: "20px",
                marginTop: ".25rem",
                transition: "transform 0.3s",
                transform: showDropdown.d1 ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </p>
        </div>
        <div
          style={{
            paddingLeft: "3rem",
            transition: "all 0.5s",
            position: "absolute",
            top: "3.5rem",
            left: 0,
            right: 0,
            opacity: showDropdown.d1 ? 1 : 0,
          }}
        >
          {options1Array.map((option, index) => (
            <Link
              to={option.link}
              key={index}
              style={{
                textDecoration: "none",
                color: "white",
                display: "block",
                transition: "opacity 0.3s",
                opacity: showDropdown.d1 ? 1 : 0,
                display: showDropdown.d1 ? "block" : "none",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="px-3">
                {option.option_name == "Question Sets" ?
                  <AiFillQuestionCircle
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px"
                    }}
                  /> : ""}
                {option.option_name == "Package Sets" ?
                  <FiPackage
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px"
                    }}
                  /> : ""}
                {option.option_name == "Books" ?
                  <IoBookSharp
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px"
                    }}
                  /> : ""}
                {/* {option.option_name == "Audio Books" ?
                  <FaFileAudio
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px"
                    }}
                  /> : ""} */}
                {option.option_name == "Access Control" ?
                  <TbLockAccess
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px"
                    }}
                  /> : ""}

                {option.option_name}
              </p>
            </Link>
          ))}
        </div>
      </section>{" "}
      {/* for student management */}
      <section
        style={{
          position: "relative",
          marginTop: showDropdown.d1 ? "9.3rem" : "0", // do same thing for more sets also
          transition: "all 0.5s",
        }}
      >
        <div
          className="px-3"
          style={{
            textDecoration: "none",
            color: "white",
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            marginTop: "-0.75rem",
          }}
        >
          <p>
            <BsPeopleFill
              style={{
                marginTop: "1rem",
                width: "20px",
                height: "20px",
              }}
            />
          </p>
          <p
            onClick={() =>
              setShowDropdown((prev) => ({ d1: false, d3: false, d2: !prev["d2"] }))
            }
            style={{ marginTop: "1rem", marginLeft: "1rem", display: "flex" }}
          >
            {title2}
            <IoIosArrowDown
              style={{
                marginLeft: "5rem",
                width: "20px",
                height: "20px",
                marginTop: ".25rem",
                transition: "transform 0.3s",
                transform: showDropdown.d2 ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </p>
        </div>
        <div
          style={{
            paddingLeft: "3rem",
            transition: "all 0.5s",
            position: "absolute",
            top: "3.5rem",
            left: 0,
            right: 0,
            minHeight: "2rem",
            opacity: showDropdown.d2 ? 1 : 0,
          }}
        >
          {options2Array.map((option, index) => (
            <Link
              to={option.link}
              key={index}
              style={{
                textDecoration: "none",
                color: "white",
                display: "block",
                transition: "opacity 0.3s",
                opacity: showDropdown.d2 ? 1 : 0,
                display: showDropdown.d2 ? "block" : "none",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >

              <p className="px-3">
                {option.option_name == "Register Inhouse Students" ?
                  <BsFillPeopleFill
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px"
                    }}
                  /> : ""}
                {option.option_name}
              </p>
            </Link>
          ))}
        </div>
      </section>
      {/* for internal exam management */}
      <section
        style={{
          position: "relative",
          marginTop: showDropdown.d2 ? "2rem" : "0", // do same thing for more sets also
          transition: "all 0.5s",
        }}
      >
        <div
          className="px-3"
          style={{
            textDecoration: "none",
            color: "white",
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            marginTop: "-0.75rem",
          }}
        >
          <p>
            <GiPapers
              style={{
                marginTop: "1rem",
                width: "20px",
                height: "20px",
              }}
            />
          </p>
          <p
            onClick={() =>
              setShowDropdown((prev) => ({ d1: false, d2: false, d3: !prev["d3"] }))
            }
            style={{ marginTop: "1rem", marginLeft: "1rem", display: "flex" }}
          >
            {title3}
            <IoIosArrowDown
              style={{
                marginLeft: "1.8rem",
                width: "20px",
                height: "20px",
                marginTop: ".25rem",
                transition: "transform 0.3s",
                transform: showDropdown.d3 ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </p>
        </div>
        <div
          style={{
            paddingLeft: "3rem",
            transition: "all 0.5s",
            position: "absolute",
            top: "3.5rem",
            left: 0,
            right: 0,
            minHeight: "2rem",
            opacity: showDropdown.d3 ? 1 : 0,
          }}
        >
          {options3Array.map((option, index) => (
            <Link
              to={option.link}
              key={index}
              style={{
                textDecoration: "none",
                color: "white",
                display: "block",
                transition: "opacity 0.3s",
                opacity: showDropdown.d3 ? 1 : 0,
                display: showDropdown.d3 ? "block" : "none",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="px-3">
                {option.option_name == "Create Exam Set" ?
                  <RiFilePaper2Line
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px"
                    }}
                  /> : ""}
                {option.option_name == "Create Internal Exam" ?
                  <ImTablet
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px"
                    }}
                  /> : ""}
                {option.option_name == "Review Exam Result" ?
                  <VscPreview
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px"
                    }}
                  /> : ""}
                {option.option_name == "Access Control" ?
                  <TbLockAccess
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px"
                    }}
                  /> : ""}

                {option.option_name}
              </p>
            </Link>
          ))}
        </div>
      </section>
    </>
  );
};
