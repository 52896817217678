import { Card, Accordion, Figure, Button } from "react-bootstrap";
import option_image from "../../../assets/lotus_logo.png";
import option_audio from "../../../assets/audio.mp3";
import { Link } from "react-router-dom";
import handle401Exception from "../../../utils/handle401Exception";
import axiosInstance from "../../../utils/axiosInstance";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../utils/GlobalContext";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { toast } from "react-hot-toast";
import { queryClient } from "../../..";
import { useMutation } from "react-query";
import DeleteQuestionModal from "../../Modal/Question/DeleteQuestionModal";
import { BiEditAlt } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";

const QuestionOnlyCard = ({
  question_set_name,
  title,
  option_type,
  option1,
  option2,
  option3,
  option4,
  question_audio,
  index,
  question_image,
  correct_answer,
  question_id,
  question_set_id,
  question_subtitle,
  question_text,
  editLink,
  pageUsed,
  set_id
}) => {
  // if (isDeleting) {
  //   return <LoadingSpinner />;
  // }
  const [deleteModal, setDeleteModal] = useState(false);
  const options = [option1, option2, option3, option4];

  // accordion open close
  const [activeKey, setActiveKey] = useState("0");
  const handleAccordionClick = (event) => {
    event.stopPropagation();
    setActiveKey("1");

    if (activeKey === "0") {
      setActiveKey("1");
    }
    if (activeKey === "1") {
      setActiveKey("0");
    }
  };

  return (
    <>
      {deleteModal && (
        <DeleteQuestionModal
          setDeleteModal={setDeleteModal}
          name={title}
          question_id={question_id}
          queryKey={
            pageUsed === "InhouseQuestion"
              ? ["get-inhouse-exam-questions", String(set_id)]
              : ["get-questions", String(question_set_id)]
          }
          API_URL={
            pageUsed === "InhouseQuestion"
              ? `/api/deletequestioninhousestudent/${question_id}`
              : `/api/deletequestion/${question_id}`
          }
        />
      )}
      <Card
        style={{
          width: "80%",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <Accordion
          style={{
            flexGrow: "1",
          }}
          defaultActiveKey={activeKey}
        >
          <Accordion.Item onClick={handleAccordionClick}>
            <Accordion.Header
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <span
                style={{
                  width: "70%",
                }}
              >
                {index + 1}. {title}
              </span>
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                }}
              >
                <Link
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  to={editLink}
                  className="btn-primary btn"
                  ml-3
                  my-3
                >
                  <BiEditAlt color="white" />
                  <span
                    style={{
                      paddingLeft: ".5rem",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    Edit
                  </span>{" "}
                </Link>
                <Button
                  type="button"
                  style={{
                    flex: "1",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: ".5rem",
                    background: "red",
                    border: "none",
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setDeleteModal(true);
                  }}
                >
                  <BsTrash />
                  Delete
                </Button>
              </div>
            </Accordion.Header>
            <>
              {activeKey === "1" && (
                <>
                  <Accordion.Body
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {" "}
                    {question_subtitle && question_subtitle !== " " && (
                      <div>
                        <p
                          style={{
                            fontWeight: "500",
                          }}
                        >
                          Question Subtitle:
                        </p>
                        <p>{question_subtitle}</p>
                      </div>
                    )}
                    {question_text && question_text !== " " && (
                      <div>
                        <p
                          style={{
                            fontWeight: "500",
                          }}
                        >
                          Question Text:
                        </p>
                        <p>{question_text}</p>
                      </div>
                    )}
                  </Accordion.Body>
                  <Accordion.Body
                    style={{
                      display: "flex",
                      gap: "5rem",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <>
                      {" "}
                      {question_image && (
                        <div>
                          <p
                            style={{
                              fontWeight: "500",
                            }}
                          >
                            Question Image:
                          </p>
                          <ImageComponent option={question_image} />
                        </div>
                      )}
                      {question_audio && (
                        <div>
                          <p
                            style={{
                              fontWeight: "500",
                            }}
                          >
                            Question Audio:
                          </p>
                          <AudioComponent option={question_audio} />
                        </div>
                      )}
                    </>
                  </Accordion.Body>
                  <Accordion.Body
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {options.length !== 0 &&
                      correct_answer &&
                      options.map((option, idx) => (
                        <AnswerOption
                          key={idx}
                          optionType={option_type}
                          option={option}
                          index={idx}
                          correct_answer={parseInt(correct_answer.split("")[6])}
                        />
                      ))}
                    <div
                      className="my-4"
                      style={
                        {
                          // marginLeft: "1rem",
                          // border: "2px solid red",
                        }
                      }
                    ></div>
                  </Accordion.Body>
                </>
              )}
            </>
          </Accordion.Item>
        </Accordion>
      </Card>
    </>
  );
};
export default QuestionOnlyCard;

const fetchImages = async (imageUrl) => {
  const performSideEffects = () => {};
  const queryFunction = `/api/getfile/${imageUrl}`;
  const { data } = await axiosInstance.get(queryFunction).catch((error) => {
    if (error.response?.status === 401) {
      handle401Exception(() => fetchImages(imageUrl), performSideEffects);
    }
  });
  return data;
};

const AnswerOption = ({ option, index, optionType, correct_answer }) => {
  return (
    <Card
      style={{
        backgroundColor: index === correct_answer - 1 ? "lightgreen" : "white",
        cursor: "pointer",
        paddingInline: "1rem",
        marginBlock: ".5rem",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "1rem",
        paddingBottom: "0",
        paddingBlock: ".5rem",
      }}
    >
      <span>{index + 1}.</span>
      {optionType === "text" && option}
      {optionType === "image" && (
        <div className="w-25 py-3">
          <ImageComponent option={option} />
        </div>
      )}
      {optionType === "audio" && (
        <Figure
          // className="my-4"
          style={{
            // border: "2px solid black",
            marginBottom: "0",
            paddingTop: "0.5rem",
          }}
        >
          <AudioComponent option={option} />
        </Figure>
      )}
    </Card>
  );
};

export const ImageComponent = ({ option }) => {
  const { setOpenImagePopup } = useGlobalContext();
  const [isImageLoading, setIsImageLoading] = useState(false);
  const performSideEffects = (response) => {
    const mimeType = response.headers["content-type"];
    const blob = new Blob([response.data], { type: mimeType }); // Replace `image/jpeg` with the actual MIME type of your image
    setIsImageLoading(false);
    const dataUrl = URL.createObjectURL(blob);
    setImageData(dataUrl);
  };

  const fetcherFunction = async (option) => {
    setIsImageLoading(true);
    return await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/api/getfile/${option}`,
      {
        responseType: "blob",
      }
    );
  };
  const [imageData, setImageData] = useState(null);
  const fetchImages = async (option) => {
    setIsImageLoading(true);
    return axiosInstance
      .get(`${process.env.REACT_APP_API_URL}/api/getfile/${option}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        if (response.status === 200) {
          performSideEffects(response);
        }
      })
      .catch((error) => {
        // setError(error);
        if (error.response?.status === 401) {
          handle401Exception(() => fetcherFunction(option), performSideEffects);
        } else {
          setIsImageLoading(false);
        }
      });
  };

  useEffect(() => {
    fetchImages(option);
  }, []);

  return (
    <>
      {imageData !== null && isImageLoading === false ? (
        <img
          src={imageData}
          className="img-fluid"
          style={{
            cursor: "pointer",
            width: "200px",
            height: "200px",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenImagePopup(imageData);
            setIsImageLoading(false);
          }}
          alt="Image"
        />
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export const AudioComponent = ({ option }) => {
  const [audioData, setAudioData] = useState(null);
  const performSideEffects = (response) => {
    setIsAudioLoading(false);
    setAudioData(response.data);
  };
  const [isAudioLoading, setIsAudioLoading] = useState(false);

  const fetcherFunction = async (option) => {
    setIsAudioLoading(true);
    return await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/api/getfile/${option}`,
      {
        responseType: "blob",
      }
    );
  };

  const fetchAudios = async (option) => {
    setIsAudioLoading(true);
    return axiosInstance
      .get(`${process.env.REACT_APP_API_URL}/api/getfile/${option}`, {
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          performSideEffects(response);
        }
      })
      .catch((error) => {
        // setError(error);
        if (error.response?.status === 401) {
          handle401Exception(() => fetcherFunction(option), performSideEffects);
        }
      });
  };

  useEffect(() => {
    fetchAudios(option);
  }, []);

  return (
    <>
      {audioData && !isAudioLoading ? (
        <audio controls>
          <source src={URL.createObjectURL(audioData)} type="audio/mpeg" />
        </audio>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};
