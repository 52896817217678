import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axiosInstance from "../../../utils/axiosInstance";
import { queryClient } from "../../..";
import handle401Exception from "../../../utils/handle401Exception";
import { useMutation } from "react-query";
import { GlobalContext } from "../../../utils/GlobalContext";
import { toast } from "react-hot-toast";

function DeleteQuestionModal({
  question_id,
  setDeleteModal,
  queryKey,
  API_URL
}) {
  console.log('QUESTION KEY in delet modal', queryKey)
  const { setIsAppLoading } = useContext(GlobalContext);

  const handleClose = () => {
    setIsAppLoading(false);
    setDeleteModal(false);
  };
  // `/api/deletequestion/${question_id}`
  // delete question
  const queryFunction = async () => {
    return await axiosInstance
      .delete(API_URL)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          performSideEffects();
        }
      });
  };
  // handle delete  // side effects
  const performSideEffects = () => {
    toast.success(`Question deleted successfully.`);
    queryClient.invalidateQueries({ queryKey: [...queryKey] });
    handleClose();
    // navigate(`/questionset/${question_set_id}`);
  };
  const { mutate, isLoading } = useMutation(queryFunction, {
    onError: (error) => {
      if (error?.response.status === 401) {
        handle401Exception(queryFunction, performSideEffects);
      }
    },
  });
  const handleDeleteQuestion = (e) => {
    e.preventDefault();
    if (!question_id) {
      return;
    }
    mutate();
    setIsAppLoading(true);
  };
  return (
    <>
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to delete this question?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            style={{ background: "#EE4B2B", border: "none" }}
            onClick={handleDeleteQuestion}
          >
            {isLoading ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteQuestionModal;
