import { IoLogOutOutline } from "react-icons/io5";
import axiosInstance from "../../utils/axiosInstance";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getDecodedToken } from "../../utils/getDecodedToken";
import handle401Exception from "../../utils/handle401Exception";

const Logout = () => {
  const navigate = useNavigate();

  // query function (logout functionnality)
  let queryFunction = () =>
    axiosInstance.post("/api/admin/logout", {
      refresh_token: JSON.parse(localStorage.getItem("refresh_token")),
    });

  // some side effect (: clear ls, toast msg, redirect )
  const performSideEffects = () => {
    localStorage.clear();
    toast.success("Logged out successfully");
    navigate("/login");
  };

  // main fn
  const handleLogout = async () => {
    const { refresh_token } = getDecodedToken();

    queryFunction(refresh_token)
      .then((response) => {
        if (response.status === 200) {
          performSideEffects();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          handle401Exception(queryFunction, performSideEffects);
        }
        if (error.status === 200) {
          performSideEffects();
        }
      });
  };
  return (
    <div
      className="py-1 px-3 "
      style={{
        textDecoration: "none",
        color: "white",
        display: "flex",
        cursor: "pointer",
        gap: "1rem",
        alignItems: "items-center",
      }}
      onClick={handleLogout}
    >
      <p>
        <IoLogOutOutline
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      </p>
      <p className="pt-1">Log out</p>
    </div>
  );
};

export default Logout;
