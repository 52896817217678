import React from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { useGlobalContext } from "../../utils/GlobalContext";

const ImagePopup = ({ imageUrl }) => {
  const { setOpenImagePopup } = useGlobalContext();
  const handleClose = () => {
    setOpenImagePopup("");
  };
  return (
    <Modal
      size="lg"
      show={true}
      onHide={handleClose}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Image Preview
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Image width={900} height={900} src={imageUrl} fluid />
      </Modal.Body>
      <Button onClick={handleClose}>Close</Button>
    </Modal>
  );
};

export default ImagePopup;
