import Table from "react-bootstrap/Table";
import { BiEditAlt } from "react-icons/bi";
import { useState } from "react";
import EditChapter from "../Modal/Chapters/EditChaptersModal";

const ChapterTable = ({ chaptersArray }) => {
    console.log(chaptersArray);
    const tableHeading = [
        "S.N",
        "Id",
        "Name",
        // "status",
        "Actions",
    ];

    const [editModal, setEditModal] = useState({
        book_id: "",
        chapter_id: "",
        chapter_name: "",
        status: "1",
    });

    const handleOpenEditModal = (user_data) => {
        setEditModal((prev) => ({
            ...user_data,
        }));
    };

    return (
        <>
            {editModal.book_id !== "" && (
                <EditChapter {...editModal} setEditModal={setEditModal} />
            )}
            <Table
                responsive
                hover
            >
                <thead>
                    <tr>
                        {tableHeading.map((heading, index) => {
                            return <th key={index}>{heading}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {chaptersArray.map((student, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{student.chapter_id}</td>
                            <td>{student.chapter_name}</td>
                            {/* <td>{student.status}</td> */}
                            <td className="d-flex gap-4">

                                <span
                                    className="d-flex gap-2 align-items-center"
                                    onClick={() => handleOpenEditModal(student)}
                                >
                                    <BiEditAlt color="green" />
                                    <span
                                        style={{
                                            color: "green",
                                            cursor: "pointer",
                                        }}
                                    >
                                        Edit
                                    </span>{" "}
                                </span>
                                <StatusChanger {...student} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};

export default ChapterTable;

const StatusChanger = ({ chapter_id }) => {

    return (
        <span
            onClick={() => {
                // <ChapterFileList />
                // Navigate to the desired URL when clicked
                window.location.href = `/view_file?chapter_id=${chapter_id}`;
            }}
            style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "blue",
            }}
        >
            View
        </span>
    );
};


