import { useEffect, useState } from "react";
import { useFetchQuestions } from "./useFetchQuestions";

const useFetchOneQuestion = (set_id, question_id, API_URL, queryKey) => {
  const { questionArray, isLoading } = useFetchQuestions(
    set_id,
    API_URL,
    queryKey
  );
  const [questionData, setQuestionData] = useState(null);

  // get data from the question
  const getQuestion = () => {
    if (!questionArray || questionArray?.length === 0) {
      return;
    }
    const allQuestions = [
      ...questionArray[0].readingQuestions,
      ...questionArray[0].listeningQuestions,
    ];

    return allQuestions.find(
      (question) =>
        parseInt(question.question_id) === parseInt(question_id)
    );
  };
  useEffect(() => {
    let data = getQuestion();
    setQuestionData(data);
  }, [isLoading, questionArray?.length]);
  return {
    questionData,
    isLoading,
  };
};
export default useFetchOneQuestion;
