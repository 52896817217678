import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import ViewQuestion from "../../components/ViewQuestions/ViewQuestions";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useMutation } from "react-query";
import axiosInstance from "../../utils/axiosInstance";
import { toast } from "react-hot-toast";
import { queryClient } from "../..";
import handle401Exception from "../../utils/handle401Exception";
import { useFetchQuestions } from "../../customHooks/useFetchQuestions";

const ViewQuestionPage = () => {
  const { question_set_id, question_id, question_set_name } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!question_id && !question_set_id) {
      navigate("/questionset");
    }
    if (!question_id) {
      navigate(`/questionset/${question_set_id}`);
    }
  }, []);
  const { isLoading, questionData } = useFetchOneQuestion(
    question_set_id,
    question_id,
    `/api/question/${question_set_id}`,
    ["get-questions", question_set_id]
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div
        // className="my-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <h2 className="my-4">Edit your question</h2>
      </div>
      {questionData?.question_id && <ViewQuestion API_URL={'/api/updatequestion'} queryKey={["get-questions", String(question_set_id)]} onSuccessLink={`/questionset/${question_set_name}/${question_set_id}`} {...questionData} />}
    </>
  );
};

export default ViewQuestionPage;

const useFetchOneQuestion = (question_set_id, question_id) => {
  const { questionArray, isLoading, isFetching, length } = useFetchQuestions(
    question_set_id,
    `/api/question/${question_set_id}`,
    ["get-questions", question_set_id]
  );  const [questionData, setQuestionData] = useState(null);

  // get data from the question
  const getQuestion = () => {
    if (!questionArray || questionArray?.length === 0) {
      return;
    }
    const allQuestions = [
      ...questionArray[0].readingQuestions,
      ...questionArray[0].listeningQuestions,
    ];

    return allQuestions.find(
      (question, index) =>
        parseInt(question.question_id) === parseInt(question_id)
    );
  };
  useEffect(() => {
    let data = getQuestion();
    setQuestionData(data);
  }, [isLoading, questionArray?.length]);
  return {
    questionData,
    isLoading,
  };
};

// correct_answer: "option4";
// option1: "1682328095589--cropped-suzan.JPG";
// option2: "1682328095590--updated usecase.png";
// option3: "1682328095592--er.png";
// option4: "1682328095599--Screenshot 2023-03-16 115545.png";
// option_id: 7;
// option_type: "image";
// question_audio: "";
// question_id: 9;
// question_image: "";
// question_set_id: 8;
// question_title: "What is HOngkong";
// {
//   "status": true,
//   "message": "Data found successfully!!!",
//   "data": [
//       {
//           "readingQuestions": [
//               {
//                   "question_id": 44,
//                   "question_title": "What is your name?",
//                   "question_image": "",
//                   "question_audio": "",
//                   "question_set_id": 8,
//                   "option_id": 42,
//                   "option_type": "text",
//                   "option1": "Hello",
//                   "option2": "Suzan",
//                   "option3": "Rana",
//                   "option4": "World"
//               },
//               {
//                   "question_id": 45,
//                   "question_title": "Hi",
//                   "question_image": "",
//                   "question_audio": "",
//                   "question_set_id": 8,
//                   "option_id": 43,
//                   "option_type": "text",
//                   "option1": "fal",
//                   "option2": "kfa",
//                   "option3": "fawl",
//                   "option4": "faw"
//               },
//               {
//                   "question_id": 47,
//                   "question_title": "Question 1?",
//                   "question_image": "",
//                   "question_audio": "",
//                   "question_set_id": 8,
//                   "option_id": 45,
//                   "option_type": "text",
//                   "option1": "option 1",
//                   "option2": "option 2",
//                   "option3": "option 3",
//                   "option4": "option 4"
//               }
//           ],
//           "listeningQuestions": [
//               {
//                   "question_id": 43,
//                   "question_title": "What is question 2?",
//                   "question_image": "1682583386959--suzan-Page-1.drawio.png",
//                   "question_audio": "1682583386963--stomping-drums-rhythm-and-percussion-141367.mp3",
//                   "question_set_id": 8,
//                   "option_id": 41,
//                   "option_type": "image",
//                   "option1": "1682583199685--suzan-Page-1.drawio.png",
//                   "option2": "1682582221278--new-.png",
//                   "option3": "1682583316197--er (1).png",
//                   "option4": "1682582221286--use.png"
//               },
//               {
//                   "question_id": 46,
//                   "question_title": "한국어는 무엇입니까?",
//                   "question_image": "1682585706266--new-.png",
//                   "question_audio": "1682585706267--stomping-drums-rhythm-and-percussion-141367.mp3",
//                   "question_set_id": 8,
//                   "option_id": 44,
//                   "option_type": "text",
//                   "option1": "어는어는어는어는어는어는어는",
//                   "option2": "어는",
//                   "option3": "어는어는",
//                   "option4": "어는어는어는"
//               }
//           ]
//       }
//   ]
// }
