import { Button, Card } from "react-bootstrap";
import { BiEditAlt } from "react-icons/bi";
import { AudioComponent, ImageComponent } from "../../QuestionOnly/QuestionOnlyCard/QuestionOnlyCard";
import RenderFile from "./Render/RenderFile";

const ActualOptionComponent = ({
    optionObject,
    optionType,
    option_number,
    handleEditButtonClick,
  }) => {
    return (
      <div
        className="d-flex align-items-center gap-4"
        style={{
          width: "45%",
        }}
      >
        <span>{option_number}.</span>
        {/* EditOptionModal */}
        {optionType === "text" && (
          <>
            <Card
              style={{
                display: "flex",
                gap: "1rem",
                marginBlock: ".5rem",
                paddingLeft: "1rem",
                paddingBlock: ".5rem",
                textAlign: "left",
                width: "100%",
              }}
            >
              {optionObject?.[`option${option_number}`]}
            </Card>
            <Button
              style={{
                minWidth: "6rem",
              }}
              className="btn-primary btn"
              onClick={() => handleEditButtonClick(`option${option_number}`)}
            >
              <BiEditAlt color="white" />
              <span
                style={{
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Edit
              </span>{" "}
            </Button>
          </>
        )}
        {optionType === "image" && (
          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "space-between",
              marginBlock: ".5rem",
              alignItems: "center",
            }}
          >
            <div style={{}}>
              {typeof optionObject?.[`option${option_number}`] !== "string" ? (
                <>
                  <RenderFile
                    optionType={optionType}
                    file={optionObject?.[`option${option_number}`]}
                  />
                </>
              ) : (
                <ImageComponent
                  option={optionObject?.[`option${option_number}`]}
                />
              )}
            </div>
            <div className="block">
              <button
                type="button"
                className="btn-primary btn"
                onClick={() => handleEditButtonClick(`option${option_number}`)}
              >
                <BiEditAlt color="white" />
                <span
                  style={{
                    paddingLeft: ".5rem",
                  }}
                >
                  Edit
                </span>
              </button>{" "}
            </div>
          </div>
        )}
        {optionType === "audio" && (
          <>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                justifyContent: "space-between",
                marginBlock: ".5rem",
                alignItems: "center",
              }}
            >
              {typeof optionObject?.[`option${option_number}`] !== "string" ? (
                <>
                  <RenderFile
                    optionType={optionType}
                    file={optionObject?.[`option${option_number}`]}
                  />
                </>
              ) : (
                <AudioComponent
                  option={optionObject?.[`option${option_number}`]}
                />
              )}{" "}
              <Button
                className="btn-primary btn"
                onClick={() => handleEditButtonClick(`option${option_number}`)}
              >
                <BiEditAlt color="white" />
                <span
                  style={{
                    paddingLeft: ".5rem",
                  }}
                >
                  Edit
                </span>
              </Button>{" "}
            </div>
          </>
        )}
      </div>
    );
  };

  export default ActualOptionComponent