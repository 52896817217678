import React, { useState, useRef, useEffect } from 'react'
import UploadAudioBooks from '../../components/Books/UploadAudioBooks'
import AudioBookList from '../../components/Books/AudioBookList'

const AudioBooks = () => {

  return (
    <>
      <h1
        style={{
          fontSize: '1.8rem',
          marginTop: '1rem',
        }}
      >
        Audio Books
      </h1>
      <UploadAudioBooks />

      <AudioBookList

      />
    </>
  )
}

export default AudioBooks


