import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axiosInstance from "../../utils/axiosInstance";

const Home = () => {
  const [homePageData, setHomePageData] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const requests = [
        axiosInstance.get("/api/payment/payment_count_by_user"),
        axiosInstance.get("/api/questionset_count"),
        axiosInstance.get("/api/questionset_package_count"),
      ];
      setIsLoading(true);
      const responses = await Promise.all(requests).catch((error) => {
        // getting the refresh token here: 
        if (error?.response?.status === 401) {
          if (localStorage.getItem("refresh_token")) {
            let refresh_token = JSON.parse(
              localStorage.getItem("refresh_token")
            );
            axiosInstance
              .post("/api/admin/refresh", { refresh_token })
              .then(({ data }) => {
                localStorage.setItem(
                  "access_token",
                  JSON.stringify(data["access_token"])
                );
                localStorage.setItem(
                  "refresh_token",
                  JSON.stringify(data["refresh_token"])
                );
                axiosInstance.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${data["access_token"]}`;
                fetchData();
              });
          }
        }
      });
      if (!responses) return;
      const data = responses.map((response) => response.data.data);
      setHomePageData((prev) => ({
        ...prev,
        user_count: {
          ...data[0],
        },
        question_sets_count: {
          ...data[1],
        },
        package_sets_count: {
          ...data[2],
        },
      }));
      setIsLoading(false);
    };
    fetchData();
  }, []);

  /*
Response result will come as an array for each request here,
thus: active count could be at index 0 or index 1,
THEREFORE, we find active count first and remaining will be inactive count
  */
  let QuestionSub1Count =
    homePageData?.question_sets_count?.[0].status === 1
      ? homePageData?.question_sets_count?.[0].count
      : homePageData?.question_sets_count?.[1].count;
  let QuestionSub2Count =
    homePageData?.question_sets_count?.[1].status === 0
      ? homePageData?.question_sets_count?.[1].count
      : homePageData?.question_sets_count?.[0].count;

  let PackageSub1Count =
    homePageData?.package_sets_count?.[0].status === 1
      ? homePageData?.package_sets_count?.[0].count
      : homePageData?.package_sets_count?.[1].count;
  let PackageSub2Count =
    homePageData?.package_sets_count?.[1].status === 0
      ? homePageData?.package_sets_count?.[1].count
      : homePageData?.package_sets_count?.[0].count;

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="d-flex gap-4 mt-5">
          <HomeCard
            title={"User"}
            total={homePageData.user_count?.total_user_count || 0}
            sub1={"Paid"}
            sub1Count={homePageData.user_count?.total_paid_users || 0}
            sub2={"Unpaid"}
            sub2Count={homePageData.user_count?.total_unpaid_users}
          />
          <HomeCard
            title={"Question Sets"}
            total={
              homePageData?.question_sets_count?.[0].count +
              homePageData?.question_sets_count?.[1].count
            }
            sub1={"Active"}
            sub1Count={QuestionSub1Count}
            sub2={"Inactive"}
            sub2Count={QuestionSub2Count}
          />
          <HomeCard
            title={"Package Sets"}
            total={
              homePageData?.package_sets_count?.[0].count +
              homePageData?.package_sets_count?.[1].count
            }
            sub1={"Active"}
            sub1Count={PackageSub1Count}
            sub2={"Inactive"}
            sub2Count={PackageSub2Count}
          />
        </div>
      )}
    </>
  );
};

export default Home;

const HomeCard = ({ title, total, sub1, sub2, sub1Count, sub2Count }) => {
  return (
    <Card style={{ width: "22rem", paddingBottom: "2rem" }}>
      <Card.Body
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Card.Title>{title}</Card.Title>
        <Card.Text>Total: {total}</Card.Text>
      </Card.Body>
      <div
        className="d-flex"
        style={{
          justifyContent: "space-between",
          width: "80%",
          marginInline: "auto",
          alignItems: "center",
        }}
      >
        <Card
          className="py-4 px-4 mb-3 shadow-sm"
          style={{
            minWidth: "115px",
          }}
        >
          <Card.Subtitle className="text-center">{sub1}</Card.Subtitle>
          <Card.Text className="text-center pt-2">{sub1Count}</Card.Text>
        </Card>
        <Card
          className="py-4 px-4 mb-3 shadow-sm"
          style={{
            minWidth: "115px",
          }}
        >
          <Card.Subtitle className="text-center">{sub2}</Card.Subtitle>
          <Card.Text className="text-center pt-2">{sub2Count}</Card.Text>
        </Card>
      </div>
    </Card>
  );
};
