import React, { useRef, useState } from "react";
import PackageSetForm from "../../components/PackageSet/PackageSetForm/PackageSetForm";
import { Stack } from "react-bootstrap";
import PackageSetCard from "../../components/PackageSet/PackageSetCard/PackageSetCard";
import PackageSetTable from "../../components/Table/PackageSetTable";
import axiosInstance from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import handle401Exception from "../../utils/handle401Exception";
import { Search } from "../QuestionSet/QuestionSet";
import Pagination from "../../components/Pagination/Pagination";
const PackageSet = () => {
  const {
    handleDecreasePage,
    handleIncreasePage,
    isFetching,
    isLoading,
    packageSetArray,
    paginationSettings,
    totalPages,
    setPaginationSettings,
  } = useFetchPackageSets();
  return (
    <Stack>
      <PackageSetForm />
      <div className="my-4 d-flex justify-content-between align-items-center">
        <h1
          className="ml-4"
          style={{
            fontSize: "2rem",
          }}
        >
          All available Package Sets
        </h1>
        <Search
          pageUsed="PackageSetMain"
          setPaginationSettings={setPaginationSettings}
        />
      </div>
      {isLoading || isFetching ? (
        <p>Loading...</p>
      ) : (
        <div>
          <PackageSetTable packageSetArray={packageSetArray} />
          <div
            className="container "
            style={{
              textAlign: "center",
              width: "40%",
            }}
          >
            {totalPages !== null && (
              <Pagination
                currentPage={paginationSettings.currentPage}
                handleIncreasePage={handleIncreasePage}
                totalPages={totalPages}
                handleDecreasePage={handleDecreasePage}
                setPaginationSettings={setPaginationSettings}
              />
            )}
          </div>
        </div>
      )}
    </Stack>
  );
};

export default PackageSet;

const useFetchPackageSets = () => {
  const [paginationSettings, setPaginationSettings] = useState({
    currentPage: 1,
    limit: 10,
    status: 1,
    questionSetName: "",
  });

  const [totalData, setTotalData] = useState(null);

  // for scrolling whenever somehting happens
  const question_set_ref = useRef();
  const executeScroll = () => {
    if (question_set_ref && question_set_ref.current) {
      question_set_ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  let queryKey = [
    "get-package-sets",
    paginationSettings.currentPage,
    paginationSettings.limit,
    paginationSettings.questionSetName,
    paginationSettings.status,
  ];
  const queryFunction = async () =>
    await axiosInstance.get(
      `/api/questionsetpackage?page=${paginationSettings.currentPage}&limit=${paginationSettings.limit}&status=${paginationSettings.status}&packageName=${paginationSettings.questionSetName}`
    );
  const [packageSetArray, setPackageSetArray] = useState([]);

  const performSideEffects = (response) => {
    setPackageSetArray(JSON.parse(JSON.stringify(response?.data?.data || {})));
    setTotalData((prev) => response?.data?.total);
    executeScroll();
  };
  const { isLoading, isFetching } = useQuery(queryKey, queryFunction, {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      if (response.status === 200) {
        performSideEffects(response);
      }
    },
    onError: (error) => {
      if (error?.response?.status === 401) {
        handle401Exception(queryFunction, performSideEffects);
      }
    },
  });

  // pagination states

  const totalPages = totalData
    ? Math.ceil(totalData / paginationSettings.limit)
    : null;
  const handleIncreasePage = () => {
    if (paginationSettings.currentPage === totalPages) {
      return;
    }
    setPaginationSettings((prev) => ({
      ...prev,
      currentPage: prev["currentPage"] + 1,
    }));
  };
  const handleDecreasePage = () => {
    if (paginationSettings.currentPage === 1) {
      return;
    }
    setPaginationSettings((prev) => ({
      ...prev,
      currentPage: prev["currentPage"] - 1,
    }));
  };
  return {
    isLoading,
    isFetching,
    packageSetArray,
    handleDecreasePage,
    handleIncreasePage,
    totalData,
    totalPages,
    paginationSettings,
    setPaginationSettings,
  };
};
