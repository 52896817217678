import { useEffect, useRef } from "react";
import { useGlobalContext } from "../../../../utils/GlobalContext";

const RenderFile = ({ file, optionType }) => {
  const { setOpenImagePopup } = useGlobalContext();
  const audioRef = useRef()
  useEffect(() => {
    if(audioRef.current){
      audioRef.current?.load()
    }
  }, [file])
    return (
      <>
        {optionType === "audio" && (
          <audio ref={audioRef} controls>
            <source
              src={URL.createObjectURL(new Blob([file]))}
              type="audio/mpeg"
            />
          </audio>
        )}
        {optionType === "image" && (
          <img
            src={URL.createObjectURL(new Blob([file]))}
            className="img-fluid"
            style={{
              width: "200px",
              height: "200px",
              cursor: 'pointer'
            }}
            onClick={() => setOpenImagePopup(URL.createObjectURL(file))}
            alt="Image"
          />
        )}
      </>
    );
  };

  export default RenderFile