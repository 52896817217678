import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { GlobalContext } from "../../../utils/GlobalContext";
import { queryClient } from "../../..";
import axiosInstance from "../../../utils/axiosInstance";
import { useMutation } from "react-query";
import handle401Exception from "../../../utils/handle401Exception";
import { toast } from "react-hot-toast";

const RestoreModal = ({
  name,
  question_set_id,
  price,
  status,
  restoreModal,
  setRestoreModal,
}) => {
  const { setIsAppLoading } = useContext(GlobalContext);

  const handleClose = () => {
    setRestoreModal((prev) => ({
      name: "",
      price: null,
      question_set_id: null,
      status: null,
      expiry_duration: "",
    }));
  };
  const performSideEffects = () => {
    setIsAppLoading(false);
    toast.success(`${name} restored successfully.`);
    queryClient.invalidateQueries({ queryKey: ["get-question-sets"] });
    handleClose();
  };

  const queryFunction = async () => {
    return await axiosInstance
      .put(`/api/restorequestionset/${question_set_id}`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          performSideEffects();
        }
      });
  };
  const { mutate } = useMutation(queryFunction, {
    onError: (error) => {
      setIsAppLoading(false);
      if (error?.response.status === 401) {
        handle401Exception(queryFunction, performSideEffects);
      }
      handleClose();
    },
  });
  const handleDelete = () => {
    setIsAppLoading(true);
    mutate();
  };
  return (
    <>
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Restore this question Set</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to restore {name}</Modal.Body>
        <Modal.Footer>
          <Button
            style={{ background: "#EE4B2B", border: "none" }}
            variant="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Restore
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RestoreModal;
