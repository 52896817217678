import Table from "react-bootstrap/Table";
import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { useState } from "react";
import { MdOutlineRestore } from "react-icons/md";
import DeleteLiveExamModal from "../Modal/Inhouse/DeleteLiveExamModal";
import EditLiveExamModal from "../Modal/Inhouse/EditLiveExam";

const InhouseLiveExam = ({ liveExamData }) => {
  const tableHeading = [
    "S.N",
    "Exam code",
    "Title",
    "Exam Date",
    "Exam Time",
    "Exam set",
    "Actions",
  ];

  const [editModal, setEditModal] = useState({
    exam_code: "",
    exam_date_ad: "",
    exam_time: "",
    exam_title: "",
    set_id: "",
    exam_id: null,
  });
  const [deleteModal, setDeleteModal] = useState({
    exam_title: "",
    exam_id: null,
  });

  const handleOpenDeleteModal = (live_exam_data) => {
    setDeleteModal({
      ...live_exam_data,
    });
  };

  const handleOpenEditModal = (live_exam_data) => {
    setEditModal({
      ...live_exam_data,
    });
  };

  return (
    <>
      {editModal.exam_id !== null && (
        <EditLiveExamModal {...editModal} setEditModal={setEditModal} />
      )}
      {deleteModal.exam_id !== null && (
        <DeleteLiveExamModal {...deleteModal} setDeleteModal={setDeleteModal} />
      )}
      <Table responsive hover>
        <thead>
          <tr>
            {tableHeading.map((heading, index) => {
              return <th key={index}>{heading}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {liveExamData.map((exam_set, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{exam_set.exam_code}</td>
              <td>{exam_set.exam_title}</td>
              <td>{new Date(exam_set.exam_date_ad).toLocaleDateString()}</td>
              <td>{exam_set.exam_time}</td>
              <td>{exam_set.set_title}</td>
              <td className="d-flex gap-3">
                <>
                  <>
                    {" "}
                    <span
                      className="d-flex gap-2 align-items-center"
                      onClick={() =>
                        handleOpenEditModal({
                          ...exam_set,
                        })
                      }
                    >
                      <BiEditAlt color="green" />
                      <span
                        style={{
                          color: "green",
                          cursor: "pointer",
                        }}
                      >
                        Edit
                      </span>{" "}
                    </span>
                    <span
                      className="d-flex gap-2 align-items-center"
                      onClick={() => handleOpenDeleteModal(exam_set)}
                    >
                      <AiFillDelete color="red" />
                      <span
                        style={{
                          color: "red",
                          cursor: "pointer",
                        }}
                      >
                        Delete
                      </span>{" "}
                    </span>
                  </>
                </>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default InhouseLiveExam;

// {
//     "exam_id": 1,
//     "exam_code": "93ka",
//     "exam_title": "klfaw",
//     "exam_date_ad": "2023-06-07T18:15:00.000Z",
//     "exam_time": "13:16:00",
//     "set_id": 7,
//     "set_title": "Hello guys"
// },
