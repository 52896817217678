import React, { useState } from "react";
import { Card, Accordion, Figure, Button } from "react-bootstrap";
import { BiEditAlt } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import { AudioComponent, ImageComponent } from "../QuestionOnly/QuestionOnlyCard/QuestionOnlyCard";
import { FcOk } from "react-icons/fc"
import { AiFillCloseCircle } from "react-icons/ai"

const ExamReviewCard = (props) => {
    const [activeKey, setActiveKey] = useState("0");


    const { data, index } = props
    let {
        question_title,
        question_subtitle,
        question_text,
        question_image,
        question_audio,
        option1,
        option2,
        option3,
        option4,
        correct_answer,
        option_type,
        submitted_answer
    } = data
    const options = [option1, option2, option3, option4];

    const handleAccordionClick = (event) => {
        event.stopPropagation();
        setActiveKey("1");

        if (activeKey === "0") {
            setActiveKey("1");
        }
        if (activeKey === "1") {
            setActiveKey("0");
        }
    };

    return (
        <div className="d-flex justify-content-left mt-2">
            <Card
                style={{
                    width: "80%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                }}
            >
                <Accordion
                    style={{
                        flexGrow: "1",
                    }}
                    defaultActiveKey={activeKey}
                >
                    <Accordion.Item onClick={handleAccordionClick}>
                        <Accordion.Header
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                alignItems: "center",
                                overflow: "hidden",
                            }}
                        >
                            <span
                                style={{
                                    width: "90%",
                                }}
                            >
                                {index + 1}. {question_title}
                            </span>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "2rem",
                                }}
                            >
                            </div>
                            <span>{submitted_answer == null ? "" : correct_answer == submitted_answer ? <FcOk style={{ height: "30px", width: "30px" }} color="green" /> : <AiFillCloseCircle style={{ height: "30px", width: "30px" }} color="red" />}</span>
                        </Accordion.Header>
                        <>
                            {activeKey === "1" && (
                                <>
                                    <Accordion.Body
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        {" "}
                                        {question_subtitle && question_subtitle !== " " && (
                                            <div>
                                                <p
                                                    style={{
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    Question Subtitle:
                                                </p>
                                                <p>{question_subtitle}</p>
                                            </div>
                                        )}
                                        {question_text && question_text !== " " && (
                                            <div>
                                                <p
                                                    style={{
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    Question Text:
                                                </p>
                                                <p>{question_text}</p>
                                            </div>
                                        )}
                                    </Accordion.Body>
                                    <Accordion.Body
                                        style={{
                                            display: "flex",
                                            gap: "5rem",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <>
                                            {" "}
                                            {question_image && (
                                                <div>
                                                    <p
                                                        style={{
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        Question Image:
                                                    </p>
                                                    <ImageComponent option={question_image} />
                                                </div>
                                            )}
                                            {question_audio && (
                                                <div>
                                                    <p
                                                        style={{
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        Question Audio:
                                                    </p>
                                                    <AudioComponent option={question_audio} />
                                                </div>
                                            )}
                                        </>
                                    </Accordion.Body>
                                    <Accordion.Body
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        {options.length !== 0 &&
                                            correct_answer &&
                                            options.map((option, idx) => (
                                                <AnswerOption
                                                    key={idx}
                                                    optionType={option_type}
                                                    option={option}
                                                    index={idx}
                                                    correct_answer={parseInt(correct_answer.split("")[6])}
                                                    submitted_answer={submitted_answer == null ? 0 : parseInt(submitted_answer.split("")[6])}
                                                />
                                            ))}
                                        <div
                                            className="my-4"
                                            style={
                                                {
                                                    // marginLeft: "1rem",
                                                    // border: "2px solid red",
                                                }
                                            }
                                        ></div>
                                    </Accordion.Body>
                                </>
                            )}
                        </>
                    </Accordion.Item>
                </Accordion>
            </Card>
        </div>
    )
}

const chooseColor = (index, correct_answer, submitted_answer) => {
    if ((index == correct_answer - 1)) {
        if (submitted_answer == correct_answer) {
            return "lightgreen"
        }
        if (submitted_answer == 0) {
            return "white"
        }
        return "lightgreen"
    } else if (index !== correct_answer - 1) {
        if (index == submitted_answer - 1) {
            return "red"
        }
        return "white"
    }
}

const AnswerOption = ({ option, index, optionType, correct_answer, submitted_answer }) => {
    return (
        <Card
            style={{
                backgroundColor: chooseColor(index, correct_answer, submitted_answer),
                cursor: "pointer",
                paddingInline: "1rem",
                marginBlock: ".5rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1rem",
                paddingBottom: "0",
                paddingBlock: ".5rem",
            }}
        >
            <span>{index + 1}.</span>
            {optionType === "text" && option}
            {optionType === "image" && (
                <div className="w-25 py-3">
                    <ImageComponent option={option} />
                </div>
            )}
            {optionType === "audio" && (
                <Figure
                    // className="my-4"
                    style={{
                        // border: "2px solid black",
                        marginBottom: "0",
                        paddingTop: "0.5rem",
                    }}
                >
                    <AudioComponent option={option} />
                </Figure>
            )}
        </Card>
    );
};


export default ExamReviewCard