import React from "react";
import QuestionOnlyCard from "../QuestionOnly/QuestionOnlyCard/QuestionOnlyCard";

const RenderQuestions = ({ questionArray, question_set_name, pageUsed }) => {
  return (
    <>
      {questionArray.map((question, index) => (
        <div className="col" key={index}>
          <QuestionOnlyCard
            question_set_name={question_set_name}
            title={question.question_title}
            key={index}
            index={index}
            {...question}
            pageUsed={pageUsed}
            editLink={
              pageUsed === "InhouseQuestion"
                ? `/inhousequestion/${encodeURIComponent(question_set_name)}/${question?.set_id}/view/${question.question_id}`
                : `/questionset/${encodeURIComponent(question_set_name)}/${question.question_set_id}/view/${question.question_id}`
            }
          />
        </div>
      ))}
    </>
  );
};
export default RenderQuestions;
