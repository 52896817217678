import React, { useRef, useState } from "react";
import { Stack, Button, Card } from "react-bootstrap";
import QuestionSetCard from "../../components/QuestionSet/QuestionSetCard/QuestionSetCard";
import { useParams } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import { AvailableQuestionSets } from "../QuestionSet/QuestionSet";
import { useQuery } from "react-query";
import handle401Exception from "../../utils/handle401Exception";
const PackageOnly = () => {
  const { id, package_name } = useParams();

  const questionSetCollectionByPackage = useFetchQuestionSetsByPackageId(id);
  return (
    <Stack>
      <AvailableQuestionSets
      package_name={package_name}
        pageUsed={"PackageSet"}
        {...questionSetCollectionByPackage}
      />
    </Stack>
  );
};

export default PackageOnly;
export const useFetchQuestionSetsByPackageId = (packageId) => {
  const [paginationSettings, setPaginationSettings] = useState({
    currentPage: 1,
    limit: 10,
    status: 1,
    questionSetName: "",
  });

  const [totalData, setTotalData] = useState(null);

  // for scrolling whenever somehting happens
  const question_set_ref = useRef();
  const executeScroll = () => {
    if (question_set_ref && question_set_ref.current) {
      question_set_ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  let queryKey = [
    "get-question-sets",
    packageId,
    paginationSettings.currentPage,
    paginationSettings.limit,
    paginationSettings.questionSetName,
    paginationSettings.status,
  ];
  const queryFunction = async () =>
    await axiosInstance.get(
      `/api/questionsetpackage/${packageId}?page=${paginationSettings.currentPage}&limit=${paginationSettings.limit}&status=${paginationSettings.status}`
    );
  const [questionSetArray, setQuestionSetArray] = useState([]);

  const performSideEffects = (response) => {
    setQuestionSetArray(JSON.parse(JSON.stringify(response?.data?.data || {})));
    setTotalData((prev) => response?.data?.total);
    executeScroll();
  };
  const { isLoading, isFetching } = useQuery(queryKey, queryFunction, {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      if (response.status === 200) {
        performSideEffects(response);
      }
    },
    enabled: !!packageId,
    onError: (error) => {
      if (error?.response?.status === 401) {
        handle401Exception(queryFunction, performSideEffects);
      }
    },
  });

  // pagination states

  const totalPages = totalData
    ? Math.ceil(totalData / paginationSettings.limit)
    : null;
  const handleIncreasePage = () => {
    if (paginationSettings.currentPage === totalPages) {
      return;
    }
    setPaginationSettings((prev) => ({
      ...prev,
      currentPage: prev["currentPage"] + 1,
    }));
  };
  const handleDecreasePage = () => {
    if (paginationSettings.currentPage === 1) {
      return;
    }
    setPaginationSettings((prev) => ({
      ...prev,
      currentPage: prev["currentPage"] - 1,
    }));
  };
  return {
    handleDecreasePage,
    handleIncreasePage,
    paginationSettings,
    totalData,
    questionSetArray,
    setQuestionSetArray,
    isFetching,
    isLoading,
    setPaginationSettings,
    question_set_ref,
    totalPages,
  };
};
