import React, { useRef, useState } from "react";
import { Stack, Button } from "react-bootstrap";
import QuestionForm from "../../components/QuestionSet/QuestionSetForm/QuestionSetForm";
import { useQuery } from "react-query";
import axiosInstance from "../../utils/axiosInstance";
import handle401Exception from "../../utils/handle401Exception";
import Table from "../../components/Table/QuestionSetTable";
import Pagination from "../../components/Pagination/Pagination";
import { BiSearch } from "react-icons/bi";
const QuestionSet = () => {
  const questionSetDetails = useFetchQuestionSets();
  return (
    <Stack>
      <QuestionForm />
      <AvailableQuestionSets pageUsed={"QuestionSet"} {...questionSetDetails} />
    </Stack>
  );
};

export const AvailableQuestionSets = ({
  paginationSettings,
  handleDecreasePage,
  handleIncreasePage,
  isFetching,
  isLoading,
  questionSetArray,
  setPaginationSettings,
  question_set_ref,
  totalPages,
  pageUsed,
  package_name,
}) => {
  return (
    <>
      {" "}
      <div className="my-4 d-flex justify-content-between align-items-center">
        {pageUsed === "PackageSet" ? (
          <div>
            <h1
              className="ml-4"
              style={{
                fontSize: "2rem",
              }}
            >
              {package_name}
            </h1>
            <p style={{
              fontSize: '1.25rem',
              marginTop: '1rem'
            }}> All available Question Sets</p>
          </div>
        ) : (
          <h1
            className="ml-4"
            style={{
              fontSize: "2rem",
            }}
          >
            All available Question Sets
          </h1>
        )}

        {pageUsed === "PackageSet" ? null : (
          <Search setPaginationSettings={setPaginationSettings} />
        )}
      </div>
      {isLoading || isFetching ? (
        <p>Loading...</p>
      ) : (
        <div className="row gap-4">
          {questionSetArray !== undefined && questionSetArray.length !== 0 ? (
            <Table pageUsed={pageUsed} questionSetArray={questionSetArray} />
          ) : (
            <p>No question set available</p>
          )}
          <div
            className="container "
            style={{
              textAlign: "center",
              width: "40%",
            }}
            ref={question_set_ref}
          >
            {pageUsed !== "PackageSet" && totalPages !== null && (
              <Pagination
                currentPage={paginationSettings.currentPage}
                handleIncreasePage={handleIncreasePage}
                totalPages={totalPages}
                handleDecreasePage={handleDecreasePage}
                setPaginationSettings={setPaginationSettings}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default QuestionSet;


export const Search = ({ setPaginationSettings, pageUsed }) => {
  const questionSetNameRef = useRef();
  const statusRef = useRef();

  const handleSearch = () => {
    let status = statusRef.current.value;
    let inputText;

    inputText = questionSetNameRef.current.value;


    setPaginationSettings((prev) => ({
      ...prev,
      limit: 10,
      status: status === "Inactive" ? 0 : 1,
      questionSetName: inputText,
    }));
  };
  return (
    <div className="d-flex align-items-center gap-2  ">
      <input
        type="text"
        className="form-control"
        id="exampleFormControlInput1"
        placeholder={
          pageUsed === "PackageSetMain" ? "Package Name" : "Question Set Name"
        }
        style={{
          minWidth: "200px",
        }}
        ref={questionSetNameRef}
      />

      <select
        className="form-select"
        aria-label="Default select example"
        ref={statusRef}
      >
        <option selected value="Active">
          Active
        </option>
        <option value="Inactive">Inactive</option>
      </select>
      <button
        className="btn btn-primary"
        style={{
          minWidth: "7rem",
          display: "flex",
          alignItems: "center",
          gap: ".25rem",
        }}
        onClick={handleSearch}
      >
        <BiSearch />
        Search
      </button>
    </div>
  );
};

export const useFetchQuestionSets = () => {
  const [paginationSettings, setPaginationSettings] = useState({
    currentPage: 1,
    limit: 10,
    status: 1,
    questionSetName: "",
  });

  const [totalData, setTotalData] = useState(null);

  // for scrolling whenever somehting happens
  const question_set_ref = useRef();
  const executeScroll = () => {
    if (question_set_ref && question_set_ref.current) {
      question_set_ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  let queryKey = [
    "get-question-sets",
    paginationSettings.currentPage,
    paginationSettings.limit,
    paginationSettings.questionSetName,
    paginationSettings.status,
  ];
  const queryFunction = async () =>
    await axiosInstance.get(
      `/api/questionset?page=${paginationSettings.currentPage}&limit=${paginationSettings.limit}&status=${paginationSettings.status}&questionSetName=${paginationSettings.questionSetName}`
    );
  const [questionSetArray, setQuestionSetArray] = useState([]);

  const performSideEffects = (response) => {
    setQuestionSetArray(JSON.parse(JSON.stringify(response?.data?.data || {})));
    setTotalData((prev) => response?.data?.total);
    executeScroll();
  };
  const { isLoading, isFetching } = useQuery(queryKey, queryFunction, {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      if (response.status === 200) {
        performSideEffects(response);
      }
    },
    onError: (error) => {
      if (error?.response?.status === 401) {
        handle401Exception(queryFunction, performSideEffects);
      }
    },
  });

  // pagination states

  const totalPages = totalData
    ? Math.ceil(totalData / paginationSettings.limit)
    : null;
  const handleIncreasePage = () => {
    if (paginationSettings.currentPage === totalPages) {
      return;
    }
    setPaginationSettings((prev) => ({
      ...prev,
      currentPage: prev["currentPage"] + 1,
    }));
  };
  const handleDecreasePage = () => {
    if (paginationSettings.currentPage === 1) {
      return;
    }
    setPaginationSettings((prev) => ({
      ...prev,
      currentPage: prev["currentPage"] - 1,
    }));
  };
  return {
    handleDecreasePage,
    handleIncreasePage,
    paginationSettings,
    totalData,
    questionSetArray,
    setQuestionSetArray,
    isFetching,
    isLoading,
    setPaginationSettings,
    question_set_ref,
    totalPages,
  };
};
