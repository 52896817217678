import React from "react";

const LoadingSpinner = () => {
  return (
    <>
      <div
        style={{
          zIndex: "100",
          position: "fixed",
          left: "0",
          right: "0",
          top: "0",
          bottom: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "black",
          opacity: "0.3",
          height: "100vh",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            // border: '1px solid red',
            paddingBlock: "1rem",
            borderRadius: "6px",
            paddingInline: "1rem",
          }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingSpinner;
