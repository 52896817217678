import { useEffect, useState } from "react";
import { getDecodedToken } from "../utils/getDecodedToken";

const useRedirectIfAlreadyLoggedIn = () => {
  const [redirectToHomePage, setRedirectToHomePage] = useState(false);
  useEffect(() => {
    const { access_token, refresh_token } = getDecodedToken();
    if (access_token && refresh_token) {
      setRedirectToHomePage(true);
    }
  }, []);
  return { redirectToHomePage };
};

export default useRedirectIfAlreadyLoggedIn;
