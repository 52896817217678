import React, { useEffect, useState } from "react";
import { Stack, Button, Card } from "react-bootstrap";
import AddQuestionSet from "../AddQuestionSet/AddQuestionSet";
import axiosInstance from "../../../utils/axiosInstance";
import { useMutation } from "react-query";
import handle401Exception from "../../../utils/handle401Exception";
import { toast } from "react-hot-toast";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { queryClient } from "../../..";
import { IoMdCreate } from "react-icons/io";

const PackageSetForm = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [packageData, setPackageData] = useState({
    package_name: "",
    price: 0,
    selected_question_sets: [],
  });

  // selected question sets : [ { question_set_id: 12, question_set_name: 'Hari' }]

  const handleInputChange = (e) => {
    if (e.target.value <= -1 && e.target.name === "price") {
      toast.error("Price cannot be negative.");
      return;
    }

    setPackageData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // create new package set api integration
  const queryFunction = async (bodyData) => {
    return await axiosInstance.post(`/api/questionsetpackage`, bodyData);
  };

  const performSideEffects = () => {
    queryClient.invalidateQueries({ queryKey: ["get-package-sets"] });
    setPackageData({
      package_name: "",
      price: 0,
      selected_question_sets: [],
    });
  };

  const { mutate, isLoading, isFetching } = useMutation(queryFunction, {
    onSuccess: (response) => {
      if (response.status === 201) {
        performSideEffects();
      }
    },
    onError: (error) => {
      if (error?.response?.status === 401) {
        const { selected_question_sets, ...rest } = packageData;

        // extract name from question_set
        const question_set_id = selected_question_sets.reduce(
          (acc, current) => [...acc, current.question_set_id],
          []
        );
        mutate({
          ...rest,
          question_set_id,
        });

        handle401Exception(
          () => queryFunction(packageData),
          performSideEffects
        );
      }
    },
  });

  const handleSubmit = () => {
    if (!packageData.package_name) {
      toast.error("Please specify package set Name");
      return;
    }
    if (!packageData.price && packageData.price !== 0) {
      toast.error("Please set a price for package set.");
      return;
    }
    if (packageData.selected_question_sets.length === 0) {
      toast.error("Please select at least one question set.");
      return;
    }

    const { selected_question_sets, ...rest } = packageData;

    // extract name from question_set
    const question_set_id = selected_question_sets.reduce(
      (acc, current) => [...acc, current.question_set_id],
      []
    );
    mutate({
      ...rest,
      question_set_id,
    });
  };

  return (
    <>
      {(isLoading || isFetching) && <LoadingSpinner />}
      <h1
        className="mt-5"
        style={{
          fontSize: "2rem",
        }}
      >
        Create New Package Set
      </h1>

      <form
        className="mb-5"
        style={{
          width: "40%",
        }}
      >
        <div className="mb-3">
          <label className="form-label">
            Package Name
            <span
              style={{
                paddingLeft: ".25rem",
                color: "red",
                fontSize: "1rem",
              }}
            >
              *
            </span>
          </label>
          <input
            value={packageData.package_name}
            name="package_name"
            onChange={handleInputChange}
            style={{
              width: "85%",
            }}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            Price{" "}
            {/* <span
              style={{
                paddingLeft: ".25rem",
                color: "red",
                fontSize: "1rem",
              }}
            >
              *
            </span> */}
          </label>
          <input
            value={packageData.price}
            name="price"
            onChange={handleInputChange}
            type="number"
            className="form-control"
            min={0}
            style={{
              width: "85%",
            }}
          />
        </div>

        {packageData.selected_question_sets.length !== 0 && (
          <>
            <p>Selected Question Sets:</p>
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: ".5rem",
                width: "85%",
              }}
            >
              {packageData.selected_question_sets.map((item, index) => (
                <Card
                  style={{
                    padding: ".5rem",
                  }}
                >
                  {item.question_set_name}
                </Card>
              ))}
            </div>
          </>
        )}

        <Stack direction="horizontal" gap={2}>
          <Button
            variant="primary"
            // style={{ background: "#EE4B2B", border: "none" }}
            onClick={() => setModalOpen(true)}
          >
            + Add Question Sets
          </Button>
          <Button onClick={handleSubmit}>
            {" "}
            <IoMdCreate /> Create Package Set
          </Button>
        </Stack>
        {modalOpen && (
          <AddQuestionSet
            setPackageData={setPackageData}
            open={modalOpen}
            setOpen={setModalOpen}
            packageData={packageData}
          />
        )}
      </form>
    </>
  );
};

export default PackageSetForm;
