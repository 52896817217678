import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Form, Figure, Stack } from "react-bootstrap";
import axiosInstance from "../../../utils/axiosInstance";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import handle401Exception from "../../../utils/handle401Exception";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { queryClient } from "../../../index";
import { AiOutlineClear } from "react-icons/ai";
import { IoMdCreate } from "react-icons/io";
import AddOptions from "./AddOptions";
import OptionsRenderer from "./OptionsRenderer";
import ImageCropperModal from "../../ImageCropper/ImageCropperModal";
import { useGlobalContext } from "../../../utils/GlobalContext";

const QuestionOnlyForm = ({ questionLength, API_URL, queryKey }) => {
  const { id } = useParams();
  const [questionTitle, setQuestionTitle] = useState("");
  const [optionsList, setOptionsList] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState("");

  // option type : text, audio, or image
  const [selectedOptionType, setSelectedOptionType] = useState("text");

  // question subtitle and question text
  const [questionSubtitleAndText, setQuestionSubtitleAndText] = useState({
    question_subtitle: "",
    question_text: "",
  });

  const imageFileRef = useRef();
  const audioFileRef = useRef();

  // for image file
  const [selectedImage, setSelectedImage] = useState("");

  // open to crop image
  const [openCropImageModal, setOpenCropImageModal] = useState(null);

  const handleUploadImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    if (e.target.files[0].size > "250000") {
      e.target.value = "";
      toast.error("Image files cannot be more than 250KB");
      return;
    }

    setOpenCropImageModal(e.target.files[0]);

    // setSelectedImage(e.target.files[0]);
  };

  // for audio files
  const [audioFile, setAudioFile] = useState(null);
  const handleUploadAudio = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    if (e.target.files[0].size > "5242880") {
      e.target.value = "";
      toast.error("Audio files cannot be more than 5MB");
      return;
    }
    setAudioFile(e.target.files[0]);
  };

  const handleRemoveImageCache = () => {
    if (imageFileRef.current.value) {
      imageFileRef.current.value = null;
    }
  };
  const handleClearAll = () => {
    setOptionsList([]);
    setCorrectAnswer("");
    setSelectedImage(null);
    setAudioFile(null);
    setQuestionTitle("");

    setSelectedOptionType("text");
    setOptionValue("");
    setQuestionSubtitleAndText({
      question_subtitle: "",
      question_text: "",
    });

    if (audioFileRef.current.value) {
      audioFileRef.current.value = null;
    }
    handleRemoveImageCache();
  };

  // radio button change
  const handleRadioButtonChange = (type) => {
    if (optionsList.length !== 0) {
      setOptionsList([]);
    }
    setSelectedOptionType(type);
  };

  // side effect
  const performSideEffects = () => {
    queryClient.invalidateQueries({ queryKey });
    handleClearAll();
  };
  // query function
  const queryFunction = async (question_data) => {
    return await axiosInstance.post(API_URL, question_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };
  const [optionValue, setOptionValue] = useState("");

  const { mutate, isLoading } = useMutation({
    mutationFn: queryFunction,
    onSuccess: (response) => {
      if (response.status === 201 || response?.status === 200) {
        performSideEffects();
      }
    },
    onError: (error) => {
      if (error?.response?.status === 401) {
        let correct_answer = `option${parseInt(correctAnswer)}`;
        let formData = new FormData();
        formData.append("question_title", questionTitle);
        formData.append("question_set_id", id);
        formData.append("option_type", selectedOptionType);
        formData.append("option1", optionsList[0]);
        formData.append("option2", optionsList[1]);
        formData.append("option3", optionsList[2]);
        formData.append("option4", optionsList[3]);
        formData.append("correct_answer", correct_answer);
        formData.append(
          "question_subtitle",
          questionSubtitleAndText.question_subtitle
        );
        formData.append("question_text", questionSubtitleAndText.question_text);

        if (selectedImage !== null) {
          formData.append("question_image", selectedImage);
        }
        if (audioFile !== null) {
          formData.append("question_audio", audioFile);
        }
        handle401Exception(() => queryFunction(formData), performSideEffects);
      }
    },
  });

  const handleSubmit = () => {
    if (!questionTitle) {
      toast.error("Question Title is required.");
      return;
    }
    if (optionsList.length !== 4) {
      toast.error("There must be 4 possible options.");
      return;
    }
    // console.log("correct", correctAnswer, typeof correctAnswer);
    if (correctAnswer === "" || !correctAnswer) {
      toast.error("Please select a correct answer");
      return;
    }

    if (questionLength >= 40) {
      toast.error("Limit reached. Maximum 40 questions can be added");
      return;
    }

    let correct_answer = `option${parseInt(correctAnswer)}`;
    let formData = new FormData();
    formData.append("question_title", questionTitle);
    formData.append("question_set_id", id);
    formData.append("option_type", selectedOptionType);
    formData.append(
      "question_subtitle",
      questionSubtitleAndText.question_subtitle
    );
    formData.append("question_text", questionSubtitleAndText.question_text);
    formData.append("option1", optionsList[0]);
    formData.append("option2", optionsList[1]);
    formData.append("option3", optionsList[2]);
    formData.append("option4", optionsList[3]);
    formData.append("correct_answer", correct_answer);
    if (selectedImage !== null) {
      formData.append("question_image", selectedImage);
    }
    if (audioFile !== null) {
      formData.append("question_audio", audioFile);
    }
    // console
    mutate(formData);
  };
  const { setOpenImagePopup } = useGlobalContext();


  const questionAudioChangingRef = useRef();
  useEffect(() => {
    if (questionAudioChangingRef.current) {
      questionAudioChangingRef.current?.load();
    }
  }, [audioFile]);

  // useEffect(() => {
  //   if (selectedImage && showImageRef) {
  //     showImageRef.current.src = URL.createObjectURL(selectedImage);
  //     showImageRef.current.cache = 'no-cache'
  //   }
  // }, [selectedImage]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <form
      className="my-5"
      style={{
        width: "50%",
      }}
    >
      {openCropImageModal && (
        <ImageCropperModal
          setOpenCropImageModal={setOpenCropImageModal}
          openCropImageModalData={openCropImageModal}
          setSelectedImage={setSelectedImage}
          handleRemoveImageCache={handleRemoveImageCache}
        />
      )}
      <h1>Create New Question</h1>
      <div className="mb-3">
        <label className="form-label">
          Question{" "}
          <span
            style={{
              color: "red",
              fontSize: "1rem",
            }}
          >
            {" "}
            *
          </span>{" "}
        </label>
        <input
          value={questionTitle}
          onChange={(e) => setQuestionTitle(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Question Subtitle </label>
        <textarea
          value={questionSubtitleAndText.question_subtitle}
          onChange={(e) =>
            setQuestionSubtitleAndText((prev) => ({
              ...prev,
              question_subtitle: e.target.value,
            }))
          }
          className="form-control"
          style={{
            minHeight: "7rem",
          }}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Question Text </label>
        <textarea
          value={questionSubtitleAndText.question_text}
          onChange={(e) =>
            setQuestionSubtitleAndText((prev) => ({
              ...prev,
              question_text: e.target.value,
            }))
          }
          className="form-control"
          style={{
            minHeight: "7rem",
          }}
        />
      </div>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>
          Upload Question Image{" "}
          <span
            style={{
              color: "red",
              fontSize: ".8rem",
              marginLeft: "1rem",
            }}
          >
            *Image file cannot exceed 256KB*
          </span>{" "}
        </Form.Label>
        <Form.Control
          type="file"
          accept="image/png, image/gif, image/jpeg"
          onChange={handleUploadImage}
          ref={imageFileRef}
        />
        {selectedImage && (
          <Figure className="my-4" style={{
            width: '80%'
          }}>
            <img
              style={{
                display: "block",
                maxWidth: '100%',
                cursor: 'pointer'
              }}
              onClick={() => {
                setOpenImagePopup(URL.createObjectURL(new File([selectedImage], "image")))
              }}
              // width={500}
              // height={300}
              // ref={showImageRef}
              src={URL.createObjectURL(new File([selectedImage], "image"))}
              alt=""
            /> 
          </Figure>
        )}
      </Form.Group>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>
          Upload Question Audio{" "}
          <span
            style={{
              color: "red",
              fontSize: ".8rem",
              marginLeft: "1rem",
            }}
          >
            *Audio file cannot exceed 5MB*
          </span>{" "}
        </Form.Label>
        <Form.Control
          type="file"
          accept="audio/*"
          onChange={handleUploadAudio}
          ref={audioFileRef}
        />
        {audioFile !== null && (
          <Figure className="my-4">
            <audio ref={questionAudioChangingRef} controls>
              <source src={URL.createObjectURL(audioFile)} type="audio/mpeg" />
            </audio>
          </Figure>
        )}
      </Form.Group>

      <Form.Label>
        Option Type{" "}
        <span
          style={{
            color: "red",
            fontSize: "1rem",
          }}
        >
          {" "}
          *
        </span>{" "}
      </Form.Label>

      <div className="mb-3">
        <Form.Check
          inline
          label="Text"
          name="group1"
          type={"radio"}
          checked={selectedOptionType === "text" ? true : false}
          onChange={() => {
            handleRadioButtonChange("text");
          }}
          // id={`inline-${type}-1`}
        />
        <Form.Check
          inline
          label="Image"
          name="group1"
          checked={selectedOptionType === "image" ? true : false}
          type={"radio"} // id={`inline-${type}-2`}
          onChange={() => {
            handleRadioButtonChange("image");
          }}
        />
        <Form.Check
          inline
          label="Audio"
          name="group1"
          checked={selectedOptionType === "audio" ? true : false}
          type={"radio"} // id={`inline-${type}-2`}
          onChange={() => {
            handleRadioButtonChange("audio");
          }}
        />
      </div>
      <div className="mb-3">
        {optionsList.length !== 4 && (
          <AddOptions
            optionsList={optionsList}
            setOptionsList={setOptionsList}
            optionType={selectedOptionType}
            optionValue={optionValue}
            setOptionValue={setOptionValue}
          />
        )}
        {optionsList.length !== 0 && (
          <>
            <p className="mt-2">
              <span style={{ color: "red" }}>*</span> Select correct answer from
              options by clicking them below:{" "}
              <span style={{ color: "red" }}>*</span>
            </p>
            <OptionsRenderer
              optionsList={optionsList}
              setOptionsList={setOptionsList}
              optionType={selectedOptionType}
              correctAnswer={correctAnswer}
              setCorrectAnswer={setCorrectAnswer}
            />
          </>
        )}
      </div>

      <Stack direction="horizontal" gap={2}>
        <Button
          onClick={handleSubmit}
          style={{
            display: "flex",
            gap: ".5rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IoMdCreate />
          Create Question
        </Button>
        <Button
          variant="secondary"
          onClick={handleClearAll}
          style={{
            display: "flex",
            gap: ".5rem",
            justifyContent: "center",
            alignItems: "center",
            background: "#EE4B2B",
            border: "none",
          }}
        >
          <AiOutlineClear />
          Clear
        </Button>
      </Stack>
    </form>
  );
};

export default QuestionOnlyForm;
