import { Modal, Form, Button } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { queryClient } from "../../../index";
import { useContext } from "react";
import { GlobalContext, useGlobalContext } from "../../../utils/GlobalContext";
import axiosInstance from "../../../utils/axiosInstance";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import handle401Exception from "../../../utils/handle401Exception";
import { IoMdCreate } from "react-icons/io";
import { AiOutlineClear, AiOutlineCloseCircle } from "react-icons/ai";

const EditBookList = ({ book_name, status, book_id, setEditModal }) => {
    const { setIsAppLoading } = useGlobalContext()
    const [formData, setFormData] = useState({
        book_name,
        status
    });


    // on input field change
    const handleOnChange = (e) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    // query function to add question set
    const queryFunction = async (user_data) => {
        setIsAppLoading(true)
        return await axiosInstance
            .put("/api/updatebookinfo", user_data)
            .then((response) => {
                if (response.status === 201) {
                    performSideEffects(response);
                }
            });
    };

    const performSideEffects = () => {
        queryClient.invalidateQueries({
            queryKey: ["get-inhouse-students"],
        });
        setFormData({
           book_name: "",
            status: "1",
        });
        setIsAppLoading(false)
    };

    const { mutate, isLoading } = useMutation({
        mutationFn: queryFunction,
        onSuccess: (response) => { },
        onError: (error) => {
            if (error?.response?.status === 401) {
                handle401Exception(
                    () =>
                        queryFunction({
                            book_id,
                            ...formData,
                        }),
                    performSideEffects
                );
                setIsAppLoading(false)
            } else {
                setIsAppLoading(false)
            }
        },
    });

    const handleSubmit = () => {
        if (formData.book_name === "") {
            toast.error("Please enter name of book.");
            return;
        }
        if (formData.status === "") {
            toast.error("Please select status.");
            return;
        }


        mutate({
            book_id,
            ...formData,
        });
    };

    const handleClose = () => {
        setEditModal({
            book_id: ""
        })
    };

    return (
        <>
            <Modal show={true} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Book</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="mb-3">
                            <label className="form-label">
                                Book Name{" "}
                                <span
                                    style={{
                                        color: "red",
                                        paddingLeft: ".25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    *
                                </span>{" "}
                            </label>
                            <input
                                value={formData.book_name}
                                onChange={handleOnChange}
                                name="book_name"
                                className="form-control shadow-sm"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">
                                Status{" "}
                                <span
                                    style={{
                                        color: "red",
                                        paddingLeft: ".25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    *
                                </span>{" "}
                            </label>
                            <select
                                value={formData.status}
                                onChange={handleOnChange}
                                name="status"
                                className="form-select shadow-sm"
                            >
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                        style={{
                            display: "flex",
                            gap: ".5rem",
                            alignItems: "center",
                            background: "#EE4B2B",
                            border: "none",
                        }}
                    >
                        <AiOutlineCloseCircle />

                        <span className="ml-3">Close</span>
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        <IoMdCreate />
                        <span> Update</span>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditBookList;
