import React, { useEffect, useState } from "react";
import axiosInstance from "../../../utils/axiosInstance";
import { InputGroup, Stack, Button, Form, Modal, Col } from "react-bootstrap";
import { useQuery } from "react-query";
import handle401Exception from "../../../utils/handle401Exception";
import { IoMdCreate } from "react-icons/io";
import { AiOutlineCloseCircle } from "react-icons/ai";

function AddQuestionSet({ open, setOpen, setPackageData, packageData }) {
  const handleClose = () => {
    setOpen(false);
  };

  const { isLoading, isFetching, allQuestionSetCollection, totalData } =
    useFetchAllQuestionSetsWithoutPagination();

  const [selectedQuestionSets, setSelectedQuestionSets] = useState([
    ...packageData.selected_question_sets,
  ]);
  const handleClickOnQuestionSet = (question_set_id, question_set_name) => {
    const findQuestionSet = selectedQuestionSets.find(
      (item) => item.question_set_id === question_set_id
    );

    if (!findQuestionSet) {
      // then insert into the list
      setSelectedQuestionSets((prev) => [
        ...prev,
        {
          question_set_id,
          question_set_name,
        },
      ]);
    } else {
      // meaning id already exists in the array

      setSelectedQuestionSets((prev) =>
        prev.filter(
          (item) => item.question_set_id !== findQuestionSet.question_set_id
        )
      );
    }
  };

  // useEffect(() => {
  //   console.log("Selected are: ", selectedQuestionSets);
  // }, [selectedQuestionSets]);

  const handleSubmit = () => {
    setPackageData((prev) => ({
      ...prev,
      selected_question_sets: [...selectedQuestionSets],
    }));
    handleClose();
  };

  return (
    <>
      <Modal size="lg" show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "95%",
              alignItems: "center",
            }}
          >
            <Modal.Title>Add Question Sets</Modal.Title>
            {isLoading || isFetching ? (
              <span>Loading...</span>
            ) : (
              <span>Total Question Sets: {totalData}</span>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {isLoading || isFetching ? (
              <span>Loading...</span>
            ) : (
              <Stack
                className="mb-3 d-flex gap-3 "
                style={{
                  maxHeight: "50vh",
                  overflowY: "scroll",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {allQuestionSetCollection.map((question_set, index) => (
                  <InputCheckBox
                    key={index}
                    {...question_set}
                    handleClickOnQuestionSet={handleClickOnQuestionSet}
                    selectedQuestionSets={selectedQuestionSets}
                  />
                ))}
              </Stack>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              background: "#EE4B2B",
              border: "none",
              display: "flex",
              gap: ".5rem",
              alignItems: "center",
            }}
            variant="secondary"
            onClick={handleClose}
          >
            <AiOutlineCloseCircle />
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            style={{ display: "flex", gap: ".5rem", alignItems: "center" }}
          >
            <IoMdCreate />
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddQuestionSet;

const InputCheckBox = ({
  question_set_id,
  name,
  price,
  expiry_duration,
  handleClickOnQuestionSet,
  selectedQuestionSets,
}) => {
  return (
    <Form.Group
      className="mb-1"
      style={{
        width: "90%",
        paddingLeft: "1rem",
      }}
    >
      <Form.Check
        checked={
          selectedQuestionSets.find(
            (item) => item.question_set_id === question_set_id
          )
            ? true
            : false
        }
        label={`${name}, Price: ${price}`}
        onClick={() => handleClickOnQuestionSet(question_set_id, name)}
      />
    </Form.Group>
  );
};
// {
//   "question_set_id": 8,
//   "name": "Set Question",
//   "price": 90,
//   "expiry_duration": "1h30m"
// },

export const useFetchAllQuestionSetsWithoutPagination = () => {
  const queryKey = ["get-all-question-sets-without-pagination"];
  const queryFunction = async () => {
    return await axiosInstance.get("/api/getallquestionset");
  };

  const [totalData, setTotalData] = useState(0);
  const [allQuestionSetCollection, setAllQuestionSetCollection] = useState([]);

  const performSideEffects = (response) => {
    let data = response?.data?.data;
    setAllQuestionSetCollection(JSON.parse(JSON.stringify(data || [])));
    setTotalData((prev) => data?.length);
  };
  const { isLoading, isFetching } = useQuery(queryKey, queryFunction, {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      if (response.status === 200) {
        performSideEffects(response);
      }
    },
    onError: (error) => {
      if (error?.response?.status === 401) {
        handle401Exception(queryFunction, performSideEffects);
      }
    },
  });
  return {
    allQuestionSetCollection,
    totalData,
    isLoading,
    isFetching,
  };
};
