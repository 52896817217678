import React from "react";
import OptionCard from "./OptionCard";
const OptionsRenderer = ({
  optionType,
  optionsList,
  setOptionsList,
  ...rest
}) => {
  return (
    <div className="pb-2">
      <p className=""> Your options are:</p>
      {optionsList.map((option, index) => (
        <OptionCard
          optionType={optionType}
          key={index + 6}
          option={option}
          index={index}
          optionsList={optionsList}
          {...rest}
        />
      ))}
      <p
        className="mt-3"
        style={{
          textTransform: "capitalize",
        }}
      >
        Correct Answer:{" "}
        {typeof rest.correctAnswer === "number"
          ? `Option${rest.correctAnswer}`
          : rest.correctAnswer}
      </p>
    </div>
  );
};
export default OptionsRenderer;
