import { Modal, Form, Button } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { queryClient } from "../../../index";
import { useContext } from "react";
import { GlobalContext, useGlobalContext } from "../../../utils/GlobalContext";
import axiosInstance from "../../../utils/axiosInstance";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import handle401Exception from "../../../utils/handle401Exception";
import { IoMdCreate } from "react-icons/io";
import { AiOutlineClear, AiOutlineCloseCircle } from "react-icons/ai";

const EditInhouseStudent = ({ name, email, address, contact, student_id, setEditModal }) => {
  const { setIsAppLoading } = useGlobalContext()
  const [formData, setFormData] = useState({
    name,
    email,
    address,
    contact,
  });


  // on input field change
  const handleOnChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // query function to add question set
  const queryFunction = async (user_data) => {
    setIsAppLoading(true)
    return await axiosInstance
      .put("/api/registerinhousestudent", user_data)
      .then((response) => {
        if (response.status === 201) {
          performSideEffects(response);
        }
      });
  };

  const performSideEffects = () => {
    queryClient.invalidateQueries({
      queryKey: ["get-inhouse-students"],
    });
    setFormData({
      name: "",
      email: "",
      address: "",
      contact: 0,
    });
    setIsAppLoading(false)
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: queryFunction,
    onSuccess: (response) => {},
    onError: (error) => {
      if (error?.response?.status === 401) {
        handle401Exception(
          () =>
            queryFunction({
              student_id,
              ...formData,
            }),
          performSideEffects
        );
        setIsAppLoading(false)
      }else {
        setIsAppLoading(false)
      }
    },
  });

  const handleSubmit = () => {
    if (formData.name === "") {
      toast.error("Please enter name of user.");
      return;
    }
    if (formData.email === "") {
      toast.error("Please enter email of user.");
      return;
    }
    if (formData.address === "") {
      toast.error("Please enter address of user.");
      return;
    }
    if (formData.contact === null || !formData.contact) {
      toast.error("Please enter contact of user.");
      return;
    }
    if(formData.contact.split('').length < 10){
      toast.error("Contact number should be at least 10 numbers long.");
      return;
    }

    mutate({
      student_id,
      ...formData,
    });
  };

  const handleClose = () => {
    setEditModal({
      student_id: null
    })
  };

  return (
    <>
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label className="form-label">
                Name{" "}
                <span
                  style={{
                    color: "red",
                    paddingLeft: ".25rem",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>{" "}
              </label>
              <input
                value={formData.name}
                onChange={handleOnChange}
                name="name"
                className="form-control shadow-sm"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                Email{" "}
                <span
                  style={{
                    color: "red",
                    paddingLeft: ".25rem",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>{" "}
              </label>
              <input
                value={formData.email}
                onChange={handleOnChange}
                name="email"
                className="form-control shadow-sm"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                Address{" "}
                <span
                  style={{
                    color: "red",
                    paddingLeft: ".25rem",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>{" "}
              </label>
              <input
                value={formData.address}
                onChange={handleOnChange}
                name="address"
                className="form-control shadow-sm"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                Contact{" "}
                <span
                  style={{
                    color: "red",
                    paddingLeft: ".25rem",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>{" "}
              </label>
              <input
                type="number"
                value={formData.contact}
                onChange={handleOnChange}
                name="contact"
                className="form-control shadow-sm"
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{
              display: "flex",
              gap: ".5rem",
              alignItems: "center",
              background: "#EE4B2B",
              border: "none",
            }}
          >
            <AiOutlineCloseCircle />

            <span className="ml-3">Close</span>
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            <IoMdCreate />
            <span> Save Changes</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditInhouseStudent;
