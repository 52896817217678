import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-hot-toast";
import ImageCropperModal from "../../ImageCropper/ImageCropperModal";

// setOpenCropImageModal={setOpenCropImageModal}
// openCropImageModalData={openCropImageModal}
const AddOptions = ({
  optionType,

  optionValue,
  setOptionValue,
  optionsList,
  setOptionsList,
}) => {
  // open to crop image
  const [openCropImageModal, setOpenCropImageModal] = useState(null);

  const handleUploadFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    if (e.target.files[0].size > "250000" && optionType === "image") {
      e.target.value = "";
      toast.error("Image files cannot be more than 250KB");
      return;
    }
    if (e.target.files[0].size > "5242880" && optionType === "audio") {
      e.target.value = "";
      toast.error("Audio files cannot be more than 5MB");
      return;
    }
    if (optionType === "image") {
      setOpenCropImageModal(e.target.files[0]);
    } else {
      setOptionValue(e.target.files[0]);
    }
    // e.target.value = "";
  };
  const fileRef = useRef();

  const inputTextRef = useRef();

  const handleAddButton = () => {
    if (
      optionValue !== "" &&
      optionValue !== null &&
      optionsList.length !== 4
    ) {
      // option type -- text
      if (optionType === "text") {
        const findIfOptionAlreadyExists = optionsList.find(
          (item) => item === optionValue
        );
        if (findIfOptionAlreadyExists) {
          toast.error("Option already exists.");
          inputTextRef.current.focus();

          return;
        }
      }
      setOptionsList((prev) => [...prev, optionValue]);
      setOptionValue("");
      if (optionType === "image" || optionType === "audio") {
        fileRef.current.value = "";
      }
    }
    if (optionType === "text") {
      inputTextRef.current.focus();
    }
  };

  return (
    <>
      {openCropImageModal && (
        <ImageCropperModal
          setOpenCropImageModal={setOpenCropImageModal}
          openCropImageModalData={openCropImageModal}
          setSelectedImage={setOptionValue}
        />
      )}
      <label className="form-label">
        Add Options:{" "}
        <span
          style={{
            color: "red",
            fontSize: "1rem",
          }}
        >
          {" "}
          *
        </span>{" "}
      </label>
      {optionType === "image" && (
        <span
          style={{
            color: "red",
            fontSize: ".9rem",
            marginLeft: "1rem",
          }}
        >
          *Image file cannot exceed 256KB*
        </span>
      )}
      {optionType === "audio" && (
        <span
          style={{
            color: "red",
            fontSize: ".9rem",
            marginLeft: "1rem",
          }}
        >
          *Audio file cannot exceed 5MB*
        </span>
      )}
      <div className="d-flex gap-2">
        {optionType === "text" ? (
          <input
            ref={inputTextRef}
            value={optionValue}
            onChange={(e) => {
              setOptionValue(e.target.value);
            }}
            className="form-control"
          />
        ) : (
          <input
            type="file"
            ref={fileRef}
            onChange={handleUploadFile}
            className="form-control"
            maxLength={optionType === "image" ? "250000" : "1048576"}
            accept={
              optionType === "image"
                ? "image/png, image/gif, image/jpeg"
                : "audio/*"
            }
          />
        )}

        <Button
          style={{
            width: "5rem",
          }}
          onClick={handleAddButton}
        >
          + Add
        </Button>
      </div>
    </>
  );
};

export default AddOptions;
