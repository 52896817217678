import React, { useEffect, useState } from "react";
import Image from "../../../assets/lotus_logo.png";
import { toast } from "react-hot-toast";
import { useMutation } from "react-query";
import axiosInstance from "../../../utils/axiosInstance";
import { IoEyeSharp } from "react-icons/io5";
import { BsFillEyeSlashFill } from "react-icons/bs";
import handle401Exception from "../../../utils/handle401Exception";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { redirect, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [userDetails, setUserDetails] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  // query function
  const queryFunction = async (body) => {
    return await axiosInstance.post("/api/admin/reset-password", body);
  };

  // desired side effects : (same as 201 or 200)
  const performSideEffects = () => {
    setUserDetails({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const { mutate, isLoading, error } = useMutation(queryFunction, {
    onSuccess: (data) => {
      if (data?.status === 200 || data?.status === 201) {
        performSideEffects();
      }
    },
    onError: (error) => {
      if (error?.response?.status === 401) {
        handle401Exception(() => {
          let args = {
            oldPassword: userDetails.oldPassword,
            newPassword: userDetails.newPassword,
          };
          return queryFunction(args);
        }, performSideEffects);
      }
    },
  });

  const handleSubmit = () => {
    const { oldPassword, newPassword, confirmPassword } = userDetails;
    if (oldPassword === "" && newPassword === "" && confirmPassword === "") {
      toast.error(
        "Please enter old password, new password, and confirm password",
        {
          duration: 2000,
          position: "top-center",
        }
      );
      return;
    }

    if (oldPassword === "") {
      toast.error("Please enter old password", {
        duration: 2000,
        position: "top-center",
      });
      return;
    }

    if (newPassword === "") {
      toast.error("Please enter new password", {
        duration: 2000,
        position: "top-center",
      });
      return;
    }

    if (confirmPassword === "") {
      toast.error("Please enter confirm password", {
        duration: 2000,
        position: "top-center",
      });
      return;
    }

    // old pass and new pass
    if (oldPassword === "" && newPassword === "") {
      toast.error("Please enter old password and new password", {
        duration: 2000,
        position: "top-center",
      });
      return;
    }

    // old pass and new pass
    if (oldPassword === "" && confirmPassword === "") {
      toast.error("Please enter old password and confirm password", {
        duration: 2000,
        position: "top-center",
      });
      return;
    }

    // both pass are not same (2 and 3)
    if (newPassword !== confirmPassword) {
      toast.error("New Password and Confirm Password do not match", {
        duration: 2000,
        position: "top-center",
      });
      return;
    }

    if (oldPassword === newPassword) {
      toast.error("Old Password and New Password can not be same", {
        duration: 2000,
        position: "top-center",
      });
      return;
    }

    if (newPassword.length < 8) {
      toast.error("Password must be at least 8 characters long.");
      return;
    }
    // password must contain at leaset one upper
    // const uppercasePasswordRegex = /(?=.*[A-Z])/;

    // const hasUppercase = uppercasePasswordRegex.test(newPassword);

    // if (!hasUppercase) {
    //   toast.error("Password must have at least one uppercase");
    //   return;
    // }

    // password must contain at least one lowercase
    // const lowerCaseRegex = /(?=.*[a-z])/;

    // const hasLowerCase = lowerCaseRegex.test(newPassword);
    // if (!hasLowerCase) {
    //   toast.error("Password must have at least one lowercase");
    //   return;
    // }

    // password must contain at least one special character
    // const specialCharacterRegex = /(?=.*[@$!%*?&])/;
    // const hasSpecialCharacter = specialCharacterRegex.test(newPassword);
    // if (!hasSpecialCharacter) {
    //   toast.error("Password must have at least one special character");
    //   return;
    // }

    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    const isValidPassword = passwordRegex.test(newPassword);
    if (!isValidPassword) {
      toast.error(
        "Password must have at least one uppercase, one lowercase and one special character"
      );
      return;
    }

    mutate({ oldPassword, newPassword });
  };

  const handleInputChange = (e) => {
    setUserDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeShowPasswordButton = (fieldName) => {
    setShowPassword((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <form
        // className=" mt-5"
        style={{
          // border: "1px solid #e5e7eb",
          paddingBlock: "2rem",
          paddingInline: "2rem",
          borderRadius: "10px",
          // background: "white",
          width: "500px",
        }}
      >
        <h1
          className="py-3"
          style={{
            fontSize: "2rem",
          }}
        >
          Reset Password
        </h1>
        <div
          id="form2Example1"
          className="form-control mb-3"
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            paddingBlock: ".5rem",
            background: "transparent",
          }}
        >
          {showPassword.oldPassword ? (
            <IoEyeSharp
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
                paddingTop: "3px",
              }}
              onClick={() => handleChangeShowPasswordButton("oldPassword")}
            />
          ) : (
            <BsFillEyeSlashFill
              style={{
                paddingTop: "3px",
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
              onClick={() => handleChangeShowPasswordButton("oldPassword")}
            />
          )}
          <input
            onChange={handleInputChange}
            value={userDetails.oldPassword}
            name="oldPassword"
            type={showPassword.oldPassword ? "text" : "password"}
            placeholder="Old Password"
            style={{
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
            }}
          />
        </div>
        <div
          id="form2Example1"
          className="form-control mb-3"
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            paddingBlock: ".5rem",
            background: "transparent",
          }}
        >
          {showPassword.newPassword ? (
            <IoEyeSharp
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
                paddingTop: "3px",
              }}
              onClick={() => handleChangeShowPasswordButton("newPassword")}
            />
          ) : (
            <BsFillEyeSlashFill
              style={{
                paddingTop: "3px",
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
              onClick={() => handleChangeShowPasswordButton("newPassword")}
            />
          )}
          <input
            onChange={handleInputChange}
            value={userDetails.newPassword}
            name="newPassword"
            type={showPassword.newPassword ? "text" : "password"}
            placeholder="New Password"
            style={{
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
            }}
          />
        </div>
        <div
          id="form2Example1"
          className="form-control mb-3"
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            paddingBlock: ".5rem",
            background: "transparent",
          }}
        >
          {showPassword.confirmPassword ? (
            <IoEyeSharp
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
                paddingTop: "3px",
              }}
              onClick={() => handleChangeShowPasswordButton("confirmPassword")}
            />
          ) : (
            <BsFillEyeSlashFill
              style={{
                paddingTop: "3px",
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
              onClick={() => handleChangeShowPasswordButton("confirmPassword")}
            />
          )}
          <input
            onChange={handleInputChange}
            value={userDetails.confirmPassword}
            name="confirmPassword"
            type={showPassword.confirmPassword ? "text" : "password"}
            placeholder="Confirm Password"
            style={{
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
            }}
          />
        </div>
        {/* <div className="form-outline mb-4">
          <p className="d-flex align-items-center justify-content-between">
            <label htmlFor="form2Example2">New Password</label>
            {showPassword.newPassword ? (
              <IoEyeSharp
                style={{
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                  paddingTop: "3px",
                }}
                onClick={() => handleChangeShowPasswordButton("newPassword")}
              />
            ) : (
              <BsFillEyeSlashFill
                style={{
                  paddingTop: "3px",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                onClick={() => handleChangeShowPasswordButton("newPassword")}
              />
            )}
          </p>
          <input
            type={showPassword.newPassword ? "text" : "password"}
            value={userDetails.newPassword}
            id="form2Example2"
            className="form-control"
            name="newPassword"
            onChange={handleInputChange}
          />
        </div>
        <div className="form-outline mb-4">
          <p className="d-flex align-items-center justify-content-between">
            <label htmlFor="form2Example2">Confirm Password</label>
            {showPassword.confirmPassword ? (
              <IoEyeSharp
                style={{
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                  paddingTop: "3px",
                }}
                onClick={() =>
                  handleChangeShowPasswordButton("confirmPassword")
                }
              />
            ) : (
              <BsFillEyeSlashFill
                style={{
                  paddingTop: "3px",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleChangeShowPasswordButton("confirmPassword")
                }
              />
            )}
          </p>
          <input
            type={showPassword.confirmPassword ? "text" : "password"}
            value={userDetails.confirmPassword}
            id="form2Example2"
            className="form-control"
            name="confirmPassword"
            onChange={handleInputChange}
          />
        </div> */}

        <button
          type="button"
          onClick={handleSubmit}
          className="btn btn-primary btn-block mb-4 w-100"
        >
          Reset Password{" "}
        </button>
      </form>
    </>
  );
};

export default ResetPassword;
