import { Stack } from 'react-bootstrap'
import React from 'react'
import { AiFillHome } from 'react-icons/ai'
import { RiLockPasswordFill } from 'react-icons/ri'
import { CustomSidebarExamElement } from '../Custom/CustomToggle'
import Logout from './Logout'
import SidebarElements from './SidebarElements'

const SidebarDetails = [
  {
    name: 'Home',
    link: '/',
    icon: (
      <AiFillHome
        style={{
          width: '20px',
          height: '20px',
        }}
      />
    ),
  },
  // {
  //   name: "Question Sets",
  //   link: "/questionset",
  //   icon: (
  //     <AiFillQuestionCircle
  //       style={{
  //         width: "20px",
  //         height: "20px",
  //       }}
  //     />
  //   ),
  // },
  // {
  //   name: "Package Sets",
  //   link: "/packageset",
  //   icon: (
  //     <FiPackage
  //       style={{
  //         width: "20px",
  //         height: "20px",
  //       }}
  //     />
  //   ),
  // },
  // {
  //   name: "Book",
  //   link: "/books",
  //   icon: (
  //     <IoBookSharp
  //       style={{
  //         width: "20px",
  //         height: "20px",
  //       }}
  //     />
  //   ),
  // },
  // {
  //   name: "Students Management",
  //   link: "/register-inhouse",
  //   icon: (
  //     <BsPeopleFill
  //       style={{
  //         width: "20px",
  //         height: "20px",
  //       }}
  //     />
  //   ),
  // },
]

const LotusUbtApp = [
  { option_name: 'Question Sets', link: '/questionset' },
  { option_name: 'Package Sets', link: '/packageset' },
  { option_name: 'Books', link: '/books' },
  // { option_name: 'Audio Books', link: '/uploadChapter' },
  { option_name: 'Access Control', link: '/access-control' },
]

const InhouseStudentsOptionArray = [
  { option_name: 'Create Exam Set', link: '/inhouse-examset' },
  { option_name: 'Create Internal Exam', link: '/inhouse-live-exam' },
  { option_name: 'Review Exam Result', link: '/review-exam-inhouse-student' },
  { option_name: 'Access Control', link: '/access-control-exam-set' },
]
const StudentsOptionArray = [
  { option_name: 'Register Inhouse Students', link: '/register-inhouse' },
  // { option_name: "Create Live Exam", link: "/inhouse-live-exam" },
]

const Sidebar = () => {
  return (
    <div
      className='container'
      style={{
        minWidth: '360px',
        maxWidth: '360px',
        height: '100vh',
        backgroundColor: 'rgb(30 41 59)',
        color: 'white',
      }}
    >
      {' '}
      <h1
        className='py-2 text-center mt-4'
        style={{
          fontSize: '1.6rem',
          cursor: 'pointer',
        }}
      >
        Admin Dashboard
      </h1>
      <Stack
        style={{
          height: '85%',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {SidebarDetails.map(({ name, link, icon }, index) => (
            <SidebarElements key={index} name={name} link={link} icon={icon} />
          ))}
          {/* use to create dropdown of internal exam managment  */}
          <CustomSidebarExamElement
            options3Array={InhouseStudentsOptionArray}
            title3={'Internal Exam Management'}
            options2Array={StudentsOptionArray}
            title2={'Student Management'}
            options1Array={LotusUbtApp}
            title1={'Lotus UBT Mobile Application'}
          // options2Array={InhouseStudentsOptionArray}
          // title2={"External Exam Management"}
          />
        </div>
        <div>
          <SidebarElements
            name={'Reset Password'}
            link={'/reset-password'}
            icon={
              <RiLockPasswordFill
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
            }
          />
          <Logout />
        </div>
      </Stack>
    </div>
  )
}

export default Sidebar
