import React, { useContext, useState } from "react";
import { createContext } from "react";

export const GlobalContext = createContext();

const ContextComponent = ({ children }) => {
  const [openImagePopup, setOpenImagePopup] = useState("");
  const [isAppLoading, setIsAppLoading] = useState(false);
  const [questionArray, setQuestionArray] = useState([]);
  const [examDetails,setExamDetails]=useState([])
  return (
    <GlobalContext.Provider
      value={{
        isAppLoading,
        setIsAppLoading,
        questionArray,
        setQuestionArray,
        openImagePopup,
        setOpenImagePopup,
        examDetails,
        setExamDetails
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);

export default ContextComponent;
