import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { GlobalContext } from "../../../utils/GlobalContext";
import { queryClient } from "../../..";
import axiosInstance from "../../../utils/axiosInstance";
import { useMutation } from "react-query";
import handle401Exception from "../../../utils/handle401Exception";
import { toast } from "react-hot-toast";

const RestoreModal = ({
  package_name,
  package_id,
  price,
  status,
  restoreModal,
  setRestoreModal,
}) => {
  const { setIsAppLoading } = useContext(GlobalContext);

  const handleClose = () => {
    setRestoreModal((prev) => ({
      package_name: "",
      price: null,
      package_id: null,
      status: null,
      expiry_duration: "",
    }));
  };
  const performSideEffects = () => {
    setIsAppLoading(false);
    toast.success(`${package_name} restored successfully.`);
    queryClient.invalidateQueries({ queryKey: ["get-package-sets"] });
    handleClose();
  };

  const queryFunction = async () => {
    return await axiosInstance
      .put(`/api/restorequestionsetpackage/${package_id}`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          performSideEffects();
        }
      });
  };
  const { mutate } = useMutation(queryFunction, {
    onError: (error) => {
      setIsAppLoading(false);
      if (error?.response.status === 401) {
        handle401Exception(queryFunction, performSideEffects);
      }
      handleClose();
    },
  });
  const handleDelete = () => {
    setIsAppLoading(true);
    mutate();
  };
  return (
    <>
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Restore this package Set</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure, you want to restore this package Set?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ background: "#EE4B2B", border: "none" }}
            s
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Restore
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RestoreModal;
