import React, { useEffect, useRef } from "react";
import handle401Exception from "../../utils/handle401Exception";
import { useQuery } from "react-query";
import { useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { Table } from "react-bootstrap";

const ChapterFileList = () => {
    const [chapterId, setChapterId] = useState('');
    const [chaptersArray, setChaptersArray] = useState([]);
    const { isLoading, isFetching } = useQuery(
        'get-book-list', // You can use any unique key here
        async () => {
            // Get the chapter_id from the URL
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            const chapterId = params.chapter_id;

            // Update the state with the chapter_id
            setChapterId(chapterId);

            // Fetch data based on the chapter_id
            const response = await axiosInstance.get(`/api/getfilelist/${chapterId}`);
            return response.data.data || [];
        },
        {
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                // Update the chaptersArray with the fetched data
                setChaptersArray(data);
            },
            onError: (error) => {
                if (error?.response?.status === 401) {
                    // Handle 401 error if needed
                }
            },
        }
    );
    const [showAudio, setShowAudio] = useState({});

    const toggleAudio = (index) => {

        setShowAudio((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    return (
        <>
            <>
                {" "}
                <div className="my-4 d-flex justify-content-between align-items-center">
                    <h1>Chapter File List</h1>
                </div>
                {isLoading || isFetching ? (
                    <p>Loading...</p>
                ) : (
                    <div className="row gap-4">
                        {chaptersArray !== undefined &&
                            chaptersArray.length !== 0 ? (
                            <Table
                                responsive
                                hover
                            >
                                <thead>
                                    <tr>
                                        <th>S.N</th>
                                        <th>File Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {chaptersArray.map((student, index) => (
                                        <React.Fragment key={index}>
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{student.file_name}</td>
                                                {(student.file_type === "PDF") ? <td>
                                                    <a
                                                        target="_blank"
                                                        href={`${process.env.REACT_APP_API_URL
                                                            }/api/getbookfile/${encodeURIComponent(student?.file_name)}`}
                                                    ><span style={{ color: 'blue' }}>View</span>
                                                    </a>
                                                </td> : <td onClick={() => { }}>
                                                    <span
                                                        style={{ cursor: 'pointer', color: 'blue' }}
                                                        onClick={() => toggleAudio(index)}
                                                    >
                                                        View
                                                    </span>
                                                </td>}
                                            </tr>
                                            {showAudio[index] && (
                                                <tr>
                                                    <td colSpan="3" >
                                                        <AudioComponent option={student.file_name} />
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <p>No Student found</p>
                        )}

                    </div>
                )}
            </>
        </>
    );
};

export default ChapterFileList;

export const AudioComponent = ({ option }) => {
    const [audioData, setAudioData] = useState(null);
    const performSideEffects = (response) => {
        setIsAudioLoading(false);
        setAudioData(response.data);
    };
    const [isAudioLoading, setIsAudioLoading] = useState(false);

    const fetcherFunction = async (option) => {
        setIsAudioLoading(true);
        return await axiosInstance.get(
            `${process.env.REACT_APP_API_URL}/api/getbookfile/${option}`,
            {
                responseType: "blob",
            }
        );
    };

    const fetchAudios = async (option) => {
        setIsAudioLoading(true);
        return axiosInstance
            .get(`${process.env.REACT_APP_API_URL}/api/getbookfile/${option}`, {
                responseType: "blob",
            })
            .then((response) => {
                if (response.status === 200) {
                    performSideEffects(response);
                }
            })
            .catch((error) => {
                // setError(error);
                if (error.response?.status === 401) {
                    handle401Exception(() => fetcherFunction(option), performSideEffects);
                }
            });
    };

    useEffect(() => {
        fetchAudios(option);
    }, []);

    return (
        <>

            {audioData && !isAudioLoading ? (
                <audio controls style={{ height: '28px' }}>
                    <source src={URL.createObjectURL(audioData)} type="audio/mpeg" />
                </audio>
            ) : (
                <p>Loading...</p>
            )}
        </>
    );
};

