import { useRef, useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { AiFillDelete } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import Pagination from "../../components/Pagination/Pagination";
import axiosInstance from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import handle401Exception from "../../utils/handle401Exception";
import DeleteBookModal from "../Modal/Books/DeleteModal";

const AudioBookList = () => {

  const [paginationSettings, setPaginationSettings] = useState({
    currentPage: 1,
    limit: 10,
    status: 1,
    bookName: "",
  });

  const [totalData, setTotalData] = useState(null);

  const [bookSetArray, setBookSetArray] = useState([]);

  const [openDeleteModal, setOpenDeleteModal] = useState({
    book_id: null,
    book_name: null,
    file_name: null,
  });



  // for scrolling whenever somehting happens
  const question_set_ref = useRef();
  const executeScroll = () => {
    if (question_set_ref && question_set_ref.current) {
      question_set_ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  let queryKey = [
    "get-audio-book-list",
    paginationSettings.currentPage,
    paginationSettings.limit,
    paginationSettings.bookName,
    paginationSettings.status,
  ];


  const queryFunction = async () =>
    await axiosInstance.get(
      `/api/getaudiobooklist?page=${paginationSettings.currentPage}&limit=${paginationSettings.limit}&status=${paginationSettings.status}&bookName=${paginationSettings.bookName}`
    );


  const performSideEffects = (response) => {

    setBookSetArray(JSON.parse(JSON.stringify(response?.data?.data || {})));
    setTotalData((prev) => response?.data?.total);
    executeScroll();

  };


  const { isLoading, isFetching } = useQuery(queryKey, queryFunction, {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      if (response.status === 200) {
        performSideEffects(response);
      }
    },
    onError: (error) => {
      if (error?.response?.status === 401) {
        handle401Exception(queryFunction, performSideEffects);
      }
    },
  });


  // pagination states

  const totalPages = totalData
    ? Math.ceil(totalData / paginationSettings.limit)
    : null;
  const handleIncreasePage = () => {
    if (paginationSettings.currentPage === totalPages) {
      return;
    }
    setPaginationSettings((prev) => ({
      ...prev,
      currentPage: prev["currentPage"] + 1,
    }));
  };
  const handleDecreasePage = () => {
    if (paginationSettings.currentPage === 1) {
      return;
    }
    setPaginationSettings((prev) => ({
      ...prev,
      currentPage: prev["currentPage"] - 1,
    }));
  };

  useEffect(() => {
    queryFunction()
  }, [])


  const tableHeading = ["S.N", "Name", "Actions"];

  const [editModal, setEditModal] = useState({
    book_name: "",
    status: null,
    book_id: null,
  });


  const [deleteModal, setDeleteModal] = useState({
    book_name: "",
    status: null,
    book_id: null,
  });


  const handleOpenEditModal = (book_set_data) => {
    setEditModal((prev) => ({
      ...book_set_data,
    }));
  };

  const handleOpenDeleteModal = (book_set_data) => {
    setOpenDeleteModal((prev) => ({
      ...book_set_data,
    }));
  };


  return (
    <>
      {openDeleteModal.book_id && (
        <DeleteBookModal
          {...openDeleteModal}
          setDeleteModal={setOpenDeleteModal}
        />
      )}
      <div className="my-4 d-flex justify-content-between align-items-center">
        <h1
          className="ml-4"
          style={{
            fontSize: "2rem",
          }}
        >
          All available Audio Books
        </h1>
        <Search setPaginationSettings={setPaginationSettings} />
      </div>

      <div>
        {bookSetArray.length === 0 ? (
          <p>No audio books set available</p>
        ) : (
          <Table responsive hover>
            <thead>
              <tr>
                {tableHeading.map((heading, index) => (
                  <th key={index}>{heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {bookSetArray.map((book_set, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{book_set.book_name}</td>
                  <td className="d-flex gap-4">
                    <AudioComponent option={book_set.file_name} />
                    <span
                      className="d-flex gap-2 align-items-center"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleOpenDeleteModal({
                          book_id: book_set?.book_id,
                          book_name: book_set?.book_name,
                          file_name: book_set?.file_name,
                        })

                      }
                    >
                      <AiFillDelete color="red" />
                      <span
                        style={{
                          color: "red",
                          cursor: "pointer",
                        }}
                      >
                        Delete
                      </span>{" "}
                    </span>
                  </td>

                </tr>
              ))}
            </tbody>
          </Table >
        )}


      </div>
      <div
        className="container "
        style={{
          textAlign: "center",
          width: "40%",
        }}

      >
        {totalPages !== null && (
          <Pagination
            currentPage={paginationSettings.currentPage}
            handleIncreasePage={handleIncreasePage}
            totalPages={totalPages}
            handleDecreasePage={handleDecreasePage}
            setPaginationSettings={setPaginationSettings}
          />
        )}
      </div>

    </>
  );
};

export default AudioBookList;


export const Search = ({ setPaginationSettings }) => {

  const [searchValues, setSearchValues] = useState({
    bookName: '',
    status: 1
  })

  const handleChange = (e) => {
    setSearchValues({ ...searchValues, [e.target.name]: e.target.value })
  }

  const handleSearch = () => {

    setPaginationSettings((prev) => ({
      ...prev,
      limit: 10,
      status: searchValues.status,
      bookName: searchValues.bookName,
    }));

    setSearchValues({
      bookName: '',
      status: 1
    })

  };
  return (
    <div className="d-flex align-items-center gap-2  ">
      <input
        type="text"
        className="form-control"
        id="exampleFormControlInput1"
        placeholder="Audio book name"
        name="bookName"
        style={{
          minWidth: "200px",
        }}
        value={searchValues.bookName}
        onChange={handleChange}
      />

      <select
        className="form-select"
        name="status"
        value={searchValues.status}
        onChange={handleChange}
      >
        <option selected value={1}>
          Active
        </option>
        <option value={0}>Inactive</option>
      </select>
      <button
        className="btn btn-primary"
        style={{
          minWidth: "7rem",
          display: "flex",
          alignItems: "center",
          gap: ".25rem",
        }}
        onClick={handleSearch}
      >
        <BiSearch />
        Search
      </button>
    </div>
  );
};


export const AudioComponent = ({ option }) => {


  const [audioData, setAudioData] = useState(null);
  const performSideEffects = (response) => {
    setIsAudioLoading(false);
    setAudioData(response.data);
  };
  const [isAudioLoading, setIsAudioLoading] = useState(false);

  const fetcherFunction = async (option) => {
    setIsAudioLoading(true);
    return await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/api/getbookfile/${option}`,
      {
        responseType: "blob",
      }
    );
  };

  const fetchAudios = async (option) => {
    setIsAudioLoading(true);
    return axiosInstance
      .get(`${process.env.REACT_APP_API_URL}/api/getbookfile/${option}`, {
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          performSideEffects(response);
        }
      })
      .catch((error) => {
        // setError(error);
        if (error.response?.status === 401) {
          handle401Exception(() => fetcherFunction(option), performSideEffects);
        }
      });
  };

  useEffect(() => {
    fetchAudios(option);
  }, []);

  return (
    <>

      {audioData && !isAudioLoading ? (
        <audio controls style={{height:'28px'}}>
          <source src={URL.createObjectURL(audioData)} type="audio/mpeg" />
        </audio>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};