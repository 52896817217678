import React, { useRef, useState, useEffect } from 'react'
import { Button, Form, Figure } from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import handle401Exception from '../../utils/handle401Exception'
import axiosInstance from '../../utils/axiosInstance'
import { useGlobalContext } from '../../utils/GlobalContext'
import ChapterList from './ChaptersList'

const UploadChapter = () => {
    const [values, setValues] = useState({
        book_id: '',
        chapter_name: '',
        pdfBook: null,
        audioBook: [],
        status: '1'
    })
    useEffect(() => {
        // Call the function to get the book_id when the component mounts
        getBookIdFromUrl();
    }, []);

    // Function to parse the URL and extract the book_id parameter
    function getBookIdFromUrl() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        // Access the book_id parameter
        const bookId = params.book_id;

        // Now, you can use bookId as needed
        setValues({ ...values, book_id: bookId });
    }

    const uploadFileRef = useRef();


    const { setIsAppLoading } = useGlobalContext()


    const API = '/api/savechapterinfo'


    const queryFunction = async (formData) => {
        return await axiosInstance.post(API, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    }

    const performSideEffects = (response) => {
        setIsAppLoading(false)
        window.location.reload();
        setValues({ chapter_name: '', pdfBook: null, audioBook: [], status: '1' })
    }

    const handleSubmit = () => {
        if (!values.chapter_name) {
            toast.error('Please enter the chapter name.')
            return
        }
        if (!values.pdfBook) {
            toast.error('Please upload an pdf file.')
            return
        }
        // if (!values.audioBook) {
        //     toast.error('Please upload an audio file.')
        //     return
        // }
        setIsAppLoading(true)
        const formData = new FormData()
        formData.append('book_id', values.book_id)
        formData.append('chapter_name', values.chapter_name)
        values.pdfBook && formData.append('pdfBook', values.pdfBook)
        values.audioBook.forEach((audioFile, index) => {
            formData.append(`audioBook`, audioFile);
        });
        formData.append('status', values.status)
        queryFunction(formData)
            .then((response) => {
                performSideEffects(response)
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handle401Exception(() => queryFunction(formData), performSideEffects)
                } else {
                    setIsAppLoading(false)
                }
            })
    }

    const handleAudioNameChange = (e) => {
        setValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.value,
        }))
    }

    const handleAudioFileChange = (e) => {
        setValues((prevValues) => ({
            ...prevValues,
            audioBook: [...prevValues.audioBook, e.target.files[0]],
        }))
    }

    const handleRemove = (e) => {
        setValues({
            chapter_name: '',
            pdfBook: null,
            audioBook: [],
            status: '1'
        })
        const fileInput = document.querySelector('input[name="audioBook"]')
        if (fileInput) {
            fileInput.value = ''
        }
    }
    const handleAudioRemove = (indexToRemove) => {
        setValues((prevValues) => ({
            ...prevValues,
            audioBook: prevValues.audioBook.filter((_, index) => index !== indexToRemove),
        }));
        const fileInput = document.querySelector('input[name="audioBook"]');
        if (fileInput) {
            fileInput.value = '';
        }
    };

    const uploadAudioChangeRef = useRef();
    useEffect(() => {
        if (uploadAudioChangeRef.current) {
            uploadAudioChangeRef.current?.load();
        }
    }, [values.audioBook]);

    const handleUploadBook = (e) => {
        if (!e.target.files || e.target.files?.length === 0) return;
        setValues(() => ({ ...values, pdfBook: e.target.files[0] }));
    };
    const handleStatusChange = (e) => { setValues((prev) => ({ ...prev, status: e.target.value })) };



    return (
        <div
            style={{
                width: '100%',
                marginTop: '1rem',
            }}
        >
            <Form style={{
                width: '50%',
                marginTop: '1rem',
            }}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <label className="form-label">Chapter name</label>
                    <input
                        onChange={handleAudioNameChange}
                        type="text"
                        name="chapter_name"
                        className="form-control"
                        value={values.chapter_name}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <label className="form-label">Upload Book</label>
                    <input
                        onChange={handleUploadBook}
                        type="file"
                        name="pdfBook"
                        className="form-control"
                        accept="application/pdf"
                        ref={uploadFileRef}

                    />
                </Form.Group>
                {values.pdfBook && (
                    <embed
                        src={URL.createObjectURL(values.pdfBook)}
                        type="application/pdf"
                        frameBorder="0"
                        scrolling="auto"
                        style={{
                            minHeight: "70vh",
                        }}
                        width="100%"
                    ></embed>
                )}
                <div
                    className="d-flex"
                    style={{
                        gap: "1rem",
                    }}
                >
                    {values.pdfBook && (
                        <Button
                            type="reset"
                            onClick={() => {
                                setValues({
                                    ...values, pdfBook: null,
                                });
                                if (uploadFileRef.current) {
                                    uploadFileRef.current.value = null;
                                }
                            }}
                            className="my-3 px-3"
                            style={{
                                background: "tomato",
                                border: "none",
                            }}
                        >
                            Remove
                        </Button>
                    )}
                </div>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <label className="form-label">Upload Audio</label>
                    <input
                        onChange={handleAudioFileChange}
                        type="file"
                        name="audioBook"
                        className="form-control"
                        accept="audio/*"
                    />
                    {values.audioBook.length !== 0 && (
                        values.audioBook.map((item, index) => {
                            return (
                                <Figure key={index} style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>
                                    <div className='d-flex'>
                                        <audio controls>
                                            <source src={URL.createObjectURL(item)} type="audio/mpeg" />
                                        </audio>
                                        <Button className='my px-1 btn-danger' onClick={() => handleAudioRemove(index)} style={{ marginBottom: '0.7rem', marginTop: '0.3rem' }} >Remove</Button>
                                    </div>
                                </Figure>
                            );
                        })
                    )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1">
                    <label className="form-label">Status</label>
                    <select
                        onChange={handleStatusChange}
                        name="status"
                        className="form-select"
                        value={values.status}
                    >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                    </select>
                </Form.Group>

                <div
                    className="d-flex"
                    style={{
                        gap: '1rem',
                    }}
                >
                    <Button type="button" onClick={handleSubmit} className="my-3 px-3">
                        Save
                    </Button>
                    <Button
                        type="button"
                        className="my-3 px-3"
                        style={{
                            background: 'tomato',
                            border: 'none',
                        }}
                        onClick={(e) => handleRemove(e)}
                    >
                        Cancel
                    </Button>
                </div>
            </Form>
            <ChapterList />

        </div >
    )
}

export default UploadChapter

