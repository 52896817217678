import { useEffect, useRef, useState } from "react";
import { AudioComponent } from "../../../QuestionOnly/QuestionOnlyCard/QuestionOnlyCard";
import { Figure, Form } from "react-bootstrap";
import { toast } from "react-hot-toast";

const RenderQuestionAudio = ({ question_audio, audioFile, setAudioFile }) => {
  const audioChangingRef = useRef()
  useEffect(() => {
    if(audioChangingRef.current){
      audioChangingRef.current?.load()
    }
  }, [audioFile])
  const [openEditAudio, setOpenEditAudio] = useState(false);
  const handleUploadAudio = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    if (e.target.files[0].size > "5242880") {
      toast.error("Audio files cannot be more than 5MB");
      e.target.value = "";
      return;
    }
    setAudioFile(e.target.files[0]);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {question_audio && !openEditAudio ? (
        <>
          <p>Question Audio</p>
          <AudioComponent option={question_audio} />
          <button
            type="button"
            onClick={() => {
              setOpenEditAudio((prev) => !prev);
              setAudioFile("none");
            }}
            style={{ background: "#EE4B2B", border: "none" }}
            className="btn btn-secondary d-block mt-3 mb-4 "
          >
            Remove Audio
          </button>
        </>
      ) : (
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Upload Question Audio </Form.Label>
          <Form.Control
            type="file"
            accept="audio/*"
            onChange={handleUploadAudio}
          />
          {audioFile !== null && audioFile !== "none" && (
            <Figure className="my-4">
              <audio ref={audioChangingRef} controls>
                <source
                  src={URL.createObjectURL(audioFile)}
                  type="audio/mpeg"
                />
              </audio>
            </Figure>
          )}
          {question_audio && (
            <button
              style={{ background: "#EE4B2B", border: "none" }}
              type="button"
              onClick={() => setOpenEditAudio((prev) => !prev)}
              className="btn btn-secondary d-block mt-3 mb-4 "
            >
              Undo Remove audio{" "}
            </button>
          )}
        </Form.Group>
      )}
    </div>
  );
};

export default RenderQuestionAudio;
