import { toast } from "react-hot-toast";
import axiosInstance from "./axiosInstance";

// this will mainly be used to get new access_token using exisitng refresh_token
// queryFunction: an axios fn that will be executed after fetching tokens
// performSideEffects: a fn that does some things after queryFn is successful

const handle401Exception = async (queryFunction, performSideEffects) => {
  // get exisitng token first
  let refresh_token;
  if (localStorage.getItem("refresh_token")) {
    refresh_token = JSON.parse(localStorage.getItem("refresh_token"));
  }
  if (!refresh_token) {
    localStorage.clear();
    toast.error("No refresh token");
    return;
  }

  // we ask for new token from here
  axiosInstance
    .post("/api/admin/refresh", { refresh_token })
    .then(({ data }) => {
      localStorage.setItem(
        "access_token",
        JSON.stringify(data["access_token"])
      );
      localStorage.setItem(
        "refresh_token",
        JSON.stringify(data["refresh_token"])
      );
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data["access_token"]}`;
    })
    .then(async () => {
      // queryFn is the api that faced 401 exception before due to expired bearer token
      try {
        const response = await queryFunction();
        if (response?.status === 200 || response?.status === 201) {
          performSideEffects(response);
        }
      } catch (error) {
        // console.log("error is herer", error);
      }
    })
    .catch((error) => {
      if (error.response.status >= 400) {
        // console.log("ErrorMessage", error);
        localStorage.clear();
        if (window) {
          window.location.href = "/login";
        }
      }
    });
};
export default handle401Exception;
