import React from "react";

const Pagination = ({
  handleDecreasePage,
  handleIncreasePage,
  currentPage,
  totalPages,
  setPaginationSettings,
}) => {
  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        <li
          className="page-item"
          style={{
            cursor: "pointer",
          }}
        >
          <a className="page-link" aria-label="Previous">
            <span aria-hidden="true" onClick={handleDecreasePage}>
              Previous
            </span>
          </a>
        </li>

        {Array(totalPages)
          .fill(1)
          .map((_, index) => (
            <li
              key={index}
              className={`page-item ${currentPage === index + 1 && "active"} `}
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setPaginationSettings((prev) => ({
                  ...prev,
                  currentPage: index + 1,
                }));
              }}
            >
              <a
                className={`page-link ${
                  currentPage === index + 1 && "active"
                } `}
                aria-current="page"
              >
                {index + 1}
              </a>
            </li>
          ))}

        <li
          className="page-item "
          style={{
            cursor: "pointer",
          }}
        >
          <a
            className="page-link"
            aria-label="Next"
            onClick={handleIncreasePage}
          >
            <span aria-hidden="true">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
