import React, { useState } from "react";
import CreateInhouseExamSet from "../../components/Inhouse/CreateInhouseExamSet";
import InhouseExamSetTable from "../../components/Inhouse/InhouseExamSet";
import axiosInstance from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import handle401Exception from "../../utils/handle401Exception";
import { Search } from "../../components/Inhouse/InhouseStudents";
import Pagination from "../../components/Pagination/Pagination";

const InhouseExamSet = () => {
  const {
    inhouseExamSetArray,
    isLoading,
    handleIncreasePage,
    handleDecreasePage,
    isFetching,
    totalPages,
    paginationSettings,
    setPaginationSettings,
  } = useFetchInhouseExamSet();
  return (
    <div>
      <CreateInhouseExamSet />
      <div className="my-4 d-flex justify-content-between align-items-center">
        <div>
          <h1
            style={{
              fontSize: "1.5rem",
              marginTop: "1rem",
            }}
          >
            All available Exam Sets
          </h1>
        </div>
        {inhouseExamSetArray !== undefined && (
          <Search setPaginationSettings={setPaginationSettings} />
        )}
      </div>
      {!isLoading || !isFetching ? (
        <>
          {" "}
          {inhouseExamSetArray !== undefined &&
          inhouseExamSetArray.length !== 0 ? (
            <InhouseExamSetTable
              pageUsed={"InhouseExamSet"}
              inhouseExamSetArray={inhouseExamSetArray}
            />
          ) : (
            <p>No exam set available</p>
          )}
        </>
      ) : null}
      <div
        className="container "
        style={{
          textAlign: "center",
          width: "40%",
        }}
      >
        {totalPages !== null && (
          <Pagination
            currentPage={paginationSettings.currentPage}
            handleIncreasePage={handleIncreasePage}
            totalPages={totalPages}
            handleDecreasePage={handleDecreasePage}
            setPaginationSettings={setPaginationSettings}
          />
        )}
      </div>
    </div>
  );
};

export default InhouseExamSet;

export const useFetchInhouseExamSet = () => {
  const [paginationSettings, setPaginationSettings] = useState({
    currentPage: 1,
    limit: 10,
    status: 1,
    name: "",
  });

  const [totalData, setTotalData] = useState(null);

  let queryKey = [
    "get-inhouse-exam-sets",
    paginationSettings.currentPage,
    paginationSettings.limit,
    paginationSettings.name,
    paginationSettings.status,
  ];
  const queryFunction = async () =>
    await axiosInstance.get(
      `/api/getexamsetinhousestudentpagination?page=${paginationSettings.currentPage}&limit=${paginationSettings.limit}&status=${paginationSettings.status}&set_title=${paginationSettings.name}`
    );
  const [inhouseExamSetArray, setInhouseExamSetArray] = useState([]);

  const performSideEffects = (response) => {
    setInhouseExamSetArray(
      JSON.parse(JSON.stringify(response?.data?.data || {}))
    );
    setTotalData((prev) => response?.data?.total);
  };
  const { isLoading, isFetching } = useQuery(queryKey, queryFunction, {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      if (response.status === 200) {
        performSideEffects(response);
      }
    },
    onError: (error) => {
      if (error?.response?.status === 401) {
        handle401Exception(queryFunction, performSideEffects);
      }
    },
  });

  // pagination states

  const totalPages = totalData
    ? Math.ceil(totalData / paginationSettings.limit)
    : null;
  const handleIncreasePage = () => {
    if (paginationSettings.currentPage === totalPages) {
      return;
    }
    setPaginationSettings((prev) => ({
      ...prev,
      currentPage: prev["currentPage"] + 1,
    }));
  };
  const handleDecreasePage = () => {
    if (paginationSettings.currentPage === 1) {
      return;
    }
    setPaginationSettings((prev) => ({
      ...prev,
      currentPage: prev["currentPage"] - 1,
    }));
  };
  return {
    handleDecreasePage,
    handleIncreasePage,
    paginationSettings,
    totalData,
    inhouseExamSetArray,
    setInhouseExamSetArray,
    isFetching,
    isLoading,
    setPaginationSettings,
    totalPages,
  };
};
