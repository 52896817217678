import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import ProtectedRoutes from "../ProtectedRoutes/ProtectedRoutes";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { useContext } from "react";
import { GlobalContext } from "../../utils/GlobalContext";
import ImagePopup from "../ImagePopup/ImagePopup";
const SharedAreaLayout = () => {
  const { isAppLoading, openImagePopup } = useContext(GlobalContext);

  return (
    <ProtectedRoutes>
      {isAppLoading && <LoadingSpinner />}
      <div
        style={{
          display: "flex",
          maxHeight: "100vh",
          alignItems: "flex-start",
          overflow: "hidden",
          justifyContent: "space-between",
        }}
      >
        {/* when you click over image, imgaes will render in big modal */}
        {openImagePopup !== "" && <ImagePopup imageUrl={openImagePopup} />}
        <Sidebar />
        <div
          className="px-4"
          style={{
            overflowY: "scroll",
            flexGrow: "1",
            paddingBlock: ".5rem",
            height: "100vh",
          }}
        >
          <Outlet />
        </div>
      </div>
    </ProtectedRoutes>
  );
};

export default SharedAreaLayout;
