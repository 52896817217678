import { Card, Figure } from "react-bootstrap";
import React, { useEffect, useRef } from "react";
const OptionCard = ({
  optionType,
  option,
  index,
  correctAnswer,
  setCorrectAnswer,
  optionsList,
}) => {
  const audioChangeRef = useRef();
  useEffect(() => {
    if (audioChangeRef.current) {
      audioChangeRef.current?.load();
    }
  }, [optionsList]);
  return (
    <>
      {optionType === "text" && (
        <Card
          key={index}
          style={{
            backgroundColor:
              optionsList[correctAnswer - 1] === option
                ? "lightgreen"
                : "white",
            paddingBlock: ".5rem",
            cursor: "pointer",
            paddingInline: "1rem",
            marginBlock: ".5rem",
          }}
          onClick={(e) => setCorrectAnswer(index + 1)}
        >
          {index + 1}. {option}
        </Card>
      )}
      {optionType === "image" && (
        <Figure
          className="my-4 d-flex gap-2"
          style={{
            backgroundColor:
              optionsList[correctAnswer - 1] === option
                ? "lightgreen"
                : "white",
            paddingBlock: ".5rem",
            cursor: "pointer",
            paddingInline: "1rem",
            marginBlock: ".5rem",
            width: '80%'
          }}
          onClick={(e) => setCorrectAnswer(index + 1)}
        >
          <p>{index + 1}.</p>
          <img
            // width={260}
            // height={220}
            style={{
              background: "transparent",
              display: 'block',
              maxWidth: '100%'
            }}
            alt="Image related to your question"
            accept="image/png, image/gif, image/jpeg"
            src={URL.createObjectURL(option)}
          />
        </Figure>
      )}

      {optionType === "audio" && (
        <Figure
          className="my-4 d-flex align-items-center gap-2"
          style={{
            backgroundColor:
              optionsList[correctAnswer - 1] === option
                ? "lightgreen"
                : "white",
            paddingBlock: ".5rem",
            cursor: "pointer",
            paddingInline: "1rem",
            marginBlock: ".5rem",
          }}
          onClick={(e) => setCorrectAnswer(index + 1)}
        >
          <p>{index + 1}.</p>
          <audio ref={audioChangeRef} controls>
            <source src={URL.createObjectURL(option)} type="audio/mpeg" />
          </audio>
        </Figure>
      )}
    </>
  );
};

export default OptionCard;
