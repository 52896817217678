import { useState } from "react";
import { Figure, Form } from "react-bootstrap";
import { ImageComponent } from "../../../QuestionOnly/QuestionOnlyCard/QuestionOnlyCard";
import { toast } from "react-hot-toast";
import ImageCropperModal from "../../../ImageCropper/ImageCropperModal";
import { useGlobalContext } from "../../../../utils/GlobalContext";

const RenderQuestionImage = ({ question_image, imageFile, setImageFile }) => {
  const { setOpenImagePopup } = useGlobalContext();
  const [openEditImage, setOpenEditImage] = useState(false);
  // open to crop image
  const [openCropImageModal, setOpenCropImageModal] = useState(null);
  const handleUploadImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    if (e.target.files[0].size > "250000") {
      toast.error("Image files cannot be more than 250KB");
      e.target.value = "";
      return;
    }
    setOpenCropImageModal(e.target.files[0]);

    // setImageFile(e.target.files[0]);
  };
  return (
    <div>
      {openCropImageModal && (
        <ImageCropperModal
          setOpenCropImageModal={setOpenCropImageModal}
          openCropImageModalData={openCropImageModal}
          setSelectedImage={setImageFile}
        />
      )}
      {question_image && !openEditImage ? (
        <>
          <p>Question Image</p>
          <ImageComponent option={question_image} />
          <button
            type="button"
            style={{ background: "#EE4B2B", border: "none" }}
            onClick={() => {
              setOpenEditImage((prev) => !prev);
              setImageFile("none");
            }}
            className="btn btn-secondary d-block mt-3 mb-4 "
          >
            Remove Image
          </button>
        </>
      ) : (
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Upload Question Image </Form.Label>
          <Form.Control
            onChange={handleUploadImage}
            type="file"
            accept="image/png, image/gif, image/jpeg"
          />
          {imageFile !== null && imageFile !== "none" && (
            <Figure className="my-4">
              <Figure.Image
                width={260}
                height={220}
                style={{
                  cursor: "pointer",
                }}
                src={URL.createObjectURL(imageFile)}
                alt="Image related to your question"
                onClick={() =>
                  setOpenImagePopup(URL.createObjectURL(imageFile))
                }
              />
            </Figure>
          )}
          {question_image && (
            <button
              type="button"
              style={{ background: "#EE4B2B", border: "none" }}
              onClick={() => setOpenEditImage((prev) => !prev)}
              className="btn btn-secondary d-block mt-3 mb-4 "
            >
              Undo Remove image{" "}
            </button>
          )}
        </Form.Group>
      )}
    </div>
  );
};

export default RenderQuestionImage;
