import React, { useEffect, useRef, useState } from "react";
import Image from "../../../assets/lotus_logo.png";
import { toast } from "react-hot-toast";
import { useMutation } from "react-query";
import "./Login.css";
import { IoEyeSharp } from "react-icons/io5";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { Navigate } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import useRedirectIfAlreadyLoggedIn from "../../../customHooks/useRedirectIfAlreadyLoggedIn";
import axiosInstance from "../../../utils/axiosInstance";
import { FaUser } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const { redirectToHomePage } = useRedirectIfAlreadyLoggedIn();
  const [userDetails, setUserDetails] = useState({
    username: "",
    password: "",
    captchaToken: ""
  });

  const [showPassword, setShowPassword] = useState(false);

  const captchaRef = useRef(null)

  const [navigate, setNavigate] = useState(false);
  const { mutate, isLoading, error } = useMutation(
    () => axiosInstance.post("/api/admin/signin", {...userDetails,captchaToken:captchaRef.current.getValue()}),
    {
      onError: (error) => {
        if (error?.response.status === 401) {
          toast.error(error?.response.data?.message);
        }
      },
      onSuccess: (data) => {
        // everything OK
        if (data?.status === 200) {
          toast.success("Login Successfull.");
          localStorage.setItem(
            "access_token",
            JSON.stringify(data?.data?.["access_token"])
          );
          localStorage.setItem(
            "refresh_token",
            JSON.stringify(data?.data?.["refresh_token"])
          );

          // to navigate from login to home
          setNavigate(true);
        }
      },
    }
  );

  const handleSubmit = () => {
    if (userDetails?.username === "" && userDetails?.password === "") {
      toast.error("Please enter your username and password", {
        duration: 2000,
        position: "top-center",
      });
      return;
    }
    if (userDetails?.username === "") {
      toast.error("Please enter your username", {
        duration: 2000,
        position: "top-center",
      });
      return;
    }
    if (userDetails?.password === "") {
      toast.error("Please enter your password", {
        duration: 2000,
        position: "top-center",
      });
      return;
    }
    mutate({ userDetails });
  };

  const handleInputChange = (e) => {
    setUserDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      {redirectToHomePage && <Navigate to={"/"} replace={true} />}
      {navigate && <Navigate to={"/"} replace={true} />}
      <form
        className="container mt-5 container_width"
        style={{
          border: "1px solid #e5e7eb",
          paddingBlock: "2rem",
          paddingInline: "2rem",
          borderRadius: "10px",
          background: "white",
        }}
      >
        {isLoading && <LoadingSpinner />}
        <div
          className="container"
          style={{
            width: "150px",
          }}
        >
          <img
            src={Image}
            alt="Hello"
            style={{
              display: "block",
              width: "100%",
            }}
          />
        </div>
        <h1
          className="py-3"
          style={{
            fontSize: "1.8rem",
            textAlign: "center",
          }}
        >
          Admin Login
        </h1>
        <div className="form-outline mb-4">
          {" "}
          <div
            id="form2Example1"
            className="form-control"
            style={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              paddingBlock: ".5rem",
              background: "transparent",
            }}
          >
            <FaUser />
            <input
              onChange={handleInputChange}
              type="text"
              value={userDetails.username}
              name="username"
              placeholder="Username"
              style={{
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
              }}
            />
          </div>
        </div>
        <div className="form-outline mb-4">
          {/* <p className="d-flex align-items-center justify-content-between">
            <label htmlFor="form2Example2">Password</label>
          </p> */}
          <div
            id="form2Example1"
            className="form-control"
            style={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              paddingBlock: ".5rem",
              background: "transparent",
            }}
          >
            {showPassword ? (
              <IoEyeSharp
                style={{
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                  paddingTop: "3px",
                }}
                onClick={() => setShowPassword((prev) => !prev)}
              />
            ) : (
              <BsFillEyeSlashFill
                style={{
                  paddingTop: "3px",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                onClick={() => setShowPassword((prev) => !prev)}
              />
            )}
            <input
              onChange={handleInputChange}
              value={userDetails.password}
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              style={{
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
              }}
            />
          </div>
        </div>
        {/* <div className="row mb-4">
          <div className="col">
            <a href="#!">Forgot password?</a>
          </div>
        </div> */}
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY}
          ref={captchaRef}
        />
        <button
          type="button"
          onClick={handleSubmit}
          className="btn btn-primary btn-block mb-4 w-100 mt-3"
        >
          Sign in
        </button>
      </form>
    </>
  );
};

export default Login;
