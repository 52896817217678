import React from "react";
import { Button, Modal } from "react-bootstrap";
import ImageCropper from "./ImageCropper";
import { IoMdCreate } from "react-icons/io";

const ImageCropperModal = ({
  setOpenCropImageModal,
  openCropImageModalData,
  setSelectedImage,
  handleRemoveImageCache
}) => {

  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>Crop Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ImageCropper
          setOpenCropImageModal={setOpenCropImageModal}
          openCropImageModalData={openCropImageModalData}
          circular={false}
          setCroppedImg={setSelectedImage}
          handleRemoveImageCache={handleRemoveImageCache}
        />
      </Modal.Body>
      
    </Modal>
  );
};

export default ImageCropperModal;
// setOpenCropImageModal={setOpenCropImageModal}
//           openCropImageModalData={openCropImageModal}
//           setSelectedImage={setSelectedImage}
