import React, { useEffect, useRef, useState } from "react"
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"
import handle401Exception from "../../utils/handle401Exception"
import axiosInstance from "../../utils/axiosInstance"
import { BsArrow90DegUp } from 'react-icons/bs'
import { toast } from "react-hot-toast"

const AccessControl = () => {

    const [questionSetData, setQuestionSetData] = useState([])
    const [packageSetData, setPackageSetData] = useState([])
    const [studentData, setStudentData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [questionSet, setQuestionSet] = useState(null)
    const [packageSet, setPackageSet] = useState(null)
    const isFirstRender = useRef(true)
    const [selectedOption, setSelectedOption] = useState('question_set');


    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(()=>{
        if(selectedOption=='question_set'){
            setQuestionSet(null)
            setQuestionSetData([])
            getQuestionSetList()
        }else{
            setPackageSet(null)
            setPackageSetData([])
            getPackageSetList()
        }
        setStudentData([])
    },[selectedOption])

    const handleSelectAll = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);

        if (isChecked) {
            setSelectedRows(studentData.map((stu) => stu.student_id));
        } else {
            setSelectedRows([]);
        }
    };

    const handleRowCheckboxChange = (event, studentId) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setSelectedRows((prevSelectedRows) => [...prevSelectedRows, studentId]);
        } else {
            setSelectedRows((prevSelectedRows) =>
                prevSelectedRows.filter((rowStudentId) => rowStudentId !== studentId)
            );
        }
    };

    const handleToggleAllButtonClick = () => {
        if (selectedRows.length === studentData.length) {
            setSelectedRows([]);
            setSelectAll(false);
        } else {
            setSelectedRows(studentData.map((stu) => stu.student_id));
            setSelectAll(true);
        }
    };

    let getQuestionSetList = async () => {
        setIsLoading(true)
        await axiosInstance.get('/api/getallquestionset')
            .then((response) => {
                setIsLoading(false)
                setQuestionSetData(response.data.data)
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    handle401Exception(getQuestionSetList, () => { })
                }
                setIsLoading(false)
            })
    }

    let getPackageSetList = async () => {
        setIsLoading(true)
        await axiosInstance.get('/api/getallpackageset')
            .then((response) => {
                setIsLoading(false)
                setPackageSetData(response.data.data)
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    handle401Exception(getPackageSetList, () => { })
                }
                setIsLoading(false)
            })
    }

    let getStudentListForQuestionSet = async (questionSet) => {
        setIsLoading(true)
        if (questionSet == null) return
        await axiosInstance.get(`/api/getactiveinhousestudent?accessFor=question_set&productId=${questionSet}`)
            .then((response) => {
                setIsLoading(false)
                setStudentData(response.data.data)
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    handle401Exception(getStudentListForQuestionSet, () => { })
                }
                setIsLoading(false)
            })
    }

    let getStudentListForPackageSet = async (packageSet) => {
        setIsLoading(true)
        if (packageSet == null) return
        await axiosInstance.get(`/api/getactiveinhousestudent?accessFor=package_set&productId=${packageSet}`)
            .then((response) => {
                setIsLoading(false)
                setStudentData(response.data.data)
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    handle401Exception(getStudentListForPackageSet, () => { })
                }
                setIsLoading(false)
            })
    }


    let handleChange = (e) => {
        setQuestionSet(e.target.value)
    }

    let handlePackageChange = (e) => {
        setPackageSet(e.target.value)
    }

    let giveAccess = async () => {


        if (selectedOption == 'question_set') {
            if (questionSet == null || questionSet == "") {
                toast.error("Please select the question set")
                return
            }
        } 
        if(selectedOption=='package_set'){
            if (packageSet == null || packageSet == "") {
                toast.error("Please select the package set")
                return
            }
        }
        if (selectedRows.length == 0) {
            toast.error('Please select at lease one student')
            return
        }
        let obj = {
            access_for: selectedOption,
            product_id: selectedOption == "question_set" ? questionSet : packageSet,
            inhouse_student_id: selectedRows
        }
        setIsLoading(true)
        await axiosInstance.post('/api/giveaccesstoinhousestudent', obj)
            .then((response) => {
                setIsLoading(false)
                if (selectedOption == 'question_set') {
                    getStudentListForQuestionSet(questionSet)
                } else {
                    getStudentListForPackageSet(packageSet)
                }
                setSelectedRows([])
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    handle401Exception(giveAccess, () => { })
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getQuestionSetList()
        getPackageSetList()
    }, [])

    useEffect(() => {
        // Skip the initial call during the first render
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        getStudentListForQuestionSet(questionSet)
    }, [questionSet])

    useEffect(() => {
        // Skip the initial call during the first render
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        getStudentListForPackageSet(packageSet)
    }, [packageSet])


    return (
        <div>
            <h1>Access Control</h1>
            {isLoading ? <LoadingSpinner /> : ""}
            <div style={{ marginTop: "50px" }}>

                <label style={{ marginRight: "10px", fontWeight: "500" }} htmlFor="">
                    <input
                        style={{ marginRight: "10px", width: "18px", height: "18px" }}
                        type="radio"
                        value="question_set"
                        checked={selectedOption == 'question_set'}
                        onChange={handleOptionChange}
                    />
                    Question Set
                </label>

                <label style={{ marginRight: "10px", fontWeight: "500" }} htmlFor="">
                    <input
                        style={{ marginRight: "10px", width: "18px", height: "18px" }}
                        type="radio"
                        value="package_set"
                        checked={selectedOption == 'package_set'}
                        onChange={handleOptionChange}
                    />
                    Package Set
                </label>
            </div>
            <div style={{ marginTop: "30px" }}>
                <div className="d-flex mb-3">
                    <div>
                        <label className="form-label">
                            {selectedOption == 'question_set' ? 'Select the question set' : 'Select the package set'}
                            <span
                                style={{
                                    color: "red",
                                    paddingLeft: ".25rem",
                                    fontWeight: "bold",
                                }}
                            >
                                *
                            </span>{" "}
                        </label>
                        {
                            selectedOption == "question_set" ?
                                <select
                                    name="questionSet"
                                    className="form-select"
                                    onChange={handleChange}
                                    style={{ width: "16.5rem" }}
                                >
                                    <option value="" hidden>

                                    </option>
                                    {questionSetData.map((questionSet, index) => (
                                        <option
                                            value={questionSet.question_set_id}
                                            key={index}
                                        >
                                            {questionSet.name}
                                        </option>
                                    ))}
                                </select>
                                :
                                <select
                                    name="packageSet"
                                    className="form-select"
                                    onChange={handlePackageChange}
                                    style={{ width: "16.5rem" }}
                                >
                                    <option value="" hidden>

                                    </option>
                                    {packageSetData.map((packageSet, index) => (
                                        <option
                                            value={packageSet.package_id}
                                            key={index}
                                        >
                                            {packageSet.package_name}
                                        </option>
                                    ))}
                                </select>
                        }
                    </div>
                </div>
                {
                    studentData.length !== 0 ?
                        <>
                            <div style={{ height: "300px", overflow: "scroll" }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>S.N</th>
                                            <th>Student Id</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Access Detail</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentData.map((stu, index) => (
                                            <tr>
                                                <td>
                                                    <input
                                                        checked={selectedRows.includes(stu.student_id)}
                                                        onChange={(event) => handleRowCheckboxChange(event, stu.student_id)}
                                                        style={{ width: "18px", height: "18px" }}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                    /></td>
                                                <td>{index + 1}</td>
                                                <td>{stu.student_id}</td>
                                                <td>{stu.name}</td>
                                                <td>{stu.email}</td>
                                                <td>
                                                    {
                                                        stu.access ?
                                                            <span
                                                                style={{
                                                                    paddingInline: ".8rem",
                                                                    paddingBlock: ".25rem",
                                                                    borderRadius: "25px",
                                                                    background: "#DAF7A6",
                                                                    cursor: "pointer",
                                                                    fontSize: ".8rem",
                                                                    color: "green",
                                                                }}
                                                            >
                                                                Granted
                                                            </span>
                                                            :
                                                            <span
                                                                style={{
                                                                    paddingInline: ".8rem",
                                                                    paddingBlock: ".25rem",
                                                                    borderRadius: "25px",
                                                                    background: "#FAA0A0",
                                                                    cursor: "pointer",
                                                                    fontSize: ".8rem",
                                                                    color: "#770737",
                                                                }}
                                                            >
                                                                Denied
                                                            </span>
                                                    }
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>

                                </table>
                            </div>
                            <div className="mb-2 mt-2">
                                <div style={{ marginLeft: "10px", display: 'flex' }}>
                                    <BsArrow90DegUp style={{
                                        width: "25px",
                                        height: "20px",
                                    }} />
                                    <div>
                                        <input
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                            style={{ width: "18px", height: "18px", marginTop: "10px", marginLeft: '7px', marginRight: "0px" }}
                                            className="form-check-input"
                                            type="checkbox"
                                        />
                                        <button onClick={handleToggleAllButtonClick} className="btn">
                                            {selectedRows.length === studentData.length ? 'Deselect All' : 'Select All'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button onClick={giveAccess} className="btn btn-primary">Give Access To Selected Students</button>
                                {/* <button style={{ marginLeft: "10px" }} className="btn btn-success">Give Access To All Students</button> */}
                            </div>
                        </>
                        :
                        
                            ""
                }
            </div>
        </div>
    )
}

export default AccessControl