import { Modal, Form, Button } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { queryClient } from "../../../index";
import { useContext } from "react";
import { GlobalContext } from "../../../utils/GlobalContext";
import axiosInstance from "../../../utils/axiosInstance";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import handle401Exception from "../../../utils/handle401Exception";
import { IoMdCreate } from "react-icons/io";
import { AiOutlineClear, AiOutlineCloseCircle } from "react-icons/ai";
import {
  convertToTime,
  formatTime,
  convertToMinutes,
} from "../../../utils/time";

const EditInhouseExamSet = ({
  set_title,
  set_duration,
  set_id,
  setEditModal,
}) => {
  const [formData, setFormData] = useState({
    set_title,
    set_duration,
    set_id,
  });

  // on input field change
  const handleOnChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // related to expiry time
  const timeRef = useRef();
  const [totalTime, setTotalTime] = useState("");

  useEffect(() => {
    const minutes = convertToMinutes(set_duration);

    // set value of input[time wala]
    if (timeRef) {
      timeRef.current.value = Number(minutes);
    }

    // set total time string
    setTotalTime(() => formatTime(minutes));
  }, []);

  // exam duration field change => total time
  // handle form field change
  const handleExpiryFieldChange = (e) => {
    if (e.target.value <= -1) {
      toast.error("Exam duration cannot be negative");
      timeRef.current.value = 1;
      setTotalTime(() => formatTime(timeRef.current.value));
      return;
    }
    setTotalTime(() => formatTime(timeRef.current.value));
  };

  const { handleSubmit, handleClose } = useEditQuestionSet(
    formData,
    totalTime,
    setEditModal,
    set_id,
    timeRef.current?.value
  );

  return (
    <>
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Question Set</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label className="form-label">Set Title</label>
              <input
                onChange={handleOnChange}
                name="set_title"
                className="form-control"
                value={formData.set_title}
              />
            </Form.Group>
            <Form.Group>
              <label className="form-label">Exam duration</label>
              <div className="d-flex align-items-center gap-5">
                <div className="d-flex  gap-2 align-items-center">
                  <input
                    min="1"
                    ref={timeRef}
                    name="m"
                    type="number"
                    className="form-control w-50"
                    onChange={handleExpiryFieldChange}
                  />
                  <p className="form-label d-flex gap-4 " htmlFor="">
                    Min
                    <span className=" ">
                      {totalTime ? totalTime : "0 minute"}
                    </span>
                  </p>
                </div>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{
              display: "flex",
              gap: ".5rem",
              alignItems: "center",
              background: "#EE4B2B",
              border: "none",
            }}
          >
            <AiOutlineCloseCircle />

            <span className="ml-3">Close</span>
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            <IoMdCreate />
            <span> Save Changes</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditInhouseExamSet;

const useEditQuestionSet = (
  formData,
  totalTime,
  setEditModal,
  set_id,
  time_in_minutes
) => {
  const { setIsAppLoading } = useContext(GlobalContext);

  // what will happen after the req is made
  const performSideEffects = () => {
    handleClose();
    queryClient.invalidateQueries({
      queryKey: ["get-inhouse-exam-sets"],
    });
    setIsAppLoading(false);
  };
  // query function to add question set
  const queryFunction = async (exam_set) => {
    return await axiosInstance
      .put("/api/examsetinhousestudent", exam_set)
      .then((response) => {
        if (response.status === 201) {
          performSideEffects();
        }
      });
  };

  // generate data to send
  const generateData = () => {
    if (formData.set_title === "") {
      toast.error("Please enter the title of exam set");
      return false;
    }
    if (
      parseInt(time_in_minutes) === 0 ||
      !time_in_minutes ||
      time_in_minutes === "0"
    ) {
      toast.error("Exam Duration is required.");
      return false;
    }
    if (parseInt(time_in_minutes) >= 1440) {
      toast.error("Exam Duration cannot be more than 24 hours.");
      return false;
    }
    const exam_duration = convertToTime(parseInt(time_in_minutes));
    const data = {
      ...formData,
      set_duration: exam_duration,
    };
    return data;
  };
  // when we close the modal
  const handleClose = () => {
    setEditModal((prev) => ({
      set_title: "",
      set_id: null,
      set_duration: "",
    }));
  };

  const { mutateAsync } = useMutation(queryFunction, {
    onError: (error) => {
      if (error?.response?.status === 401) {
        const data = generateData();
        if (data === false) return;
        handle401Exception(
          () => queryFunction({ ...data, set_id }),
          performSideEffects
        );
      }
    },
  });

  const handleSubmit = () => {
    const data = generateData();
    if (data === false) return;
    setIsAppLoading(true);
    mutateAsync({ ...data, set_id });
  };

  return {
    handleSubmit,
    handleClose,
  };
};
