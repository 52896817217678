import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Figure, Form, Modal } from "react-bootstrap";
import logo from "../../assets/lotus_logo.png";
import { toast } from "react-hot-toast";
import {
  AudioComponent,
  ImageComponent,
} from "../QuestionOnly/QuestionOnlyCard/QuestionOnlyCard";
import axiosInstance from "../../utils/axiosInstance";
import { useMutation } from "react-query";
import handle401Exception from "../../utils/handle401Exception";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";
import { queryClient } from "../..";
import { BiEditAlt } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { IoMdCreate } from "react-icons/io";
import { AiOutlineClear, AiOutlineCloseCircle } from "react-icons/ai";
import EditOptionModal from "./Edit/EditOptionsModal";
import SelectCorrectAnswer from "./Edit/SelectCorrectAnswer";
import AddOptions from "../QuestionOnly/QuestionOnlyForm/AddOptions";
import OptionsRenderer from "../QuestionOnly/QuestionOnlyForm/OptionsRenderer";
import RenderQuestionImage from "./Edit/Render/RenderQuestionImage";
import RenderQuestionAudio from "./Edit/Render/RenderQuestionAudio";
import ActualOptionComponent from "./Edit/ActualOptionComponent";

const ViewQuestions = ({
  question_title,
  option_type,
  option1,
  option2,
  option3,
  option4,
  question_audio,
  question_image,
  correct_answer,
  question_id,
  question_set_id,
  question_subtitle,
  question_text,
  API_URL,
  onSuccessLink,
  queryKey
}) => {
  const { question_set_name } = useParams();
  const navigate = useNavigate();
  const [questionTitle, setQuestionTitle] = useState(question_title);
  const [imageFile, setImageFile] = useState(null);
  const [audioFile, setAudioFile] = useState(null);

  // question subtitle and question text
  const [questionSubtitleAndText, setQuestionSubtitleAndText] = useState({
    question_subtitle,
    question_text,
  });

  const optionsArray = [
    {
      option1: option1,
    },
    {
      option2: option2,
    },
    {
      option3: option3,
    },
    {
      option4: option4,
    },
  ];
  const [selectedOptionsArray, setSelectedOptionsArray] =
    useState(optionsArray);

  // option type
  const [selectedOptionType, setSelectedOptionType] = useState(option_type);
  const handleRadioButtonChange = (type) => {
    if (optionsList.length !== 0) {
      setOptionsList([]);
    }
    setSelectedOptionType((prev) => type);
  };

  // list of selected options from a type
  const [optionsList, setOptionsList] = useState([]);

  // correct answer
  const [correctAnswer, setCorrectAnswer] = useState(correct_answer);

  const [openEditOptionModal, setOpenEditOptionModal] = useState("");
  // handleEditButtonClick: clicked_option: 'option1' 'option2' etc
  const handleEditButtonClick = (clicked_option) => {
    setOpenEditOptionModal(clicked_option);
  };

  // api
  const queryFunction = async (data) => {
    return await axiosInstance.put(API_URL, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const performSideEffects = () => {
    queryClient.invalidateQueries({ queryKey });
    navigate(onSuccessLink);
  };
  const { isLoading, mutate } = useMutation(queryFunction, {
    onSuccess: (response) => {
      if (response.status === 201) {
        performSideEffects();
      }
    },
    onError: (error) => {
      if (error?.response?.status === 401) {
        if (!correctAnswer) {
          toast.error("Please select a correct answer.");
          return;
        }
        let formData = new FormData();
        formData.append("question_title", questionTitle);
        formData.append("question_id", question_id);
        formData.append(
          "question_subtitle",
          questionSubtitleAndText.question_subtitle
        );
        formData.append("question_text", questionSubtitleAndText.question_text);
        formData.append("option_type", selectedOptionType);

        if (selectedOptionType === option_type) {
          formData.append("option1", selectedOptionsArray[0].option1);
          formData.append("option2", selectedOptionsArray[1].option2);
          formData.append("option3", selectedOptionsArray[2].option3);
          formData.append("option4", selectedOptionsArray[3].option4);
          formData.append("correct_answer", correctAnswer);
        } else {
          formData.append("option1", optionsList[0]);
          formData.append("option2", optionsList[1]);
          formData.append("option3", optionsList[2]);
          formData.append("option4", optionsList[3]);
          formData.append("correct_answer", `option${correctAnswer}`);
        }
        if (imageFile === "none") {
          formData.append("question_image", "");
        } else if (imageFile !== null) {
          formData.append("question_image", imageFile);
        } else {
          formData.append("question_image", question_image);
        }
        if (audioFile === "none") {
          formData.append("question_audio", "");
        } else if (audioFile !== null) {
          formData.append("question_audio", audioFile);
        } else {
          formData.append("question_audio", question_audio);
        }
        handle401Exception(() => queryFunction(formData), performSideEffects);
      }
    },
  });

  const [optionValue, setOptionValue] = useState();

  const handleSubmit = () => {
    let formData = new FormData();
    if (!correctAnswer && option_type !== selectedOptionType) {
      toast.error("Please select a correct answer.");
      return;
    }
    formData.append("question_title", questionTitle);
    formData.append("question_id", question_id);
    formData.append(
      "question_subtitle",
      questionSubtitleAndText.question_subtitle
    );
    formData.append("question_text", questionSubtitleAndText.question_text);
    formData.append("option_type", selectedOptionType);

    if (selectedOptionType === option_type) {
      formData.append("option1", selectedOptionsArray[0].option1);
      formData.append("option2", selectedOptionsArray[1].option2);
      formData.append("option3", selectedOptionsArray[2].option3);
      formData.append("option4", selectedOptionsArray[3].option4);
      formData.append("correct_answer", correctAnswer);
    } else {
      formData.append("option1", optionsList[0]);
      formData.append("option2", optionsList[1]);
      formData.append("option3", optionsList[2]);
      formData.append("option4", optionsList[3]);
      formData.append(
        "correct_answer",
        typeof correctAnswer === "number"
          ? `option${correctAnswer}`
          : correctAnswer
      );
    }

    if (imageFile === "none") {
      formData.append("question_image", "");
    } else if (imageFile !== null) {
      formData.append("question_image", imageFile);
    } else {
      formData.append("question_image", question_image);
    }
    if (audioFile === "none") {
      formData.append("question_audio", "");
    } else if (audioFile !== null) {
      formData.append("question_audio", audioFile);
    } else {
      formData.append("question_audio", question_audio);
    }

    // validations

    if (
      formData.get("option1") === "undefined" ||
      formData.get("option2") === "undefined" ||
      formData.get("option3") === "undefined" ||
      formData.get("option4") === "undefined"
    ) {
      toast.error("Please insert 4 possible options. ");
      return;
    }
    if (formData.get("correct_answer") === "undefined") {
      toast.error("Please select a correct answer.");
      return;
    }

    mutate(formData);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <form
      className="my-1 mb-3"
      style={{
        width: "80%",
      }}
    >
      <div className="mb-1">
        <label className="form-label">
          Question Title{" "}
          <span
            style={{
              color: "red",
              fontSize: "1rem",
            }}
          >
            {" "}
            *
          </span>{" "}
        </label>
        <input
          value={questionTitle}
          onChange={(e) => setQuestionTitle(() => e.target.value)}
          name="question_title"
          className="form-control w-50"
        />
      </div>
      <div className="mb-3">
        <label className="form-label">
          Question Subtitle{" "}
          {/* <span
            style={{
              color: "red",
              fontSize: "1rem",
            }}
          >
            {" "}
            *
          </span>{" "} */}
        </label>
        <textarea
          value={questionSubtitleAndText.question_subtitle}
          onChange={(e) =>
            setQuestionSubtitleAndText((prev) => ({
              ...prev,
              question_subtitle: e.target.value,
            }))
          }
          className="form-control w-50"
          style={{
            minHeight: "7rem",
          }}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Question Text </label>
        <input
          value={questionSubtitleAndText.question_text}
          onChange={(e) =>
            setQuestionSubtitleAndText((prev) => ({
              ...prev,
              question_text: e.target.value,
            }))
          }
          className="form-control w-50"
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-between",
          gap: "10rem",
        }}
      >
        <RenderQuestionImage
          imageFile={imageFile}
          setImageFile={setImageFile}
          question_image={question_image}
        />
        <RenderQuestionAudio
          question_audio={question_audio}
          audioFile={audioFile}
          setAudioFile={setAudioFile}
        />
      </div>

      <p>
        Option Type{" "}
        <span
          style={{
            color: "red",
            fontSize: "1rem",
          }}
        >
          {" "}
          *
        </span>{" "}
      </p>
      <div className="mb-3">
        <Form.Check
          inline
          label="Text"
          name="group1"
          type={"radio"}
          checked={selectedOptionType === "text" ? true : false}
          onChange={() => {
            handleRadioButtonChange("text");
          }}
        />
        <Form.Check
          inline
          label="Image"
          name="group1"
          checked={selectedOptionType === "image" ? true : false}
          type={"radio"} // id={`inline-${type}-2`}
          onChange={() => {
            handleRadioButtonChange("image");
          }}
        />
        <Form.Check
          inline
          label="Audio"
          name="group1"
          checked={selectedOptionType === "audio" ? true : false}
          type={"radio"} // id={`inline-${type}-2`}
          onChange={() => {
            handleRadioButtonChange("audio");
          }}
        />
        {/* // edit modal  */}
        {openEditOptionModal && (
          <EditOptionModal
            optionType={option_type}
            setOpenEditOptionModal={setOpenEditOptionModal}
            handleEditButtonClick={handleEditButtonClick}
            setSelectedOptionsArray={setSelectedOptionsArray}
            openEditOptionModal={openEditOptionModal}
            selectedOptionsArray={selectedOptionsArray}
          />
        )}
        {selectedOptionType === option_type ? (
          <>
            <p className="mt-3">Options:</p>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                rowGap: "1rem",
                columnGap: "5rem",
                // border: "2px solid black",
              }}
            >
              {selectedOptionsArray.map((optionObject, index) => {
                return (
                  <>
                    <ActualOptionComponent
                      option_number={index + 1}
                      optionObject={optionObject}
                      optionType={option_type}
                      handleEditButtonClick={handleEditButtonClick}
                    />
                  </>
                );
              })}
            </div>
            <SelectCorrectAnswer
              correctAnswer={correctAnswer}
              setCorrectAnswer={setCorrectAnswer}
            />
          </>
        ) : (
          <div className="my-3">
            {optionsList.length !== 4 && (
              <AddOptions
                optionsList={optionsList}
                setOptionsList={setOptionsList}
                optionType={selectedOptionType}
                optionValue={optionValue}
                setOptionValue={setOptionValue}
              />
            )}
            {optionsList.length !== 0 && (
              <>
                <p className="mt-2">
                  <span style={{ color: "red" }}>*</span> Select correct answer
                  from options by clicking them below:{" "}
                  <span style={{ color: "red" }}>*</span>
                </p>
                <OptionsRenderer
                  optionsList={optionsList}
                  setOptionsList={setOptionsList}
                  optionType={selectedOptionType}
                  correctAnswer={correctAnswer}
                  setCorrectAnswer={setCorrectAnswer}
                />
              </>
            )}
          </div>
        )}{" "}
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: "2rem",
            marginTop: "1rem",
            marginBottom: "3rem",
          }}
        >
          <Button
            className="btn-secondary"
            style={{
              flex: "1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: ".5rem",
              background: "red",
              border: "none",
            }}
            onClick={() => {
              // navigate(`/questionset/${question_set_name}/${question_set_id}`);
              navigate(-1)
            }}
          >
            <BsTrash />
            <span>Cancel</span>
          </Button>
          <Button
            style={{
              flex: "1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: ".5rem",
            }}
            onClick={handleSubmit}
          >
            <FiEdit />
            Update
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ViewQuestions;


