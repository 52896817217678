import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import axiosInstance from "../../utils/axiosInstance"
import handle401Exception from "../../utils/handle401Exception"
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"
import { useGlobalContext } from "../../utils/GlobalContext"
import ExamReviewCard from "../../components/Inhouse/ExamReviewCard"


const DetailReview = () => {
    const { student_id, exam_id, student_name, score } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    const [examDetails, setExamDetails] = useState([])
    const [exam, setExam] = useState({})

    let getExamList = async () => {
        setIsLoading(true)
        await axiosInstance.get('/api/getexamlistinhousestudent')
            .then((response) => {
                setIsLoading(false)
                setExamDetails(response.data.data)
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    handle401Exception(getExamList, () => { })
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getExamList()
    }, [])

    let getDetailReview = async () => {
        setIsLoading(true)
        await axiosInstance.get(`api/getdetailexamreviewinhousestudent?student_id=${student_id}&exam_id=${exam_id}`)
            .then((response) => {
                setIsLoading(false)
                setData(response.data.data)
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    handle401Exception(getDetailReview, () => { })
                }
                setIsLoading(false)
            })
    }

    let getFormattedDate = (mydate) => {
        let newdate = new Date(mydate)
        let year = newdate.getFullYear()
        let month = newdate.getMonth() + 1
        let date = newdate.getDate()
        let formattedDate = year + '-' + month + '-' + date
        return formattedDate
    }


    useEffect(() => {
        getDetailReview()
    }, [])

    useEffect(() => {
        let exam = examDetails.filter(e => { return e.exam_id !== exam_id })
        setExam(exam[0])
    }, [examDetails])
    return (
        <div className="mt-4">
            {/* <h1>Detail Review Page</h1> */}
            {isLoading ? <LoadingSpinner /> : ""}
            <div className="d-flex justify-content-between">
                <div>
                    <h5>Student Name: {student_name}</h5>
                    <h5>Score: {score}</h5>
                </div>
                <div className="text-center">
                    <h5>{exam?.exam_title} [{exam?.exam_code}]</h5>
                    <h5>{exam?.set_title}</h5>
                </div>
                <div>
                    <h5>Exam Date: {getFormattedDate(exam?.exam_date_ad)}</h5>
                    <h5>Exam Time: {exam?.exam_time}</h5>
                </div>
            </div>
            <hr />
            {data.map((d, index) => (
                <ExamReviewCard data={d} index={index} />
            ))}
        </div>
    )
}

export default DetailReview