import { useQuery } from "react-query";
import { useGlobalContext } from "../utils/GlobalContext";
import axiosInstance from "../utils/axiosInstance";
import handle401Exception from "../utils/handle401Exception";


/*
@args id: questionSetId, because we are fetching the questions from questionsets
@args API_URL: url of the api: this hook is used in places like QuestionSet's question, ExamSet's question
@args queryKey: will be an array: ['get-questions', id] => if somehow id changes query will be
refetched by react query, or at the time of query invalidation: queryClient.invalidateQueries({ queryKey }) => this tells react query that we need to invalidate stored result and refetch the api for new data

*/
export const useFetchQuestions = (id, API_URL, queryKey) => {
  const { questionArray, setQuestionArray } = useGlobalContext();
  const queryFunction = async () => {
    return await axiosInstance.get(API_URL);
  };
  const performSideEffects = (response) => {
    setQuestionArray(JSON.parse(JSON.stringify(response?.data?.data || {})));
  };
  const { isLoading, isFetching } = useQuery(queryKey, queryFunction, {
    onSuccess: (response) => {
      if (response.status === 200) {
        performSideEffects(response);
      }
    },
    refetchOnWindowFocus: false,
    enabled: id !== null,
    retry: false,

    onError: (error) => {
      if (error?.response?.status === 401) {
        handle401Exception(queryFunction, performSideEffects);
      }
    },
  });
  let questionLength =
    questionArray[0]?.readingQuestions.length +
    questionArray[0]?.listeningQuestions.length;
  let length = questionArray ? questionLength : undefined;
  return { questionArray, setQuestionArray, isLoading, isFetching, length };
};
