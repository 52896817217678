import { useEffect } from "react"
import { Link } from "react-router-dom"
import { useGlobalContext } from "../../utils/GlobalContext"


const ReviewExamResultTable = (props) => {
    const { data, examId } = props

    return (
        <div className="mt-4">
            <h3>Students Score Sheet</h3>
            <hr />
            <table className="table">
                <thead>
                    <tr>
                        <th>S.N</th>
                        <th>Student Id</th>
                        <th>Student Name</th>
                        <th>Score</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.stu_id}</td>
                                <td>{item.name}</td>
                                <td>{item.score}</td>
                                <td>
                                    <Link
                                        to={`/detail-review-inhouse-student/${item.stu_id}/${examId}/${item.name}/${item.score}`}
                                    >
                                        <span>View Details</span>
                                    </Link>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ReviewExamResultTable