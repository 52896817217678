import axios from "axios";
import { toast } from "react-hot-toast";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(
  localStorage.getItem("access_token")
)}`;

// new instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(
  localStorage.getItem("access_token")
)}`;

// req interceptor
axiosInstance.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${JSON.parse(
    localStorage.getItem("access_token")
  )}`;
  return request;
});

// res interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 201) {
      toast.success(response.data.message);
    }
    // Any status code from range of 2xx
    // Do something with response data
    return response;
  },
  (error) => {
    if (error.response.status === 400 || error.response.status >= 402) {
      toast.error(error.response.data.message);
    }
    // const originalConfig = error.config;

    // if (error.response) {
    //   if (
    //     error.response.status === 401 &&
    //     !originalConfig._retry &&
    //     originalConfig.url !== "/api/admin/refresh"
    //   ) {
    //     originalConfig._retry = true;

    //     // get token from ls
    //     let refresh_token;
    //     if (localStorage.getItem("refresh_token")) {
    //       refresh_token = JSON.parse(localStorage.getItem("refresh_token"));
    //     }
    //     axiosInstance
    //       .post("/api/admin/refresh", { refresh_token })
    //       .then(({ data }) => {
    //         localStorage.setItem(
    //           "access_token",
    //           JSON.stringify(data["access_token"])
    //         );
    //         localStorage.setItem(
    //           "refresh_token",
    //           JSON.stringify(data["refresh_token"])
    //         );
    //         originalConfig.headers[
    //           "Authorization"
    //         ] = `Bearer ${data["access_token"]}`;

    //         if (originalConfig.url === "/api/admin/logout") {
    //           originalConfig.data = JSON.stringify({
    //             refresh_token: `${data["refresh_token"]}`,
    //           });
    //         }
    //         return axiosInstance(originalConfig);
    //       });
    //   }
    // }
    return Promise.reject(error);
  }
);
export default axiosInstance;
