import { Modal, Form, Button } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { queryClient } from "../../../index";
import { useContext } from "react";
import { GlobalContext, useGlobalContext } from "../../../utils/GlobalContext";
import axiosInstance from "../../../utils/axiosInstance";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import handle401Exception from "../../../utils/handle401Exception";
import { IoMdCreate } from "react-icons/io";
import { AiOutlineClear, AiOutlineCloseCircle } from "react-icons/ai";
import { useFetchAvailableExamSets } from "../../Inhouse/CreateLiveExamForm";
import { format } from "date-fns";
const EditLiveExamModal = ({
  exam_code,
  exam_title,
  exam_date_ad,
  exam_time,
  set_id,
  setEditModal,
  exam_id,
  set_title,
}) => {
  const { setIsAppLoading } = useGlobalContext();
  const [formData, setFormData] = useState({
    exam_code,
    exam_title,
    exam_date_ad,
    exam_time,
    set_id,
    exam_id,
  });

  // on input field change
  const handleOnChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const { availableExamSets } = useFetchAvailableExamSets();
  // query function to add question set
  const queryFunction = async (live_exam_data) => {
    setIsAppLoading(true);
    return await axiosInstance
      .put("/api/updateliveexaminhousestudent", live_exam_data)
      .then((response) => {
        if (response.status === 201) {
          performSideEffects(response);
        }
      });
  };

  const performSideEffects = () => {
    queryClient.invalidateQueries({
      queryKey: ["get-available-exam-sets"],
    });
    queryClient.invalidateQueries({
      queryKey: ["get-live-exams"],
    });
    setFormData({
      exam_code: "",
      exam_date_ad: "",
      exam_time: "",
      exam_title: "",
      set_id: "",
    });
    setIsAppLoading(false);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: queryFunction,
    onSuccess: (response) => {},
    onError: (error) => {
      if (error?.response?.status === 401) {
        handle401Exception(
          () =>
            queryFunction({
              ...formData,
              exam_date_ad: format(new Date(formData.exam_date_ad), 'yyyy-MM-dd')
            }),
          performSideEffects
        );
        setIsAppLoading(false);
      } else {
        setIsAppLoading(false);
      }
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.exam_title === "") {
      toast.error("Please enter title of exam.");
      return;
    }
    if (formData.exam_code === "") {
      toast.error("Please enter exam code.");
      return;
    }
    if (formData.exam_date_ad === "") {
      toast.error("Please enter date of exam.");
      return;
    }
    if (formData.set_id === "") {
      formData.set_id = availableExamSets?.[0]?.set_id;
    }

    mutate({
      ...formData,
      exam_date_ad: format(new Date(formData.exam_date_ad), 'yyyy-MM-dd')
    });
  };

  const handleClose = () => {
    setEditModal({
      exam_id: null,
      exam_code: "",
      exam_title: "",
      exam_date_ad: "",
      exam_time: "",
      set_id: "",
    });
  };

  return (
    <>
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label className="form-label">
                Exam code
                <span
                  style={{
                    color: "red",
                    paddingLeft: ".25rem",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>{" "}
              </label>
              <input
                value={formData.exam_code}
                onChange={handleOnChange}
                name="exam_code"
                className="form-control shadow-sm"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                Exam Title
                <span
                  style={{
                    color: "red",
                    paddingLeft: ".25rem",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>{" "}
              </label>
              <input
                value={formData.exam_title}
                onChange={handleOnChange}
                name="exam_title"
                className="form-control shadow-sm"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                Exam Date
                <span
                  style={{
                    color: "red",
                    paddingLeft: ".25rem",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>{" "}
              </label>
              <input
                type="date"
                value={format(new Date(formData.exam_date_ad), 'yyyy-MM-dd')}
                onChange={handleOnChange}
                name="exam_date_ad"
                className="form-control shadow-sm"
              />  
            </div>
            <div className="mb-3">
              <label className="form-label">
                Exam Time
                <span
                  style={{
                    color: "red",
                    paddingLeft: ".25rem",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>{" "}
              </label>
              <input
                step="2"
                value={formData.exam_time}
                onChange={handleOnChange}
                name="exam_time"
                type="time"
                set="2"
                className="form-control shadow-sm"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                Exam Set
                <span
                  style={{
                    color: "red",
                    paddingLeft: ".25rem",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>{" "}
              </label>
              {availableExamSets && (
                <select
                  name="set_id"
                  className="form-select"
                  onChange={handleOnChange}
                >
                  <option selected value={set_id}>
                    {set_title}
                  </option>
                  {availableExamSets.map((exam_set, index) => (
                    <option value={exam_set.set_id}>
                      {exam_set.set_title}: ({exam_set.set_id})
                    </option>
                  ))}
                </select>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{
              display: "flex",
              gap: ".5rem",
              alignItems: "center",
              background: "#EE4B2B",
              border: "none",
            }}
          >
            <AiOutlineCloseCircle />

            <span className="ml-3">Close</span>
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            <IoMdCreate />
            <span> Save Changes</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditLiveExamModal;
