import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import Login from "./pages/Auth/Login/Login";
import SharedAreaLayout from "./components/SharedAreaLayout/SharedAreaLayout";
import Home from "./pages/Home/Home";
import QuestionSet from "./pages/QuestionSet/QuestionSet";
import Question from "./pages/Question/Question";
import PackageSet from "./pages/PackageSet/PackageSet";
import PackageOnly from "./pages/Package/Package";
import { useEffect } from "react";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import ViewQuestion from "./pages/Question/ViewQuestion";
import Books from "./pages/Books/Books";
import AudioBooks from "./pages/Books/AudioBooks";
import RegisterInhouse from "./pages/Inhouse/RegisterInhouse";
import InhouseExamSet from "./pages/Inhouse/InhouseExamSet";
import InhouseQuestion from "./pages/Inhouse/InhouseQuestion";
import InhouseLiveExam from "./pages/Inhouse/InhouseLiveExam";
import InhouseEditQuestion from "./pages/Inhouse/InhouseEditQuestion";
import ReviewExam from "./pages/Inhouse/ReviewExam";
import DetailReview from "./pages/Inhouse/DetailReview";
import AccessControl from "./pages/Inhouse/AccessControl";
import AccessControlExamSet from "./pages/Inhouse/AccessControlExamSet";
import UploadChapter from "./components/Books/UploadChapters";
import ChapterFileList from "./components/Books/ViewChapter";

// use axiosinstance from utils/axiosinstance to make a axios request
function App() {
  const location = useLocation();

  useEffect(() => {
    // for progress bar
    NProgress.start();
    NProgress.configure({ easing: "ease", speed: 500 });
    NProgress.done();
  }, [location.pathname]);
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<SharedAreaLayout />}>
        <Route index element={<Home />} />
        <Route exact path="/questionset" element={<QuestionSet />} />
        <Route
          path="/questionset/:question_set_name/:id"
          element={<Question />}
        ></Route>
        <Route
          exact
          path="/questionset/:question_set_name/:question_set_id/view/:question_id"
          element={<ViewQuestion />}
        />
        <Route exact path="/packageset" element={<PackageSet />} />
        <Route
          exact
          path="/packageset/:package_name/:id"
          element={<PackageOnly />}
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/books" element={<Books />} />
        <Route exact path="/audiobooks" element={<AudioBooks />} />
        <Route exact path="/register-inhouse" element={<RegisterInhouse />} />
        <Route exact path="/inhouse-examset" element={<InhouseExamSet />} />
        <Route
          exact
          path="/inhouse-examset/:set_title/:id"
          element={<InhouseQuestion />}
        />
        <Route
          exact
          path="/inhousequestion/:question_set_name/:question_set_id/view/:question_id"
          element={<InhouseEditQuestion />}
        />
        <Route exact path="/inhouse-live-exam" element={<InhouseLiveExam />} />
        <Route exact path="/review-exam-inhouse-student" element={<ReviewExam />} />
        <Route
          exact
          path="/detail-review-inhouse-student/:student_id/:exam_id/:student_name/:score"
          element={<DetailReview />}
        />
        <Route exact path="/access-control" element={<AccessControl />} />
        <Route exact path="/access-control-exam-set" element={<AccessControlExamSet />} />
        <Route exact path="/uploadChapter" element={<UploadChapter />} />
        <Route exact path="/view_file" element={<ChapterFileList />} />
      </Route>
    </Routes>
  );
}

export default App;
