import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { AiFillFolderOpen } from "react-icons/ai";
import { useState } from "react";
import { useMutation } from "react-query";
import axiosInstance from "../../utils/axiosInstance";
import { queryClient } from "../..";
import handle401Exception from "../../utils/handle401Exception";
import EditBookList from "../Modal/Books/EditBooksModal";

const BooksListTable = ({ booksArray }) => {
  console.log(booksArray);
  const tableHeading = [
    "S.N",
    "Id",
    "Name",
    // "status",
    "Actions",
  ];

  const [editModal, setEditModal] = useState({
    name: "",
    status:"1",
    book_id: "",
  });

  const handleOpenEditModal = (user_data) => {
    setEditModal((prev) => ({
      ...user_data,
    }));
  };

  return (
    <>
      {editModal.book_id !== "" && (
        <EditBookList {...editModal} setEditModal={setEditModal} />
      )}
      <Table
        responsive
        hover
      >
        <thead>
          <tr>
            {tableHeading.map((heading, index) => {
              return <th key={index}>{heading}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {booksArray.map((student, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{student.book_id}</td>
              <td>{student.book_name}</td>
              {/* <td>{student.status}</td> */}
              <td className="d-flex gap-4">

                <span
                  className="d-flex gap-2 align-items-center"
                  onClick={() => handleOpenEditModal(student)}
                >
                  <BiEditAlt color="green" />
                  <span
                    style={{
                      color: "green",
                      cursor: "pointer",
                    }}
                  >
                    Edit
                  </span>{" "}
                </span>

                <StatusChanger {...student} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default BooksListTable;

const StatusChanger = ({ book_id }) => {
  return (
    <span
      onClick={() => {
        // Navigate to the desired URL when clicked
        window.location.href = `/uploadChapter?book_id=${book_id}`;
      }}
      style={{
        cursor: "pointer",
        textDecoration: "underline",
        color: "blue",
      }}
    >
      Add Chapter
    </span>
  );
};
