import { Form } from "react-bootstrap";

const SelectCorrectAnswer = ({ correctAnswer, setCorrectAnswer }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <p
          style={{
            // width: "30%",
            marginTop: ".85rem",
          }}
        >
          Correct Answer
        </p>
  
        <Form.Select
          defaultValue={correctAnswer}
          aria-label="Default select example"
          onChange={(e) => {
            setCorrectAnswer(e.target.value);
          }}
          style={{
            width: "20rem",
          }}
        >
          <option value="option1">Option 1</option>
          <option value="option2">Option 2</option>
          <option value="option3">Option 3</option>
          <option value="option4">Option 4</option>
        </Form.Select>
      </div>
    );
  };
export default SelectCorrectAnswer  