import React, { useEffect, useState, useRef } from "react";
import { useMutation } from "react-query";
import axiosInstance from "../../../utils/axiosInstance";
import { IoExpandOutline } from "react-icons/io5";
import { toast } from "react-hot-toast";
import handle401Exception from "../../../utils/handle401Exception";
import { queryClient } from "../../..";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { IoMdCreate } from "react-icons/io";

const QuestionSetForm = () => {
  const [totalTime, setTotalTime] = useState("");

  const timeRef = useRef();
  const [formData, setFormData] = useState({
    name: "",
    price: 0,
    expiry_duration: {
      h: 0,
      m: 0,
    },
  });
  // query function to add question set
  const queryFunction = async (questionSet) => {
    await axiosInstance
      .post("/api/questionset", questionSet)
      .then((response) => {
        if (response.status === 201) {
          performSideEffects(response);
        }
      });
  };

  const performSideEffects = () => {
    queryClient.invalidateQueries({
      queryKey: ["get-question-sets"],
    });
    queryClient.invalidateQueries({
      queryKey: ["get-all-question-sets-without-pagination"],
    });
    setFormData((prev) => ({
      ...prev,
      name: "",
      price: 0,
      expiry_duration: {
        h: 0,
        m: 0,
      },
    }));
    setTotalTime("");
    timeRef.current.value = 0;
  };
  //
  const { mutate, isLoading } = useMutation({
    mutationFn: queryFunction,
    onSuccess: (response) => {},
    onError: (error) => {
      if (error?.response?.status === 401) {
        // (3) ['', '40', 'minutes']
        // (4) ['2', 'hours', '3', 'minutes']0: "2"1: "hours"2: "3"3: "minutes"length: 4[[Prototype]]: Array(0)
        let expiry_duration;
        const timeArray = totalTime.split(" ");
        if (timeArray.length === 3) {
          expiry_duration = `0h${timeArray[1]}m`;
        }
        if (timeArray.length === 4) {
          expiry_duration = `${timeArray[0]}h${timeArray[2]}m`;
        }

        const data = {
          ...formData,
          expiry_duration: expiry_duration,
        };
        handle401Exception(() => queryFunction(data), performSideEffects);
      }
    },
  });

  const handleSubmit = () => {
    if (formData.name === "") {
      toast.error("Please enter the name of question set.");
      return;
    }

    if (totalTime === "" || !totalTime || totalTime === null) {
      toast.error("Expiry Duration is required.");
      return;
    }

    // (3) ['', '40', 'minutes']
    // (4) ['2', 'hours', '3', 'minutes']0: "2"1: "hours"2: "3"3: "minutes"length: 4[[Prototype]]: Array(0)
    let expiry_duration;
    const timeArray = totalTime.split(" ");
    if (timeArray.length === 3) {
      expiry_duration = `0h${timeArray[1]}m`;
    }
    if (timeArray.length === 4) {
      expiry_duration = `${timeArray[0]}h${timeArray[2]}m`;
    }

    if (expiry_duration == "0h0m") {
      toast.error("Exam duration cannot be Zero");
      return;
    }

    const data = {
      ...formData,
      expiry_duration: expiry_duration,
    };
    mutate({ ...data });
  };

  // on input field change
  const handleOnChange = (e) => {
    if (e.target.value <= -1) {
      toast.error("Price cannot be less than zero.");
      return;
    }
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // handle form field change
  const handleExpiryFieldChange = (e) => {
    // setFormData((prev) => ({
    //   ...prev,
    //   expiry_duration: {
    //     ...prev["expiry_duration"],
    //     [e.target.name]: e.target.value,
    //   },
    // }));

    // if (e.target.value !== "") {
    formatTime(e.target.value);
    // }
    // setTotalTime((prev) => )
  };

  // useEffect(() => {
  //   if (formData.expiry_duration.m) {
  //     formatTime(formData.expiry_duration.m);
  //   }
  // }, [formData.expiry_duration.m]);

  function formatTime(minutes) {
    if (!minutes) {
      setTotalTime("0 minute");
      return;
    }
    if (minutes <= -1) {
      setTotalTime("0 minute");
      timeRef.current.value = 1;
      toast.error("Exam duration cannot be negaive");
      return;
    }
    if (minutes <= 60) {
      setTotalTime(` ${minutes} minutes`);
      return;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    setTotalTime(`${hours} hours ${remainingMinutes} minutes`);
    return;
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <form
        className="my-5"
        style={{
          width: "35%",
        }}
      >
        <h1
          style={{
            fontSize: "2rem",
          }}
        >
          Create New Question Set
        </h1>
        <div className="mb-3">
          <label className="form-label">
            Set Name{" "}
            <span
              style={{
                color: "red",
                paddingLeft: ".25rem",
                fontWeight: "bold",
              }}
            >
              *
            </span>{" "}
          </label>
          <input
            value={formData.name}
            onChange={handleOnChange}
            name="name"
            className="form-control shadow-sm"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Price</label>
          <input
            onChange={handleOnChange}
            name="price"
            type="number"
            className="form-control  shadow-sm"
            value={formData.price}
            min={0}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">
            Exam duration
            <span
              style={{
                color: "red",
                paddingLeft: ".25rem",
                fontWeight: "bold",
              }}
            >
              *
            </span>{" "}
          </label>
          <div className="d-flex align-items-center gap-5">
            {/* <div className="d-flex w-25 align-items-center gap-2">
            <input
            max={24}
              min={0}
              onChange={handleExpiryFieldChange}
              name="h"
              type="number"
              className="form-control"
            />
            <p className="form-label" htmlFor="">
              Hour
            </p>
          </div> */}
            <div className="d-flex  gap-2 align-items-center">
              <input
                ref={timeRef}
                min="1"
                onChange={handleExpiryFieldChange}
                name="m"
                type="number"
                className="form-control w-50  shadow-sm"
                // value={formData.expiry_duration.m}
              />
              <p className="form-label d-flex gap-4 " htmlFor="">
                Min
                <span className=" ">{totalTime}</span>
              </p>
            </div>
          </div>
        </div>

        <button
          type="button"
          onClick={handleSubmit}
          className="btn btn-primary"
          style={{
            display: "flex",
            gap: ".5rem",
            alignItems: "center",
          }}
        >
          <IoMdCreate />
          <span> Create Question set</span>
        </button>
      </form>
    </>
  );
};

export default QuestionSetForm;
