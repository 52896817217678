import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoMdCreate } from "react-icons/io";
import { toast} from 'react-hot-toast'
import ImageCropperModal from "../../ImageCropper/ImageCropperModal";

const EditOptionModal = ({
    optionType,
    setOpenEditOptionModal,
    setSelectedOptionsArray,
    openEditOptionModal,
    selectedOptionsArray,
  }) => {
    const handleClose = () => {
      setOpenEditOptionModal(false);
    };
    const [openCropImageModal, setOpenCropImageModal] = useState(null);

    const handleSubmit = () => {
      if (uploadedOption === "" && optionType === "text") {
        toast.error("Text cannot be empty.");
        return;
      }
      if (uploadedOption === "") {
        toast.error("No file selected. Please select a file or cancel");
        return;
      }
  
      const op_nums = extractNumberFromOptions(openEditOptionModal);
      const newArray = [...selectedOptionsArray];
      newArray[op_nums - 1] = {
        [openEditOptionModal]: uploadedOption,
      };
  
      setSelectedOptionsArray(newArray);
  
      handleClose();
    };
  
    const extractNumberFromOptions = (option_name) => {
      return parseInt(option_name.split("")[6]);
    };
    // option
  
    const [uploadedOption, setUploadedOption] = useState("");
    useEffect(() => {
      const getInput = () => {
        if (optionType !== "text") return;
        const getOptionIndex = openEditOptionModal.split("option")[1];
        const value =
          selectedOptionsArray[getOptionIndex - 1]?.[openEditOptionModal];
        setUploadedOption(value);
      };
      getInput();
    }, []);
    const handleUploadFile = (e) => {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      if (e.target.files[0].size > "250000" && optionType === "image") {
        toast.error("Image files cannot be more than 250KB");
        e.target.value = "";
        return;
      }
      if (e.target.files[0].size > "5242880" && optionType === "audio") {
        e.target.value = "";
        toast.error("Audio files cannot be more than 5MB");
        return;
      }
      if(optionType === 'image'){
        setOpenCropImageModal(e.target.files[0])
      }else {
        setUploadedOption(e.target.files[0]);

      }
      // console.log('E.TARGET', e.target.files[0])
    };
  
    return (
      <>
       {openCropImageModal && (
        <ImageCropperModal
          setOpenCropImageModal={setOpenCropImageModal}
          openCropImageModalData={openCropImageModal}
          setSelectedImage={setUploadedOption}
        />
      )}
        <Modal show={true} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Option ({openEditOptionModal}) </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {optionType === "text" && (
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <label className="form-label">Option</label>
                  <input
                    value={uploadedOption}
                    name="name"
                    className="form-control"
                    onChange={(e) => setUploadedOption(e.target.value)}
                  />
                </Form.Group>
              )}
              {optionType === "audio" && (
                <>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Upload Audio </Form.Label>
                    <Form.Control
                      type="file"
                      accept="audio/*"
                      onChange={handleUploadFile}
                    />
                  </Form.Group>
                </>
              )}
              {optionType === "image" && (
                <>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Upload Question Image </Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handleUploadFile}
                    />
                  </Form.Group>
                </>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{
                background: "#EE4B2B",
                border: "none",
                display: "flex",
                gap: ".5rem",
                alignItems: "center",
              }}
              onClick={handleClose}
            >
              <AiOutlineCloseCircle />
  
              <span className="pl-3">Close</span>
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              style={{ display: "flex", gap: ".5rem", alignItems: "center" }}
            >
              <IoMdCreate />
              <span className="pl-3">Save Changes</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  export default EditOptionModal