import { useContext } from "react";
import { GlobalContext } from "../utils/GlobalContext";
import { queryClient } from "..";
import axiosInstance from "../utils/axiosInstance";
import { useMutation } from "react-query";
import { convertToTime } from "../utils/time";
import { toast } from "react-hot-toast";
import handle401Exception from "../utils/handle401Exception";

const useEditQuestionSet = (
  formData,
  totalTime,
  setEditModal,
  question_set_id,
  time_in_minutes
) => {
  const { setIsAppLoading } = useContext(GlobalContext);

  // what will happen after the req is made
  const performSideEffects = () => {
    handleClose();
    queryClient.invalidateQueries({
      queryKey: ["get-question-sets"],
    });
    queryClient.invalidateQueries({
      queryKey: ["get-all-question-sets-without-pagination"],
    });
    setIsAppLoading(false);
  };
  // query function to add question set
  const queryFunction = async (questionSet) => {
    return await axiosInstance
      .put("/api/questionset", questionSet)
      .then((response) => {
        if (response.status === 201) {
          performSideEffects();
        }
      });
  };

  // generate data to send
  const generateData = () => {
    if (formData.name === "") {
      toast.error("Please enter the name of question set.");
      return false;
    }
    if (formData.price === undefined || formData.price === null || formData.price === '') {
      toast.error("Please enter the price of question set");
      return false;
    }
    if (parseInt(time_in_minutes) === 0 || !time_in_minutes || time_in_minutes === '0') {
      toast.error("Exam Duration is required.");
      return false;
    }
    if (parseInt(time_in_minutes) >= 1440) {
      toast.error("Exam Duration cannot be more than 24 hours.");
      return false;
    }
    const exam_duration = convertToTime(parseInt(time_in_minutes));
    const data = {
      ...formData,
      expiry_duration: exam_duration,
    };
    return data;
  };
  // when we close the modal
  const handleClose = () => {
    setEditModal((prev) => ({
      name: "",
      price: null,
      expiry_duration: "",
      status: null,
      question_set_id: null,
    }));
  };

  const { mutateAsync } = useMutation(queryFunction, {
    onError: (error) => {
      if (error?.response?.status === 401) {
        const data = generateData();
        if (data === false) return;
        handle401Exception(
          () => queryFunction({ ...data, question_set_id }),
          performSideEffects
        );
      }
    },
  });

  const handleSubmit = () => {
    const data = generateData();
    if (data === false) return;
    setIsAppLoading(true);
    mutateAsync({ ...data, question_set_id });
  };

  return {
    handleSubmit,
    handleClose,
  };
};

export default useEditQuestionSet;
