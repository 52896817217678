import React, { useEffect, useRef } from "react";
import InhouseStudentsTable from "../Table/InhouseStudentTable";
import handle401Exception from "../../utils/handle401Exception";
import { useQuery } from "react-query";
import { useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import Pagination from "../Pagination/Pagination";
import { BiSearch } from "react-icons/bi";

const InhouseStudents = () => {
  const {
    inhouseStudentArray,
    isFetching,
    isLoading,
    setPaginationSettings,
    handleDecreasePage,
    handleIncreasePage,
    paginationSettings,
    totalPages,
  } = useInhouseStudents();

  return (
    <>
      <>
        {" "}
        <div className="my-4 d-flex justify-content-between align-items-center">
          <h1>Inhouse Students</h1>
          <Search setPaginationSettings={setPaginationSettings} />
        </div>
        {isLoading || isFetching ? (
          <p>Loading...</p>
        ) : (
          <div className="row gap-4">
            {inhouseStudentArray !== undefined &&
            inhouseStudentArray.length !== 0 ? (
              <InhouseStudentsTable inhouseStudentArray={inhouseStudentArray} />
            ) : (
              <p>No Student found</p>
            )}
            <div
              className="container "
              style={{
                textAlign: "center",
                width: "40%",
              }}
            >
              {totalPages !== null && (
                <Pagination
                  currentPage={paginationSettings.currentPage}
                  handleIncreasePage={handleIncreasePage}
                  totalPages={totalPages}
                  handleDecreasePage={handleDecreasePage}
                  setPaginationSettings={setPaginationSettings}
                />
              )}
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default InhouseStudents;

export const useInhouseStudents = () => {
  const [paginationSettings, setPaginationSettings] = useState({
    currentPage: 1,
    limit: 10,
    status: 1,
    name: "",
  });

  const [totalData, setTotalData] = useState(null);

  let queryKey = [
    "get-inhouse-students",
    paginationSettings.currentPage,
    paginationSettings.limit,
    paginationSettings.status,
    paginationSettings.name,
  ];
  const queryFunction = async () =>
    await axiosInstance.get(
      `/api/getinhousestudentpagination?page=${paginationSettings.currentPage}&limit=${paginationSettings.limit}&status=${paginationSettings.status}&name=${paginationSettings.name}`
    );
  const [inhouseStudentArray, setInhouseStudentArray] = useState([]);

  const performSideEffects = (response) => {
    setInhouseStudentArray(
      JSON.parse(JSON.stringify(response?.data?.data || {}))
    );
    setTotalData((prev) => response?.data?.total);
  };
  const { isLoading, isFetching } = useQuery(queryKey, queryFunction, {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      if (response.status === 200) {
        performSideEffects(response);
      }
    },
    onError: (error) => {
      if (error?.response?.status === 401) {
        handle401Exception(queryFunction, performSideEffects);
      }
    },
  });

  // pagination states

  const totalPages = totalData
    ? Math.ceil(totalData / paginationSettings.limit)
    : null;
  const handleIncreasePage = () => {
    if (paginationSettings.currentPage === totalPages) {
      return;
    }
    setPaginationSettings((prev) => ({
      ...prev,
      currentPage: prev["currentPage"] + 1,
    }));
  };
  const handleDecreasePage = () => {
    if (paginationSettings.currentPage === 1) {
      return;
    }
    setPaginationSettings((prev) => ({
      ...prev,
      currentPage: prev["currentPage"] - 1,
    }));
  };
  return {
    handleDecreasePage,
    handleIncreasePage,
    paginationSettings,
    totalData,
    inhouseStudentArray,
    setInhouseStudentArray,
    isFetching,
    isLoading,
    setPaginationSettings,
    totalPages,
  };
};
export const Search = ({ setPaginationSettings, pageUsed }) => {
  const statusRef = useRef();

  const [inputSearchText, setInputSearchText] = useState('')

  const handleSearch = () => {
    let status = statusRef.current.value;
    console.log("CLICKED HERE.");

    console.log('REF...', inputSearchText)

    setPaginationSettings((prev) => ({
      ...prev,
      limit: 10,
      status: status === "Inactive" ? 0 : 1,
      name: inputSearchText,
    }));
  };
  return (
    <div className="d-flex align-items-center gap-2  ">
      <input
        type="text"
        className="form-control"
        id="exampleFormControlInput1"
        placeholder={"Name"}
        style={{
          minWidth: "200px",
        }}
        value={inputSearchText}
        onChange={(e) => setInputSearchText(e.target.value)}
      />
      <select
        className="form-select"
        aria-label="Default select example"
        ref={statusRef}
      >
        <option selected value="Active">
          Active
        </option>
        <option value="Inactive">Inactive</option>
      </select>
      <button
        type="button"
        className="btn btn-primary"
        style={{
          minWidth: "7rem",
          display: "flex",
          alignItems: "center",
          gap: ".25rem",
        }}
        onClick={handleSearch}
      >
        <BiSearch />
        Search 
      </button>
    </div>
  );
};
