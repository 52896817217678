import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import ViewQuestion from "../../components/ViewQuestions/ViewQuestions";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import useFetchOneQuestion from "../../customHooks/useFetchOneQuestion";

const InhouseEditQuestion = () => {
  const { question_set_id, question_id, question_set_name } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!question_id && !question_set_id) {
      navigate(-1);
    }
    if (!question_id) {
      navigate(-1);
    }
  }, []);
  const { isLoading, questionData } = useFetchOneQuestion(
    question_set_id,
    question_id,
    `/api/getquestioninhousestudent/${question_set_id}`,
    ["get-inhouse-exam-questions", question_id]
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const API_URL = `/api/updatequestioninhousestudent`
  const onSuccessLink = `/inhouse-examset/${question_set_name}/${question_set_id}`

  return (
    <>
      <div
        // className="my-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <h2 className="my-4">Edit your question</h2>
      </div>
      {questionData?.question_id && <ViewQuestion queryKey={["get-inhouse-exam-questions", String(question_set_id)]} API_URL={API_URL} onSuccessLink={onSuccessLink} {...questionData} />}
    </>
  );
};
// { queryKey: ["get-questions"] }
export default InhouseEditQuestion;
