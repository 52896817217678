import { Link } from "react-router-dom";

const SidebarElements = ({ name, link, icon }) => {
  return (
    <Link
      className="py-1 px-3 "
      to={link}
      style={{
        textDecoration: "none",
        color: "white",
        display: "flex",
        cursor: "pointer",
        gap: "1rem",
        alignItems: "items-center",
      }}
    >
      <p>{icon}</p>
      <p className="pt-1"> {name}</p>
    </Link>
  );
};
export default SidebarElements;
