import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { AiFillFolderOpen } from "react-icons/ai";
import { useState } from "react";
import EditQuestionSet from "../Modal/QuestionSet/EditQuestionSet";
import DeleteModal from "../Modal/QuestionSet/DeleteQuestionSet";
import { MdOutlineRestore } from "react-icons/md";
import RestoreModal from "../Modal/QuestionSet/RestoreModal";


/*
@args: pageUsed is the page in which the component is used
*/
const QuestionSetTable = ({ questionSetArray, pageUsed }) => {
  const tableHeading = [
    "S.N",
    "Name",
    "Price",
    "Exam Duration",
    "Status",
    "Actions",
  ];

  const [editModal, setEditModal] = useState({
    name: "",
    price: null,
    expiry_duration: "",
    status: null,
    question_set_id: null,
  });

  const [deleteModal, setDeleteModal] = useState({
    name: "",
    price: null,
    expiry_duration: "",
    status: null,
    question_set_id: null,
  });

  const handleOpenEditModal = (question_set_data) => {
    setEditModal((prev) => ({
      ...question_set_data,
    }));
  };

  const handleOpenDeleteModal = (question_set_data) => {
    setDeleteModal((prev) => ({
      ...question_set_data,
    }));
  };

  // restore modal
  const [restoreModal, setRestoreModal] = useState({
    name: "",
    price: null,
    expiry_duration: "",
    status: null,
    question_set_id: null,
  });

  const handleOpenRestoreModal = (question_set_data) => {
    setRestoreModal((prev) => ({
      ...question_set_data,
    }));
  };

  return (
    <>
      {editModal.question_set_id !== null && (
        <EditQuestionSet
          {...editModal}
          editModal={editModal}
          setEditModal={setEditModal}
        />
      )}
      {deleteModal.question_set_id !== null && (
        <DeleteModal
          {...deleteModal}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
        />
      )}
      {restoreModal.question_set_id !== null && (
        <RestoreModal
          {...restoreModal}
          restoreModal={restoreModal}
          setRestoreModal={setRestoreModal}
        />
      )}
      <Table responsive hover>
        <thead>
          <tr>
            {tableHeading.map((heading, index) => (
              <th key={index}>{heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {questionSetArray.map((question_set, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{question_set.name}</td>
              <td>
                {question_set.price === 0 ? (
                  <span>
                    0{" "}
                    <span
                      style={{
                        paddingInline: ".75rem",
                        borderRadius: "8px",
                        background: "#E4F6DF",
                        cursor: "pointer",
                        fontSize: ".9rem",
                        color: "#629D5B",
                      }}
                    >
                      Free
                    </span>
                  </span>
                ) : (
                  question_set.price
                )}
              </td>
              <td>{question_set.expiry_duration}</td>
              <td>
                {pageUsed === "PackageSet" || question_set.status === 1 ? (
                  <span
                    style={{
                      paddingInline: ".8rem",
                      paddingBlock: ".25rem",
                      borderRadius: "8px",
                      background: "#FEF4DB",
                      cursor: "pointer",
                      fontSize: ".9rem",
                      color: "#988539",
                    }}
                  >
                    Active
                  </span>
                ) : (
                  <span
                    style={{
                      paddingInline: ".8rem",
                      paddingBlock: ".35rem",
                      borderRadius: "12px",
                      background: "#EAF9FE",
                      cursor: "pointer",
                      fontSize: ".9rem",
                      color: "#6B93A1",
                    }}
                  >
                    Inactive
                  </span>
                )}
              </td>

              <td className="d-flex gap-3">
                <>
                  {pageUsed !== "PackageSet" && (
                    <>
                      {" "}
                      {question_set.status === 1 && (
                        <span
                          className="d-flex gap-2 align-items-center"
                          onClick={() => handleOpenEditModal(question_set)}
                        >
                          <BiEditAlt color="green" />
                          <span
                            style={{
                              color: "green",
                              cursor: "pointer",
                            }}
                          >
                            Edit
                          </span>{" "}
                        </span>
                      )}
                      {question_set.status === 1 ? (
                        <span
                          className="d-flex gap-2 align-items-center"
                          onClick={() => handleOpenDeleteModal(question_set)}
                        >
                          <AiFillDelete color="red" />
                          <span
                            style={{
                              color: "red",
                              cursor: "pointer",
                            }}
                          >
                            Delete
                          </span>{" "}
                        </span>
                      ) : (
                        <span
                          className="d-flex gap-2 align-items-center pr-4"
                          onClick={() => handleOpenRestoreModal(question_set)}
                        >
                          <MdOutlineRestore color="green" />
                          <span
                            style={{
                              color: "red",
                              cursor: "pointer",
                            }}
                          >
                            Restore
                          </span>
                        </span>
                      )}
                    </>
                  )}
                </>

                {(pageUsed === "PackageSet" || question_set.status === 1) && (
                  <Link
                    to={`/questionset/${encodeURIComponent(
                      question_set.name
                    )}/${question_set["question_set_id"]}`}
                  >
                    <span className="d-flex gap-2 align-items-center">
                      <AiFillFolderOpen />{" "}
                      <span
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                      >
                        View
                      </span>{" "}
                    </span>
                  </Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default QuestionSetTable;
