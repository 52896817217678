import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { AiFillFolderOpen } from "react-icons/ai";
import { useState } from "react";
import DeleteModal from "../Modal/PackageSet/DeletePackageSet";
import { MdOutlineRestore } from "react-icons/md";
import RestoreModal from "../Modal/PackageSet/RestorePackageSet";
import EditPackageSet from "../Modal/PackageSet/EditPackageSet";

const PackageSetTable = ({ packageSetArray }) => {
  const tableHeading = ["S.N", "Name", "Price", "Status", "Actions"];

  const [editModal, setEditModal] = useState({
    package_name: "",
    price: null,
    status: null,
    package_id: null,
  });

  const [deleteModal, setDeleteModal] = useState({
    package_name: "",
    price: null,
    status: null,
    package_id: null,
  });

  const handleOpenEditModal = (package_set_data) => {
    setEditModal((prev) => ({
      ...package_set_data,
    }));
  };

  const handleOpenDeleteModal = (package_set_data) => {
    setDeleteModal((prev) => ({
      ...package_set_data,
    }));
  };

  // restore modal
  const [restoreModal, setRestoreModal] = useState({
    package_name: "",
    price: null,
    expiry_duration: "",
    status: null,
    package_id: null,
  });

  const handleOpenRestoreModal = (package_set_data) => {
    setRestoreModal((prev) => ({
      ...package_set_data,
    }));
  };

  return (
    <>
      {editModal.package_id !== null && (
        <EditPackageSet
          {...editModal}
          editModal={editModal}
          setEditModal={setEditModal}
        />
      )}
      {deleteModal.package_id !== null && (
        <DeleteModal
          {...deleteModal}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
        />
      )}
      {restoreModal.package_id !== null && (
        <RestoreModal
          {...restoreModal}
          restoreModal={restoreModal}
          setRestoreModal={setRestoreModal}
        />
      )}
      {packageSetArray.length === 0 ? (
        <p>No package set available</p>
      ) : (
        <Table responsive hover>
          <thead>
            <tr>
              {tableHeading.map((heading, index) => (
                <th key={index}>{heading}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {packageSetArray.map((package_set, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{package_set.package_name}</td>
                <td>
                  {package_set.price === 0 ? (
                    <span>
                      0{" "}
                      <span
                        style={{
                          paddingInline: ".75rem",
                          // paddingBlock: ".2rem",
                          borderRadius: "8px",
                          background: "#E4F6DF",
                          cursor: "pointer",
                          fontSize: ".9rem",
                          color: "#629D5B",
                        }}
                      >
                        Free
                      </span>
                    </span>
                  ) : (
                    package_set.price
                  )}
                </td>

                <td>
                  {package_set.status === 1 ? (
                    <span
                      style={{
                        paddingInline: ".8rem",
                        paddingBlock: ".25rem",
                        borderRadius: "8px",
                        background: "#FEF4DB",
                        cursor: "pointer",
                        fontSize: ".9rem",
                        color: "#988539",
                      }}
                    >
                      Active
                    </span>
                  ) : (
                    <span
                      style={{
                        // border: "1px solid blue",
                        paddingInline: ".8rem",
                        paddingBlock: ".35rem",
                        borderRadius: "12px",
                        background: "#EAF9FE",
                        cursor: "pointer",
                        fontSize: ".9rem",
                        color: "#6B93A1",
                      }}
                    >
                      Inactive
                    </span>
                  )}
                  {/* <span class="badge bg-primary ">
                  {question_set.status === 1 ? "Active" : "Inactive"}
                </span> */}
                </td>
                <td className="d-flex gap-3">
                  {package_set.status === 1 && (
                    <span
                      className="d-flex gap-2 align-items-center"
                      onClick={() => handleOpenEditModal(package_set)}
                    >
                      <BiEditAlt color="green" />
                      <span
                        style={{
                          color: "green",
                          cursor: "pointer",
                        }}
                      >
                        Edit
                      </span>{" "}
                    </span>
                  )}
                  {package_set.status === 1 ? (
                    <span
                      className="d-flex gap-2 align-items-center"
                      onClick={() => handleOpenDeleteModal(package_set)}
                    >
                      <AiFillDelete color="red" />
                      <span
                        style={{
                          color: "red",
                          cursor: "pointer",
                        }}
                      >
                        Delete
                      </span>{" "}
                    </span>
                  ) : (
                    <span
                      className="d-flex gap-2 align-items-center pr-4"
                      onClick={() => handleOpenRestoreModal(package_set)}
                    >
                      <MdOutlineRestore color="green" />
                      <span
                        style={{
                          color: "red",
                          cursor: "pointer",
                        }}
                      >
                        Restore
                      </span>
                    </span>
                  )}

                  {package_set.status === 1 && (
                    <Link to={`/packageset/${encodeURIComponent(package_set.package_name)}/${package_set["package_id"]}`}>
                      <span className="d-flex gap-2 align-items-center">
                        <AiFillFolderOpen />{" "}
                        <span
                          style={{
                            color: "blue",
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                        >
                          View
                        </span>{" "}
                      </span>
                    </Link>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default PackageSetTable;

// {
//   "package_id": 1,
//   "package_name": "First package",
//   "question_set_id": "[11,12]",
//   "price": 90,
//   "status": 1
// }
