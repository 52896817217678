import React, { useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-hot-toast";
import handle401Exception from "../../utils/handle401Exception";
import axiosInstance from "../../utils/axiosInstance";
import { useGlobalContext } from "../../utils/GlobalContext";
import { queryClient } from "../../index";
import { BsWindowSidebar } from "react-icons/bs";

const UploadBooks = () => {
  const {
    book,
    handleSubmit,
    handleBookNameChange,
    setBook,
    handleStatusChange
  } = useUploadBook();

  return (
    <section
      style={{
        width: "50%",
        marginTop: "1rem",
      }}
    >
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <label className="form-label">Book name</label>
          <input
            onChange={handleBookNameChange}
            type="text"
            name="book_name"
            className="form-control"
            value={book.book_name}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1">
          <label className="form-label">Status</label>
          <select
            onChange={handleStatusChange}
            name="status"
            className="form-select"
            value={book.status}
          >
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
        </Form.Group>
        <div
          className="d-flex"
          style={{
            gap: "1rem",
          }}
        >
          <Button type="reset" onClick={handleSubmit} className="my-3 px-3">
            Save
          </Button>
          <Button onClick={() => {
            setBook({
              book_name: "",
              status: "1"
            })
          }} className="my-3 px-3 btn-danger">
            Cancel
          </Button>
        </div>
      </Form>
    </section >
  );
};

export default UploadBooks;

const useUploadBook = () => {
  const { setIsAppLoading } = useGlobalContext();
  const [book, setBook] = useState({
    book_name: "",
    status: "1",
  });

  const API = "/api/savebookinfo";

  const handleBookNameChange = (e) => {
    setBook((prev) => ({ ...prev, book_name: e.target.value }));
  };
  const handleStatusChange = (e) => { setBook((prev) => ({ ...prev, status: e.target.value })) };
  // query function
  const queryFunction = async (formData) => {
    return await axiosInstance.post(API, formData, {

    });
  };

  const generateFormData = () => {
    let newFormData = new FormData();
    newFormData.append("book_name", book.book_name);
    newFormData.append("status", book.status)
    return newFormData;
  };

  const performSideEffects = (response) => {
    setIsAppLoading(false);
    setBook({
      book_name: "",
      status: "1"
    });
    queryClient.invalidateQueries({ queryKey: ["get-book-list"] });
    window.location.reload();
  };

  const handleSubmit = () => {
    if (!book.book_name) {
      toast.error("Please enter the name of the book.");
      return;
    }
    if (!book.status) {
      toast.error("Please select the status of the book.");
      return;
    }
    setIsAppLoading(true);
    const formData = generateFormData();
    queryFunction(formData)
      .then((response) => {
        performSideEffects(response);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          handle401Exception(() => queryFunction(formData), performSideEffects);
        } else {
          setIsAppLoading(false);
        }
      });
  };
  return {
    book,
    setBook,
    handleSubmit,
    handleBookNameChange,
    handleStatusChange
  };
};
