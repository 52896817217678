import React, { useEffect, useRef, useState } from "react"
import axiosInstance from "../../utils/axiosInstance"
import { toast } from "react-hot-toast"
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"
import handle401Exception from "../../utils/handle401Exception"
import ReviewExamResultTable from "../../components/Inhouse/ReviewExamResultTable"

const ReviewExam = () => {
    const [comboData, setComboData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [examId, setExamId] = useState('')
    const [data, setData] = useState([])

    let getExamList = async () => {
        setIsLoading(true)
        await axiosInstance.get('/api/getexamlistinhousestudent')
            .then((response) => {
                setIsLoading(false)
                setComboData(response.data.data)
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    handle401Exception(getExamList, () => { })
                }
                setIsLoading(false)
            })
    }

    let getExamReview = async () => {
        if (examId == "" || examId == null) {
            toast.error('Please select an exam')
            return
        }
        setIsLoading(true)
        await axiosInstance.get(`/api/getexamreviewinhousestudent/${examId}`)
            .then((response) => {
                setIsLoading(false)
                if(response.data?.data.length==0){
                    toast.error('Data not found !!!')
                }
                setData(response.data.data)
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    handle401Exception(getExamReview, () => { })
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getExamList()
    }, [])


    let handleChange = (e) => {
        setExamId(e.target.value)
    }

    return (
        <div>
            <h1 className="mt-3">Review Internal Exam Result</h1>
            {isLoading ? <LoadingSpinner /> : ""}

            <div style={{ marginTop: "3rem" }}>
                <label className="form-label">
                    Select The Exam
                    <span
                        style={{
                            color: "red",
                            paddingLeft: ".25rem",
                            fontWeight: "bold",
                        }}
                    >
                        *
                    </span>{" "}
                </label>
                
                    <select
                        name="set_id"
                        className="form-select"
                        onChange={handleChange}
                        style={{ width: "25rem" }}
                    >
                        <option value="" hidden>

                        </option>
                        {comboData.map((exam, index) => (
                            <option
                                value={exam.exam_id}
                                key={index}
                            >
                                {exam.exam_title}  [{exam.exam_code}]
                            </option>
                        ))}
                    </select>
                
            </div>
            <div>
                <button onClick={getExamReview} className="btn btn-primary mt-3">Review Result</button>
            </div>
            <div className="mt-3">
                {data.length > 0 ? <ReviewExamResultTable data={data} examId={examId} /> : ""}
            </div>
        </div>
    )
}

export default ReviewExam