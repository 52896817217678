import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { AiFillFolderOpen } from "react-icons/ai";
import { useState } from "react";
import EditInhouseStudent from "../Modal/Inhouse/EditInhouseStudent";
import { useMutation } from "react-query";
import axiosInstance from "../../utils/axiosInstance";
import { queryClient } from "../..";
import handle401Exception from "../../utils/handle401Exception";

const InhouseStudentsTable = ({ inhouseStudentArray }) => {
  const tableHeading = [
    "S.N",
    "Student Id",
    "Name",
    "Email",
    "Address",
    "Contact",
    "Actions",
  ];

  const [editModal, setEditModal] = useState({
    name: "",
    email: "",
    address: "",
    contact: null,
    student_id: null,
  });

  const handleOpenEditModal = (user_data) => {
    setEditModal((prev) => ({
      ...user_data,
    }));
  };

  return (
    <>
      {editModal.student_id !== null && (
        <EditInhouseStudent {...editModal} setEditModal={setEditModal} />
      )}
      <Table
        responsive
        hover
      >
        <thead>
          <tr>
            {tableHeading.map((heading, index) => {
              return <th key={index}>{heading}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {inhouseStudentArray.map((student, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{student.student_id}</td>
              <td>{student.name}</td>
              <td>{student.email}</td>
              <td>{student.address}</td>
              <td>{student.contact}</td>
              <td className="d-flex gap-4">
               {student.status !== 0 &&  <>
                  {" "}
                  <span
                    className="d-flex gap-2 align-items-center"
                    onClick={() => handleOpenEditModal(student)}
                  >
                    <BiEditAlt color="green" />
                    <span
                      style={{
                        color: "green",
                        cursor: "pointer",
                      }}
                    >
                      Edit
                    </span>{" "}
                  </span>
                </>}
                <StatusChanger {...student} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default InhouseStudentsTable;

const StatusChanger = ({ student_id, status }) => {
  const [currentStatus, setCurrentStatus] = useState(status);
  // query function to add question set
  const queryFunction = async ({ status }) => {
    await axiosInstance
      .put(`/api/changestatusinhousestudent/${student_id}?status=${status}`)
      .then((response) => {
        if (response.status === 200) {
          performSideEffects(response);
        }
      });
  };

  const performSideEffects = () => {
    queryClient.invalidateQueries({
      queryKey: ["get-inhouse-students"],
    });
  };

  const handleStatusChange = () => {
    let newStatus = currentStatus ? 0 : 1;
    setCurrentStatus(newStatus);
    try {
      queryFunction({
        status: newStatus,
      }).catch((error) => {
        if (error?.response?.status === 401) {
          handle401Exception(
            () =>
              queryFunction({
                status: newStatus,
              }),
            performSideEffects
          );
        }
      });
    } catch (error) {
      console.log("ERROR OCCURED", error);
    }
  };
  return (
    <span
      onClick={() => {
        handleStatusChange();
      }}
      style={{
        cursor: "pointer",
        textDecoration: "underline",
        color: currentStatus ? "red" : "green",
      }}
    >
      Set {currentStatus ? "Inactive" : "Active"}{" "}
    </span>
  );
};
