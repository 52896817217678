import React from "react";
import { useState } from "react";
import { IoMdCreate } from "react-icons/io";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-hot-toast";
import axiosInstance from "../../utils/axiosInstance";
import handle401Exception from "../../utils/handle401Exception";
import { queryClient } from "../..";
import { useGlobalContext } from "../../utils/GlobalContext";

const InhouseLiveExamForm = ({ availableExamSets }) => {
  const { setIsAppLoading } = useGlobalContext();
  const [formData, setFormData] = useState({
    exam_code: "",
    exam_title: "",
    exam_date_ad: "",
    exam_time: "",
    set_id: "",
  });

  // on input field change
  const handleOnChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // query function to add question set
  const queryFunction = async (exam_data) => {
    setIsAppLoading(true);
    return await axiosInstance
      .post("/api/createliveexaminhousestudent", exam_data)
      .then((response) => {
        if (response.status === 201) {
          performSideEffects(response);
        }
      });
  };

  const performSideEffects = () => {
    queryClient.invalidateQueries({
      queryKey: ["get-available-exam-sets"],
    });
    queryClient.invalidateQueries({
      queryKey: ["get-live-exams"],
    });
    setFormData({
      exam_code: "",
      exam_date_ad: "",
      exam_time: "",
      exam_title: "",
      set_id: "",
    });
    setIsAppLoading(false);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: queryFunction,
    onSuccess: (response) => {},
    onError: (error) => {
      if (error?.response?.status === 401) {
        handle401Exception(() => queryFunction(formData), performSideEffects);
      } else {
        setIsAppLoading(false);
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.exam_title === "") {
      toast.error("Please enter title of exam.");
      return;
    }
    if (formData.exam_code === "") {
      toast.error("Please enter exam code.");
      return;
    }
    if (formData.exam_date_ad === "") {
      toast.error("Please enter date of exam.");
      return;
    }
    if (formData.set_id === "") {
      formData.set_id = availableExamSets?.[0]?.set_id;
    }

    mutate(formData);
  };

  return (
    <>
      {/* {isLoading && <LoadingSpinner />} */}
      <form
        className="my-5"
        style={{
          width: "45%",
        }}
        onSubmit={handleSubmit}
      >
        <h1
          style={{
            fontSize: "2rem",
          }}
        >
          Create Internal Exam
        </h1>
        <div className="mb-3">
          <label className="form-label">
            Exam code
            <span
              style={{
                color: "red",
                paddingLeft: ".25rem",
                fontWeight: "bold",
              }}
            >
              *
            </span>{" "}
          </label>
          <input
            value={formData.exam_code}
            onChange={handleOnChange}
            name="exam_code"
            className="form-control shadow-sm"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            Exam Title
            <span
              style={{
                color: "red",
                paddingLeft: ".25rem",
                fontWeight: "bold",
              }}
            >
              *
            </span>{" "}
          </label>
          <input
            value={formData.exam_title}
            onChange={handleOnChange}
            name="exam_title"
            className="form-control shadow-sm"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            Exam Date
            <span
              style={{
                color: "red",
                paddingLeft: ".25rem",
                fontWeight: "bold",
              }}
            >
              *
            </span>{" "}
          </label>
          <input
            value={formData.exam_date_ad}
            type="date"
            onChange={handleOnChange}
            name="exam_date_ad"
            className="form-control shadow-sm"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            Exam Time
            <span
              style={{
                color: "red",
                paddingLeft: ".25rem",
                fontWeight: "bold",
              }}
            >
              *
            </span>{" "}
          </label>
          <input
            value={formData.exam_time}
            onChange={handleOnChange}
            name="exam_time"
            type="time"
            step="2"
            className="form-control shadow-sm"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            Exam Set
            <span
              style={{
                color: "red",
                paddingLeft: ".25rem",
                fontWeight: "bold",
              }}
            >
              *
            </span>{" "}
          </label>
          {availableExamSets && (
            <select
              name="set_id"
              className="form-select"
              onChange={handleOnChange}
            >
              {availableExamSets.map((exam_set, index) => (
                <option
                  selected={index === 0 ? true : false}
                  value={exam_set.set_id}
                >
                  {exam_set.set_title}: ({exam_set.set_id})
                </option>
              ))}
            </select>
          )}
        </div>

        <button
          type="submit"
          className="btn btn-primary"
          style={{
            display: "flex",
            gap: ".5rem",
            alignItems: "center",
          }}
        >
          <IoMdCreate />
          <span>Create Internal Exam</span>
        </button>
      </form>
    </>
  );
};

export default InhouseLiveExamForm;

export const useFetchAvailableExamSets = (setIsRequestEnabled) => {
  let queryKey = ["get-available-exam-sets"];
  // what will happen after the req is made
  const [availableExamSets, setAvailableExamSets] = useState([]);
  const performSideEffects = (response) => {
    setAvailableExamSets(response.data.data);
    if(setIsRequestEnabled){
      setIsRequestEnabled(true)
    }
  };
  // query function to add question set
  const queryFunction = async () => {
    return await axiosInstance.get("/api/getavailableexamsetinhousestudent");
  };
  const { isLoading, isFetching, data } = useQuery(queryKey, queryFunction, {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      if (response?.status === 200) {
        performSideEffects(response);
      }
    },
    onError: (error) => {
      if (error?.response?.status === 401) {
        handle401Exception(queryFunction, performSideEffects);
      }
    },
  });
  return {
    availableExamSets: availableExamSets,
  };
};
export const useFetchLiveExam = (isRequestEnabled) => {
  let queryKey = ["get-live-exams"];
  const [liveExamData, setLiveExamData] = useState([])
  // what will happen after the req is made
  const performSideEffects = (response) => {
    setLiveExamData(response.data.data)
  };
  // query function to add question set
  const queryFunction = async () => {
    return await axiosInstance
      .get("/api/getexamlistinhousestudent")
      .then((response) => {
        if (response.status === 200) {
          performSideEffects(response);
        }
      });
  };
  const { isLoading, isFetching } = useQuery(queryKey, queryFunction, {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: isRequestEnabled,
    onError: (error) => {
      if (error?.response?.status === 401) {
        handle401Exception(queryFunction, performSideEffects);
      }
    },
  });
  return { 
    liveExamData, isFetching, isLoading
  };
};
