import { Dispatch, SetStateAction } from "react";
import { PixelCrop } from "react-image-crop";

export async function canvasPreview(
  image,
  canvas,
  crop,
  imgName,
  setCroppedImg
) {
  const ctx = canvas.getContext("2d");

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);


  ctx?.scale(pixelRatio, pixelRatio);

  if (ctx) {
    ctx.imageSmoothingQuality = "high";
  }

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx?.save();

  // 4) Move the crop origin to the canvas origin (0,0)
  ctx?.translate(-cropX, -cropY);
  // 3) Move the origin to the center of the original position
  ctx?.translate(centerX, centerY);
  // 2) Scale the image
  ctx?.scale(1, 1);
  // 1) Move the center of the image to the origin (0,0)
  ctx?.translate(-centerX, -centerY);
  ctx?.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  const base64String = canvas.toDataURL("image/jpeg");

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      // @ts-ignore
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  var file = dataURLtoFile(base64String, imgName);
  setCroppedImg(file);

  ctx?.restore();
}
