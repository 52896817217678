import { Modal, Form, Button, Stack, Card } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { queryClient } from "../../../index";
import { useContext } from "react";
import { GlobalContext } from "../../../utils/GlobalContext";
import axiosInstance from "../../../utils/axiosInstance";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import handle401Exception from "../../../utils/handle401Exception";
import AddQuestionSet, {
  useFetchAllQuestionSetsWithoutPagination,
} from "../../PackageSet/AddQuestionSet/AddQuestionSet";
import { IoMdCreate } from "react-icons/io";
import { AiOutlineClear, AiOutlineCloseCircle } from "react-icons/ai";
const EditPackageSet = ({
  package_name,
  price,
  package_id,
  editModal,
  setEditModal,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { isLoading, isFetching, allQuestionSetCollection, totalData } =
    useFetchAllQuestionSetsWithoutPagination();
  const [packageData, setPackageData] = useState({
    package_name,
    price,
    selected_question_sets: [],
  });
  // question_set_id

  useEffect(() => {
    const findQuestionPackage = getQuestionSetCollectionArray(
      editModal.question_set_id,
      allQuestionSetCollection
    );
    if (findQuestionPackage.length === 0) {
      return;
    }
    setPackageData((prev) => ({
      ...prev,
      selected_question_sets: [...findQuestionPackage],
    }));
  }, [totalData]);

  // on input field change
  const handleOnChange = (e) => {
    if (e.target.value <= -1) {
      toast.error("Price cannot be in negative");
      return;
    }
    setPackageData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const { handleSubmit, handleClose } = useEditQuestionSet(
    packageData,
    setEditModal,
    package_id
  );

  return (
    <>
      <Modal
        size="lg"
        show={true}
        // show={editModal.package_id !== "" && editModal.package_id !== null}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Package Set</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <label className="form-label">Set Name</label>
              <input
                onChange={handleOnChange}
                name="package_name"
                className="form-control"
                value={packageData.package_name}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <label className="form-label">Price</label>
              <input
                min="0"
                onChange={handleOnChange}
                name="price"
                type="number"
                className="form-control"
                value={packageData.price}
              />
            </Form.Group>
            <Form.Group>
              {packageData.selected_question_sets.length !== 0 && (
                <>
                  <p>Selected Question Sets:</p>
                  <div
                    className="mb-3 mr-3"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: ".5rem",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      maxHeight: "20rem",
                      overflowY: "auto",
                      paddingRight: "2rem",
                      paddingBottom: "1rem",
                    }}
                  >
                    {packageData.selected_question_sets.map((item, index) => (
                      <Card
                        style={{
                          padding: ".5rem",
                          width: "45%",
                        }}
                      >
                        {item?.name || item?.question_set_name}
                      </Card>
                    ))}
                  </div>
                </>
              )}
            </Form.Group>
            <Form.Group>
              <Stack direction="horizontal" gap={2}>
                <Button
                  variant="primary"
                  // style={{
                  //   background: "#EE4B2B",
                  //   border: "none",
                  // }}
                  onClick={() => setModalOpen(true)}
                >
                  Add / Remove Question Sets
                </Button>
              </Stack>
              {modalOpen && (
                <AddQuestionSet
                  setPackageData={setPackageData}
                  open={modalOpen}
                  setOpen={setModalOpen}
                  packageData={packageData}
                />
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{
              display: "flex",
              gap: ".5rem",
              alignItems: "center",
              background: "#EE4B2B",
              border: "none",
            }}
          >
            <AiOutlineCloseCircle />
            {/* <AiOutlineClear /> */}
            <span className="ml-3">Close</span>
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            style={{
              display: "flex",
              gap: ".5rem",
              alignItems: "center",
            }}
          >
            <IoMdCreate />
            <span> Save Changes</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditPackageSet;

const useEditQuestionSet = (packageData, setEditModal, package_id) => {
  const { setIsAppLoading } = useContext(GlobalContext);

  // what will happen after the req is made
  const performSideEffects = () => {
    handleClose();
    queryClient.invalidateQueries({
      queryKey: ["get-package-sets"],
    });
    setIsAppLoading(false);
  };
  // query function to add question set
  const queryFunction = async (packageSet) => {
    return await axiosInstance
      .put("/api/questionsetpackage", packageSet)
      .then((response) => {
        if (response.status === 201) {
          performSideEffects();
        }
      });
  };

  // when we close the modal
  const handleClose = () => {
    setEditModal((prev) => ({
      package_id: null,
      package_name: null,
      price: null,
      question_set_id: [],
      status: null,
    }));
    // package_id: 8;
    // package_name: "Hello Package";
    // price: 12;
    // question_set_id: "[8,10,12,11,13,14,15,16,17,19,20,21,22,23]";
    // status: 1;
  };

  const { mutateAsync } = useMutation(queryFunction, {
    onError: (error) => {
      if (error?.response?.status === 401) {
        const data = {
          // ...packageData,
          package_id: package_id,
          package_name: packageData?.package_name,
          price: packageData?.price || 0,
          question_set_id: packageData.selected_question_sets.reduce(
            (acc, current) => {
              return [...acc, current.question_set_id];
            },
            []
          ),
        };
        setIsAppLoading(true);
        handle401Exception(
          () => queryFunction({ ...data, package_id }),
          performSideEffects
        );
      }
    },
  });

  const handleSubmit = () => {
    if (packageData.package_name === "") {
      toast.error("Please specify name of package set");
      return;
    }

    if (packageData.selected_question_sets.length === 0) {
      toast.error("Please select at least one question set");
      return;
    }
    const data = {
      // ...packageData,
      package_id: package_id,
      package_name: packageData?.package_name,
      price: packageData?.price || 0,
      question_set_id: packageData.selected_question_sets.reduce(
        (acc, current) => {
          return [...acc, current.question_set_id];
        },
        []
      ),
    };
    // selected_question_sets
    // question_set_id

    setIsAppLoading(true);
    mutateAsync({ ...data, package_id });
  };

  return {
    handleSubmit,
    handleClose,
  };
};

const getQuestionSetCollectionArray = (
  question_set_id_array,
  collectionArray
) => {
  const newArray = collectionArray.filter((item) => {
    // compare item.id and question_set_id_array
    const findItem = JSON.parse(question_set_id_array).find(
      (set_id) => set_id === item.question_set_id
    );
    return findItem;
  });
  return newArray;
};
