import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { AiFillFolderOpen } from "react-icons/ai";
import { useState } from "react";
import EditInhouseStudent from "../Modal/Inhouse/EditInhouseStudent";
import { useMutation } from "react-query";
import axiosInstance from "../../utils/axiosInstance";
import { queryClient } from "../..";
import handle401Exception from "../../utils/handle401Exception";
import EditInhouseExamSet from "../Modal/Inhouse/EditInhouseExamSet";
import { MdOutlineRestore } from "react-icons/md";
import DeleteInhouseExamSetModal from "../Modal/Inhouse/DeleteInhouseExamSet";
import RestoreExamSetModal from "../Modal/Inhouse/RestoreExamSet";

const InhouseExamSetTable = ({ inhouseExamSetArray, pageUsed }) => {
  const tableHeading = ["S.N", "Title", "Exam duration", "Actions"];

  const [editModal, setEditModal] = useState({
    title: "",
    set_duration: "",
    set_id: null,
  });
  const [deleteModal, setDeleteModal] = useState({
    set_title: "",
    set_duration: "",
    set_id: null,
  });

  const [restoreModal, setRestoreModal] = useState({
    set_title: "",
    set_duration: "",
    set_id: null,
  });

  const handleOpenDeleteModal = (exam_set) => {
    setDeleteModal({
      ...exam_set,
    });
  };

  const handleOpenRestoreModal = (exam_set) => {
    setRestoreModal({
      ...exam_set,
    });
  };

  const handleOpenEditModal = (exam_data) => {
    setEditModal((prev) => ({
      ...exam_data,
    }));
  };

  return (
    <>
      {editModal.set_id !== null && (
        <EditInhouseExamSet {...editModal} setEditModal={setEditModal} />
      )}
      {deleteModal.set_id !== null && (
        <DeleteInhouseExamSetModal
          {...deleteModal}
          setDeleteModal={setDeleteModal}
        />
      )}
      {restoreModal.set_id !== null && (
        <RestoreExamSetModal
          {...restoreModal}
          setRestoreModal={setRestoreModal}
        />
      )}
      <Table responsive hover>
        <thead>
          <tr>
            {tableHeading.map((heading, index) => {
              return <th key={index}>{heading}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {inhouseExamSetArray.map((exam_set, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{exam_set.set_title}</td>
              <td>{exam_set.set_duration}</td>
              <td className="d-flex gap-3">
                <>
                  {pageUsed !== "PackageSet" && (
                    <>
                      {" "}
                      {exam_set.status === 1 && (
                        <span
                          className="d-flex gap-2 align-items-center"
                          onClick={() => handleOpenEditModal({ ...exam_set })}
                        >
                          <BiEditAlt color="green" />
                          <span
                            style={{
                              color: "green",
                              cursor: "pointer",
                            }}
                          >
                            Edit
                          </span>{" "}
                        </span>
                      )}
                      {exam_set.status === 1 ? (
                        <span
                          className="d-flex gap-2 align-items-center"
                          onClick={() => handleOpenDeleteModal(exam_set)}
                        >
                          <AiFillDelete color="red" />
                          <span
                            style={{
                              color: "red",
                              cursor: "pointer",
                            }}
                          >
                            Delete
                          </span>{" "}
                        </span>
                      ) : (
                        <span
                          className="d-flex gap-2 align-items-center pr-4"
                          onClick={() => handleOpenRestoreModal(exam_set)}
                        >
                          <MdOutlineRestore color="green" />
                          <span
                            style={{
                              color: "red",
                              cursor: "pointer",
                            }}
                          >
                            Restore
                          </span>
                        </span>
                      )}
                    </>
                  )}
                </>

                {pageUsed === "InhouseExamSet" && exam_set.status === 1&& (
                  <Link
                    to={`/inhouse-examset/${encodeURIComponent(
                      exam_set.set_title
                    )}/${exam_set["set_id"]}`}
                  >
                    <span className="d-flex gap-2 align-items-center">
                      <AiFillFolderOpen />{" "}
                      <span
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                      >
                        Add Question
                      </span>{" "}
                    </span>
                  </Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default InhouseExamSetTable;

